import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Drawer, Divider, Typography, OutlinedInput, Box } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';
// components

import { dispatch, useSelector } from '../../../redux/store';
import { addAttachment, deleteAttachment } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import CardAssignee from '../components/CardAssignee/CardAssignee';
import { IconButtonAnimate } from '../../animate';
//

// import KanbanTaskCommentList from './KanbanTaskCommentList';
import KanbanTaskAttachments from '../../../sections/@dashboard/kanban/KanbanTaskAttachments';

// import KanbanTaskCommentInput from './KanbanTaskCommentInput';
import { useDatePicker, DisplayTime } from '../../../sections/@dashboard/kanban/KanbanTaskAdd';
import axios from '../../../utils/axios';



// ----------------------------------------------------------------------

ImageCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  onFinish: PropTypes.func,
};

export function ImageCard({ paperStyle = {}, onOpen, preview, card, onChange, onDeleteTask, onFinish, index, ...other }) {
  const { translate } = useLocales();
  const [openDetails, setOpenDetails] = useState(false);
  const { name, attachments, completed, isNew } = card;
  const handleOpenDetails = () => {
    if (!preview) setOpenDetails(true);
  };

  return (
    <>
      <Box
        sx={{
          width: 1,
          position: 'relative',
          ...paperStyle,
        }}
      >
        <Box onClick={onOpen} sx={{ cursor: 'pointer' }}>
          {attachments.length > 0 ? (
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                sx={{
                  width: 1,
                  borderRadius:card.sx?.borderRadius
                }}
                src={attachments[0]?.url?.full}
              />
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Iconify icon={'bx:image-add'} width={40} height={40} />
              <br />
              <Typography
                variant="subtitle"
                sx={{
                  py: 1,
                  textAlign: 'center',
                  pl: 0,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  ...(completed && { opacity: 0.48 }),
                }}
              >
                {translate('dragndrop.dropimagehere')}
              </Typography>
            </Box>
          )}

          {name && (
            <Typography
              variant="caption"
              sx={{
                py: 1,
                pl: 0,
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                  }),
                ...(completed && { opacity: 0.48 }),
              }}
            >
              {translate(name)}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export function ImageModal({ page, card, isOpen, onClose, onUpdate, onDeleteTask, onAttachAdd, ...other }) {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();
  const { user } = useAuth();
  const titleInput = useRef(null);
  const [alt, setAlt] = useState(card.alt || '');
  const [name, setName] = useState(card.name || '');
  const [opened, setOpened] = useState(false);
  const [userAuth, setUserAuth] = useState(false);


  useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);

  const onAttachChanged = async (files) => {
    const form = new FormData();
    const originalTitles = [];
    files.map((file)=>{
      form.append(`file`, file)
      originalTitles.push(file.name)
      return true;
    });
    form.append(`titles`, JSON.stringify(originalTitles))
    const response = await axios.post('/attachment', form);
    console.log(files)
    dispatch(addAttachment({ board: page, attachments: response.data?.map((item) => item._id || ''), card: card._id }));
  };

  const deleteAttach = async (list, c) => {
    console.log(list, c)
    // const response = await axios.post('/modules/kanban/card/attachment/delete', { cardId: card._id, file: c });
    dispatch(deleteAttachment({card: card._id, file: c}))
  };

  const onCloseHandler = () => {
    setOpened(false);
    onClose({ name, alt });
  };

  useEffect(() => {
    if (opened) {
      if (titleInput.current) {
        titleInput.current.focus();
        titleInput.current?.setSelectionRange(name.length-1, name.length-1);
      }
    }
  }, [opened]);

  return (
    <>
      <OutlinedInput
        fullWidth
        ref={titleInput}
        multiline
        size="small"
        placeholder={translate('dragndrop.imagetitle')}
        value={name}
        autoFocus
        onChange={(e) => {
          setName(e.target.value);
        }}
        onBlur={()=>onUpdate({name, changed: true})}
        sx={{
          typography: 'h6',
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        }}
      />
      <OutlinedInput
        fullWidth
        multiline
        size="small"
        placeholder={translate('dragndrop.alt')}
        value={alt}
        autoFocus
        onChange={(e) => {
          setAlt(e.target.value);
        }}
        onBlur={()=>onUpdate({alt, changed: true})}
        sx={{
          typography: 'h6',
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        }}
      />


        <Typography variant="subtitle2" sx={{ mt: 2 }}>{translate('dragndrop.images')}</Typography>
        <Stack direction="row">
          <KanbanTaskAttachments
            attachments={card.attachments}
            onAttachChanged={onAttachChanged}
            deleteAttach={deleteAttach}
          />
        </Stack>
        {/* <NotLoggedInWarning /> */}
    </>
  );
}
