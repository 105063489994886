import PropTypes from 'prop-types';
import { useState} from 'react';
// @mui
import {
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
// hooks

import useLocales from '../../../hooks/useLocales';
// components

import { dispatch, useSelector } from '../../../redux/store';
import { setCurSize } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import MenuPopover from '../../MenuPopover';
import { IconButtonAnimate } from '../../animate';


// ----------------------------------------------------------------------

ResponsiveManager.propTypes = {
  rowObject: PropTypes.object,
};



const responsiveBtnDesign = (active) => ({
  bgcolor: active ? 'rgba(0,0,0,0.4)' : 'unset',
});

export default function ResponsiveManager({ size, page, row, rowObject }) {
  const { translate } = useLocales();

  const { curSize, userSizes } = useSelector((state) => state.kanban);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
    <IconButtonAnimate
      onClick={handleOpen}
      sx={{
        width: 40,
        height: 40,
        ...(open && { bgcolor: 'action.selected' }),
        m: 0
      }}
    >
      <Iconify icon={userSizes[curSize].icon} width={30 * userSizes[curSize].scale} height={30 * userSizes[curSize].scale} />
    </IconButtonAnimate>

    <MenuPopover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
      sx={{
        mt: 1.5,
        ml: 0.75,
        width: 50,
        '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
      }}
    >
      <Stack spacing={0.75}>
        {userSizes.map((item, index) => (
 
                <Tooltip title={translate(item.title)}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      dispatch(setCurSize(index));
                    }}
                    sx={responsiveBtnDesign(curSize === index)}
                  >
                    <Iconify icon={item.icon} width={30 * item.scale} height={30 * item.scale} />
                  </IconButton>
                </Tooltip>

            ))}
        </Stack>
    </MenuPopover>
  </>
  );
}
