import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Card } from '@mui/material';
import { styled } from '@mui/material/styles';

// redux

// routes
import useLocales from '../../hooks/useLocales';

import { DialogAnimate } from '../animate';
import Iconify  from '../Iconify';
import BoardAddDialog from './BoardAddDialog';

// ----------------------------------------------------------------------

export default function BoardAdd({ onClose }) {
  const { translate } = useLocales();

  const [newBoard, setNewBoard] = useState(false);
  const [items, setItems] = useState(['btn']);
  const [boardname, setBoardName] = useState('');

  const addBoard = async () => {
    console.log('Ok');
    setNewBoard(true);
  };

  const handleCloseModal = () => {
    
    setNewBoard(false);
    if (onClose)
      onClose();
  };

  const handleOpen = () => {};

  return (
    <>
      <Button fullWidth onClick={addBoard} variant="outlined" to="#" component={RouterLink}>
        <Iconify icon="akar-icons:plus" sx={{mr: 1}} />{translate('widgets.addboard')}
      </Button>

      {newBoard && (
        <DialogAnimate sx={{ p: 2 }} open onClose={handleCloseModal}>
          <BoardAddDialog onClose={handleCloseModal} isOpen={newBoard} />{' '}
        </DialogAnimate>
      )}
    </>
  );
}
