import PropTypes from 'prop-types';
import { createContext, useEffect } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
import axios from '../utils/axios';
// utils
import getColorPresets, { colorPresets, defaultPreset } from '../utils/getColorPresets';
// config
import { defaultSettings } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onToggleStretch: () => {},
  onChangeLayout: () => {},
  onResetSetting: () => {},
  onPermissionAdd: () =>{},
  updateSetting: () =>{},
  setDefaultValues: () =>{},
  setColor: defaultPreset,
  colorOption: [],
  defaultValues: {},
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: initialState.themeMode,
    themeDirection: initialState.themeDirection,
    themeColorPresets: initialState.themeColorPresets,
    themeStretch: initialState.themeStretch,
    themeLayout: initialState.themeLayout,
    defaultValues: initialState.defaultValues
  });

  const saveSetting = async (data)=>{
    const response = await axios.post('/user/configuration', data);
  }

  console.log(settings)
  const onChangeMode = (event) => {
    saveSetting({themeMode: event.target.value})
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };


  const setDefaultValues = (group, key, value)=>{
    console.log(group, key, value)
    saveSetting({defaultValues: {...settings.defaultValues, [group]: {...(settings.defaultValues[group || "default"] || {}), [key]: value}}})
    setSettings({
      ...settings,
      defaultValues: {...settings.defaultValues, [group]: {...(settings.defaultValues[group || "default"] || {}), [key]: value}}
    });
  }

  const onToggleMode = () => {
    saveSetting({themeMode: settings.themeMode === 'light' ? 'dark' : 'light'})
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onChangeDirection = (event) => {
    saveSetting({themeDirection: event.target.value})
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event) => {
    saveSetting({themeColorPresets: event.target.value})
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  };

  const onPermissionAdd = (data) => {
    settings.permissions.push(JSON.stringify(data))
    saveSetting({permissions: settings.permissions})
    setSettings({
      ...settings,
      permissions: settings.permissions
    });
    return settings.permissions
  };

  const onChangeLayout = (event) => {
    saveSetting({themeLayout: event.target.value})
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    saveSetting({themeStretch: !settings.themeStretch})
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
    
  };

  useEffect(async ()=>{
    const response = await axios.get('/user/configuration');
    setSettings({
      ...settings,
      ...response.data
    })
    console.log({
      ...settings,
      ...response.data
    })
  }, [])

  const onResetSetting = () => {
    saveSetting({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
      onPermissionAdd: initialState.onPermissionAdd,
    })
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
      onPermissionAdd: initialState.onPermissionAdd,
      updateSetting: initialState.updateSetting
    });
  };
  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        onToggleMode,
        setDefaultValues,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map((color) => ({
          name: color.name,
          value: color.main,
        })),
        // Stretch
        onToggleStretch,
        // Navbar Horizontal
        onChangeLayout,
        // Reset Setting
        onResetSetting,
        onPermissionAdd,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
