import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Stack, Typography, Box, Checkbox, Tooltip, TextField, Divider, MenuItem, OutlinedInput } from '@mui/material';
// components
import { MobileDateRangePicker } from '@mui/lab';
import Image from '../../Image';
import Iconify from '../../Iconify';
import useLocales from '../../../hooks/useLocales';
//
// import TaskModal from '../components/TaskModal';
import useResponsive from '../../../hooks/useResponsive';

import { dispatch } from '../../../redux/store';
import { addAttachment } from '../../../redux/slices/kanban';

import { useDatePicker, DisplayTime } from '../../../sections/@dashboard/kanban/KanbanTaskAdd';
import KanbanTaskAttachments from '../../../sections/@dashboard/kanban/KanbanTaskAttachments';
import CardModalGlobal from '../components/CardModalGlobal';
import { IconButtonAnimate } from '../../animate';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'high'];

TaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  onFinish: PropTypes.func,
};

export default function TaskCard({
  paperStyle = {},
  preview,
  card,
  onChange,
  onDeleteTask,
  onFinish,
  index,
  ...other
}) {
  const { translate } = useLocales();
  const [openDetails, setOpenDetails] = useState(false);
  const { name, attachments, completed, isNew } = card;

  const handleOpenDetails = () => {
    if (!preview) setOpenDetails(true);
  };

  const handleCloseDetails = async (data) => {
    setOpenDetails(false);
  };

  const handleChangeComplete = async (event) => {
    console.log({ isChange: true, completed: event.target.checked, _id: card._id });
    await onChange({ isChange: true, completed: event.target.checked, _id: card._id });
  };

  const onAttachAddCard = (files) => {
    handleCloseDetails({ ...card });
  };

  return (
    <>
      <Paper
        sx={{
          px: 2,
          width: 1,
          position: 'relative',
          boxShadow: (theme) => theme.customShadows.z1,
          '&:hover': {
            boxShadow: (theme) => theme.customShadows.z16,
          },
          ...(attachments.length > 0 && {
            pt: 2,
          }),
          ...paperStyle,
        }}
      >
        <Box onClick={handleOpenDetails} sx={{ cursor: 'pointer' }}>
          {attachments.length > 0 && (
            <Box
              sx={{
                pt: '60%',
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                  }),
                ...(completed && {
                  opacity: 0.48,
                }),
              }}
            >
              <Image src={attachments[0].url?.preview} sx={{ position: 'absolute', top: 0, width: 1, height: 1 }} />
            </Box>
          )}

          <Typography
            variant="subtitle2"
            sx={{
              py: 3,
              pl: 5,
              transition: (theme) =>
                theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shortest,
                }),
              ...(completed && { opacity: 0.48 }),
            }}
          >
            {translate(name)}
          </Typography>
        </Box>

        <Checkbox
          disableRipple
          checked={completed}
          icon={<Iconify icon={'eva:radio-button-off-outline'} />}
          checkedIcon={<Iconify icon={'eva:checkmark-circle-2-outline'} />}
          onChange={handleChangeComplete}
          sx={{ position: 'absolute', bottom: 15 }}
        />
      </Paper>

      {(openDetails || isNew) && (
        <TaskModal
          {...other}
          card={card}
          onChange={onChange}
          open={openDetails || isNew}
          onClose={handleCloseDetails}
          onDeleteTask={() => onDeleteTask(card._id)}
          onAttachAdd={onAttachAddCard}
        />
      )}
    </>
  );
}

const TaskModal = ({ board, card, open, onDeleteTask, onAttachAdd, ...other }) => {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();
  const fileInputRef = useRef(null);
  const titleInput = useRef(null);
  const [prioritize, setPrioritize] = useState(card.prioritize || 'low');
  const [description, setDescription] = useState(card.description || '');
  const [name, setName] = useState(card.name || '');
  const [completed, setCompleted] = useState(card.completed || false);
  const { due, comments } = card;

  const [isChange, setIsChange] = useState(null);

  const setChange = () => {
    setIsChange(true);
  };

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker,
  } = useDatePicker({
    date: due,
  });

  const handleChangePrioritize = (event) => {
    setPrioritize(event.target.value);
    setChange();
  };

  const onAttachChanged = async (files) => {
    const form = new FormData();
    files.map((file) => form.append(`file`, file));
    const response = await axios.post('/attachment', form);
    dispatch(addAttachment({ board, attachments: response.data?.map((item) => item._id || ''), card: card._id }));
  };

  const deleteAttach = async (list, c) => {
    console.log('Attachment deleted');
    await axios.post('/modules/kanban/card/attachment/delete', { cardId: card._id, file: c });
  };

  useEffect(() => {
    if (open) {
      if (titleInput.current) {
        titleInput.current.focus();
      }
    }
  }, [open]);

  return (
    <>
      <CardModalGlobal fields={{ isChange }} open={open || card.isNew} card={card} {...other}>
        <OutlinedInput
          fullWidth
          ref={titleInput}
          multiline
          size="small"
          placeholder={translate('dragndrop.taskname')}
          value={name}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
            setChange();
          }}
          sx={{
            typography: 'h6',
            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
          }}
        />

        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2"> {translate('dragndrop.duedate')}</Typography>
          <>
            {startTime && endTime ? (
              <DisplayTime
                startTime={startTime}
                endTime={endTime}
                isSameDays={isSameDays}
                isSameMonths={isSameMonths}
                onOpenPicker={onOpenPicker}
                sx={{ typography: 'body2' }}
              />
            ) : (
              <Tooltip title={translate('dragndrop.settime')}>
                <IconButtonAnimate
                  onClick={onOpenPicker}
                  sx={{
                    p: 1,
                    ml: 0.5,
                    border: (theme) => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
            )}

            <MobileDateRangePicker
              open={openPicker}
              onClose={onClosePicker}
              onOpen={onOpenPicker}
              value={dueDate}
              onChange={(...o) => {
                onChangeDueDate(...o);
                setChange();
              }}
              renderInput={() => {}}
            />
          </>
        </Stack>

        <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2">{translate('dragndrop.priority')}</Typography>
          <TextField
            fullWidth
            select
            size="small"
            value={translate(prioritize)}
            onChange={handleChangePrioritize}
            sx={{
              '& svg': { display: 'none' },
              '& fieldset': { display: 'none' },
              '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
            }}
          >
            {PRIORITIZES.map((option) => (
              <MenuItem key={option} value={translate(option)} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                <Box
                  sx={{
                    mr: 1,
                    width: 14,
                    height: 14,
                    borderRadius: 0.5,
                    bgcolor: 'error.main',
                    ...(option === 'low' && { bgcolor: 'info.main' }),
                    ...(option === 'medium' && { bgcolor: 'warning.main' }),
                  }}
                />
                <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                  {translate(option)}
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction="row">
        <Typography variant="subtitle2" sx={{ mt: 2 }}>{translate('dragndrop.description')}</Typography>
          <OutlinedInput
            fullWidth
            multiline
            rows={3}
            size="small"
            placeholder={translate('dragndrop.description')}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setChange();
            }}
            sx={{ typography: 'body2' }}
          />
        </Stack>

        <Stack direction="row">
          <Typography variant="subtitle2" sx={{ mt: 2 }}>{translate('dragndrop.attachments')}</Typography>
          <Stack direction="row" flexWrap="wrap">
            <KanbanTaskAttachments
              attachments={card.attachments}
              onAttachChanged={onAttachChanged}
              deleteAttach={deleteAttach}
            />
          </Stack>
        </Stack>
      </CardModalGlobal>
    </>
  );
};
