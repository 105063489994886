import { useState } from 'react';

import { Stack, Card, Box, Button, Tooltip } from '@mui/material';
import useLocales from '../../../../hooks/useLocales';
import Iconify from '../../../Iconify';

import { dispatch, useSelector } from '../../../../redux/store';
import { splitRow } from '../../../../redux/slices/kanban';
import { IconButtonAnimate } from '../../../animate';
import {AddCardButton} from '../..';
import WebEditorRowModal from './ColumnModal';

export default function WebEditorColumnSettings({ row, column, page, ...other }) {
  const { translate } = useLocales();

  const [open, setOpen] = useState(false)
  return (<>
    <Box
      className="column-settings"
      sx={{
        position: 'absolute',
        right: '0',
        top: -30,
        p: 0.25,
        bgcolor: "background.neutral",
        opacity: '0',
        transition:"0.2s opacity",

        borderBottomLeftRadius: "8px"
      }}
    >
      <Stack direction="row">
        <AddCardButton column={column} page={page} />
        <Tooltip title={translate('modules.boards.edit.column')}>
          <IconButtonAnimate onClick={()=>setOpen(true)}>
            <Iconify icon={'ic:baseline-settings'} sx={{ width: 15, height: 15 }} />
          </IconButtonAnimate>
        </Tooltip>
      </Stack>
    </Box>
    {open && <WebEditorRowModal {...other} row={row} column={column} page={page} open={open} onClose={() => setOpen(false)} />}
    </>
  );
}
