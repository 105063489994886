import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------



const initialState = {
  isLoading: false,
  error: null,
  prints: [],
  print: {},
  shirts: [],
  orders: [],
  shirt: {sizes: {w: [], m: []}, plentyIds: {}},
  tagOptions: [],
  files: {},
  tags: [],
  changedPrintId: {},
  basketItems: [],
  isEnd: false
};

const slice = createSlice({
  name: 'prints',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.error = action.payload;
    },

    // GET BOARD
    getPrintsSuccess(state, action) {
      state.isLoading = false;
      const {prints, isEnd, isNew} = action.payload
      console.log(isNew)
      if (!isNew){
        state.prints = [...current(state).prints || [], ...prints]
        state.isEnd = isEnd
        state.page = current(state).page+1
      } else {
        state.prints = [...prints]
        state.isEnd = isEnd
        state.page = 0
      }
    },

    getTagsSuccess(state, action) {
      state.isLoading = false;
      const {tags} = action.payload
      state.tags = [...tags]

    },

    getOrdersSuccess(state, action) {
      const {orders, isEnd} = action.payload
      state.orders = [...orders]
    },

    updateShirt(state, action){
      const  {what, value} = action.payload
      state.shirt[what] = value
    },

    getShirtsSuccess(state, action) {
      state.shirts = action.payload.items || []
    },

    getShirtSuccess(state, action) {
      state.shirt = action.payload
    },

    updatePlentyId(state, action){
      const {gender, value} = action.payload
      if (current(state).shirt.plentyIds){
        state.shirt.plentyIds[gender] = value
      }
    },

    updatePlentyPrice(state, action){
      const {gender, value} = action.payload
      if (current(state).shirt.plentyIds){
        state.shirt.prices[gender] = value
      }
    },

    getPrintSuccess(state, action) { 
      state.print = action.payload || {}
    },

    uploadFileSuccess(state, action){
      const {what, items} = action.payload
      state.print[what] = items
    },
    downloadFileSuccess(state, action){
      const {link} = action.payload
      if (link)
        window.location = link
    },
    savePrintSuccess(state, action){
      const {id} = action.payload
    },
    setNewPrint(state, action){
      state.print = {...initialState.print}
    },
    getDesignSuccess(state, action){
      state.basketItems = action.payload.items || []
      state.locked = action.payload.locked || false
    },
    downloadFilesSuccess(state, action){},

    addSize(state, action){
      const {where} = action.payload
      state.shirt.sizes[where].push({})
    },
    changeSize(state, action){
      const {index, gender, what, value} = action.payload
      state.shirt.sizes[gender][index][what] = value
    },
    
    relaodPriceSuccess(state, action){
      state.shirt.sizes = action.payload
    },
    
    setNewShirt(state, action){
      state.shirt = initialState.shirt
    },
    resetPrints(state, action){
      state.prints = []
    },
    deleteFileSuccess(state, action){
      const {what, id, file} = action.payload
      const curData = current(state).print[what]
      state.print[what] = curData.filter((item)=>item.name!==file.name)
      console.log(curData)
      // state.print[what].
    }
  },
});

/*
  recipeFinished, setRecipeFinished
  catAndDurFinished, setCatAndDurFinished
  ingredAndSteps, setIngredAndSteps
*/

// Reducer
export default slice.reducer;

export const {
  setNewPrint,
  updateShirt,
  addSize,
  changeSize,
  setNewShirt,
  updatePlentyId,
  updatePlentyPrice,
  resetPrints
} = slice.actions;

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPrints(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/prints', {
        params: { ...page },
      });
      await dispatch(slice.actions.getPrintsSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTags(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/tags', {
        params: {...page}
      });
      dispatch(slice.actions.getTagsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getOrders(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/orders', {
        params: { page },
      });
      await dispatch(slice.actions.getOrdersSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reloadPrices({id}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(id)
      const response = await axios.get('/api/shirts/prices', {
        params: { id }
      });
      await dispatch(slice.actions.relaodPriceSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveShirt(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/shirt', data);
      // await dispatch(slice.actions.getPrintsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeFile (data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/file/delete', data);
      dispatch(slice.actions.deleteFileSuccess(data, response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deletePrint({ id }) {
  return async () => {
    try {
      const response = await axios.delete('/api/print', { params: { id } });
      dispatch(slice.actions.deleteCardSuccess({ id }));
      return true
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShirts(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/shirts', {
        params: { id },
      });
      await dispatch(slice.actions.getShirtsSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPrint(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/print', {
        params: { id },
      });
      await dispatch(slice.actions.getPrintSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getShirt(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/shirt', {
        params: { id },
      });
      await dispatch(slice.actions.getShirtSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function downloadFiles(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/download', {
        params: { id },
      });
      await dispatch(slice.actions.downloadFilesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDesign(id){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/designid', {
        params: { id },
      });
      await dispatch(slice.actions.getDesignSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadFile(data){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/file', data);
      await dispatch(slice.actions.uploadFileSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function downloadFile(data){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/file/download', data)
      await dispatch(slice.actions.downloadFileSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function savePrint(data){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (data.id === "new"){
        const response = await axios.post('/api/print', data)
        return response.data;
      }
      const response = await axios.put('/api/print', data)
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}