import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Paper, TextField } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import CardModalGlobal from '../components/CardModalGlobal';

import { useDispatch } from '../../../redux/store';
import Editor from '../../editor';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

PlaceholderCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export function PlaceholderCard({ disableTitle, onOpen, paperStyle = {}, preview, onChange, card, sx, ...other }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [open, setOpen] = useState(null);

  const openWindow = () => {
    if (!preview) setOpen(true);
  };

  const onClose = async (data) => {
    const response = await onChange({ ...data, _id: card._id });
    if (response === 'to') {
      setTimeout(() => {
        setOpen(false);
      }, 200);
      return;
    }
    if (!data.noclose) setOpen(false);
  };
  return (
    <>
      <Paper
        onClick={onOpen}
        component="span"
        sx={{

          width: 1,

          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
          ...paperStyle,
        }}
      >
        <Box sx={{width: 1, height: 1, bgcolor: "grey.5008",border:"2px solid black", p:4}}>
          <Typography variant="body1" sx={{textAlign:"center", color: "black"}}>
            <Iconify icon="gg:website" width={40} height={40} /><br/>
            Hier wird dein Inhalt der besuchten Seite eingefügt.<br/>
            Du kannst diese Karte nicht löschen, <br/>
            aber du kannst weitere Karten oder Spalten hinzufügen.
          </Typography>
        </Box>
      </Paper>
    </>
  );
}

export function PlaceholderModal({ card, preview, open, onUpdate, ...other }) {

  return (
    <>

    </>
  );
}


export function TextCardRendered({card}){

  return `<div class="anim-text-card">
    
  </div>`
}