import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
// @mui
import {
  Stack,
  Box,
  AccordionSummary,
  Typography,
  Accordion,
  AccordionDetails,
  TextField,
  Grid,
  Tooltip,
  Checkbox,
} from '@mui/material';


import useSettings from '../../../hooks/useSettings';


// components
import Iconify from '../../Iconify';

import ColorPopover from '../../ColorPopover';
import useLocales from '../../../hooks/useLocales';
import { IconButtonAnimate } from '../../animate';

// import { dispatch } from '../../../redux/store';
// import { deleteCard } from '../../../redux/slices/kanban';

// ----------------------------------------------------------------------

CardStyleManager.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const fromDirections = {
  bottom: { height: true },
  bottomLeft: { width: true, height: false },
  bottomRight: { width: false, height: false },
  left: { width: true },
  right: { width: false },
  top: { height: true },
  topLeft: { width: true, height: true },
  topRight: { width: false, height: true },
};

export default function CardStyleManager({ type, onChange, taskComplete, onDelete, card, onClose, ...other }) {
  const { translate } = useLocales();
  const curElement = useRef(null);
  const { defaultValues, setDefaultValues } = useSettings();
  const [cardPadding, setCardPadding] = useState([
    card.sx?.pt || defaultValues[type]?.pt || 1,
    card.sx?.pr || defaultValues[type]?.pr || 1,
    card.sx?.pb || defaultValues[type]?.pb || 1,
    card.sx?.pl || defaultValues[type]?.pl || 1
  ]);
  const [maxWidth, setMaxWidth] = useState(card.sx?.maxWidth);
  const [centerItem, setCenterItem] = useState(card.sx?.textAlign);
  const [borderRadius, setBorderRadius] = useState(card.sx?.borderRadius || "0px");
  
  console.log(defaultValues)
  useEffect(() => {
    if (!defaultValues){
      setDefaultValues({})
    }
    console.log(cardMargin, cardPadding)
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), borderRadius, ...{mt: cardMargin[0], mr: cardMargin[1], mb: cardMargin[2], ml: cardMargin[3]}, ...{pt: cardPadding[0], pr: cardPadding[1], pb: cardPadding[2], pl: cardPadding[3]} } });
  }, []);


  const [cardMargin, setCardMargin] = useState([
    card.sx?.mt || defaultValues[type]?.mt || 1.5,
    card.sx?.mr || defaultValues[type]?.mr || 1.5,
    card.sx?.mb || defaultValues[type]?.mb || 1.5,
    card.sx?.ml || defaultValues[type]?.ml || 1.5,
  ]);

  const onSizeChange = (e, index) => {
    const copy = [...cardPadding];
    copy[index] = Number(e.target.value);
    setCardPadding(copy);
    onChange({
      noclose: true, isChange: true,
      sx: { ...(card.sx || {}), pt: copy[0], pr: copy[1], pb: copy[2], pl: copy[3] },
    });
  };

  const onMarginChange = (e, index) => {
    const copy = [...cardMargin];
    copy[index] = Number(e.target.value);
    setCardMargin(copy);
    console.log(copy)
    onChange({
      noclose: true, isChange: true,
      sx: { ...(card.sx || {}), mt: copy[0], mr: copy[1], mb: copy[2], ml: copy[3] },
    });
  };

  const onColorChange = (color) => {
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), bgcolor:color?.hex || color } });
  };
  
  const onColorChangeText = (color) => {
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), color: color?.hex || color } });
  };

  const onWidthChange = (value) => {
    if (!Number) return;
    setMaxWidth(value)
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), maxWidth: value } });
  };

  const onBorderRadiusChange = (value) => {
    console.log(value)

    
    setBorderRadius(value)
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), borderRadius: value }});
  };

  const onCenterChanged = (value) => {
    setCenterItem(value)
    onChange({ noclose: true, isChange: true, sx: { ...(card.sx || {}), textAlign: value?"center":"left" } });
  };
  console.log(defaultValues)
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
          <Iconify icon="ic:baseline-compare-arrows" width={18} height={18} sx={{ mr: 1 }} />
          Abstände
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0, bgcolor: 'unset' }}>
          
          <Typography sx={{ ml: 2}}>
            <b>Innenabstand</b>
          </Typography>
          <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
          
            <Box sx={{ position: 'relative' }} ref={curElement} id={`preview-${card._id}`}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <TextField
                  value={cardPadding[0]}
                  label="oben"
                  onChange={(e) => onSizeChange(e, 0)}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  sx={{ width: 60 }}
                />
                <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "pt", cardPadding[0])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
              </Box>
              <Grid container>
                <Grid item xs={2} sx={{ margin: 'auto' }}>
                  <TextField
                    label="links"
                    value={cardPadding[3]}
                    onChange={(e) => onSizeChange(e, 3)}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "pl", cardPadding[3])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
                </Grid>

                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <CardItem item={card} column={column} preview /> */}
                  <Iconify icon="akar-icons:square" sx={{ margin: 'auto' }} width={50} height={50} />
                </Grid>
                <Grid item xs={2} sx={{ margin: 'auto' }}>
                  <TextField
                    value={cardPadding[1]}
                    label="rechts"
                    onChange={(e) => onSizeChange(e, 1)}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "pr", cardPadding[1])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16}/>
                    </IconButtonAnimate>
                  </Tooltip>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <TextField
                  label="unten"
                  value={cardPadding[2]}
                  onChange={(e) => onSizeChange(e, 2)}
                  
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  sx={{ width: 60 }}
                />
                <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "pb", cardPadding[2])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
              </Box>
            </Box>
            
          </Stack>
          <TextField sx={{m:2, maxWidth:"300px"}} label="Maximale Breite" onChange={(e)=>onWidthChange(e.target.value)} value={maxWidth}/>
          <Checkbox sx={{m:2, maxWidth:"300px"}} label="Mittig zentrieren" onChange={(e)=>onCenterChanged(e.target.checked)} checked={centerItem}/>
          <Typography sx={{ ml: 2, mt: 5 }}>
            <b>Außenabstand</b>
          </Typography>
          <Stack direction="row" spacing={1} sx={{ mb: 0.5 }}>
            <Box sx={{ position: 'relative' }} ref={curElement} id={`preview-${card._id}`}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                <TextField
                  value={cardMargin[0]}
                  label="oben"
                  onChange={(e) => onMarginChange(e, 0)}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  sx={{ width: 60 }}
                />
                <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "mt", cardMargin[0])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
              </Box>
              <Grid container>
                <Grid item xs={2} sx={{ margin: 'auto' }}>
                  <TextField
                    label="links"
                    value={cardMargin[3]}
                    onChange={(e) => onMarginChange(e, 3)}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "ml", cardMargin[3])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
                </Grid>

                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <CardItem item={card} column={column} preview /> */}
                  <Iconify icon="akar-icons:square" sx={{ margin: 'auto' }} width={50} height={50} />
                </Grid>
                <Grid item xs={2} sx={{ margin: 'auto' }}>
                  <TextField
                    value={cardMargin[1]}
                    label="rechts"
                    onChange={(e) => onMarginChange(e, 1)}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                  <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "mr", cardMargin[1])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <TextField
                  label="unten"
                  value={cardMargin[2]}
                  onChange={(e) => onMarginChange(e, 2)}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  sx={{ width: 60 }}
                />
                <Tooltip title={translate("dragndrop.page.standard")}>
                    <IconButtonAnimate
                      onClick={()=>setDefaultValues(type, "mb", cardMargin[2])}
                    >
                      <Iconify icon={'akar-icons:pin'} width={16} height={16} />
                    </IconButtonAnimate>
                  </Tooltip>
              </Box>
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
          <Iconify
            icon="ic:outline-color-lens"
            width={18}
            height={18}
            sx={{ mr: 1, color: 'primary.main', transform: 'translateY(3px)' }}
          />
          Farben
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0, bgcolor: 'unset' }}>
          <Grid container sx={{px:2}} alignItems="center">
            <Grid item xs={6} sx={{margin: "auto"}}>
              <Typography variant="caption">Hintergrundfarbe</Typography>
              <ColorPopover defaultColor={card.sx?.bgcolor} onColorChange={onColorChange}/>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption">Textfarbe</Typography>
              <ColorPopover defaultColor={card.sx?.color} onColorChange={onColorChangeText}/>
            </Grid>
          </Grid>
          
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
          <Iconify
            icon="ic:outline-color-lens"
            width={18}
            height={18}
            sx={{ mr: 1, color: 'primary.main', transform: 'translateY(3px)' }}
          />
          Weiteres
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0, bgcolor: 'unset' }}>
          <Grid container sx={{px:2}} alignItems="center">
            <Box sx={{borderRadius}}>
              <TextField label="Ecken-Abrundung" value={borderRadius} onChange={(e)=>onBorderRadiusChange(e.target.value)} />
            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
