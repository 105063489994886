import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

import { kanbanBoard } from '../../_mock/kanban';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  systems: [],
  system: []
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.error = action.payload;
    },
    getSystemsComplete(state, action){
      state.systems = action.payload || []
    },
    getSystemComplete(state, action){
      state.system = action.payload || {}
    }
  },
});


// Reducer
export default slice.reducer;

export const {
  setLoading
} = slice.actions;


// ----------------------------------------------------------------------

export function getSystems(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/admin/systems', {
        params: { id },
      });
      await dispatch(slice.actions.getSystemsComplete(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getSystem(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/admin/system', {
        params: { id },
      });
      await dispatch(slice.actions.getSystemComplete(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function saveSystem(dt) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/admin/system', dt);
      await dispatch(slice.actions.getSystemComplete(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}
