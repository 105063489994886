import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, Stack, Drawer, Divider, Card, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useLocales from '../../../hooks/useLocales';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components

import Scrollbar from '../../../components/Scrollbar';

import { NavSectionVertical } from '../../../components/nav-section';

import MainMenu from './MainMenu';
import MainMenuList from './MainMenuList';

const cardStyle = {
  px: 1,
  cursor: 'pointer',
  ':hover': {
    bgcolor: 'grey.50024',
  },
};
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { pathname } = useLocation();
  const [appsActive, setAppsActive] = useState(false);

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const [open, setOpen] = useState(false);
  
  const handleAppsOpen = ()=>{
    setAppsActive(!appsActive)
  }



  const renderContent = (
    <>
      {isDesktop && !isCollapse && (
        <>
         
        </>
      )}
    </>
  );

  return (
    <></>
  );
}
