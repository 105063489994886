import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';
import { dropToWeb } from './pages';
import breakpoints from '../../theme/breakpoints';

// ----------------------------------------------------------------------
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, curr) => {
    accumulator[curr[key]] = curr;
    return accumulator;
  }, {});
}

const convertCss = (id, pstyle, styleNode) => {
  const elem = document.createTextNode(styleNode);

  pstyle.appendChild(elem);
  
  let rules = [...pstyle.sheet.cssRules];
  let ruleText = '';
  rules = rules.map((rule) => {
    if (!(rule.selectorText === ":root")){
      rule.selectorText = `.p-${id} ${rule.selectorText}`;
      ruleText += ` ${rule.cssText}`;
    } else {
      ruleText += ` ${rule.cssText}`;
    }
    return rule;
  });

  
  const ruleElem = document.createTextNode(ruleText);

  pstyle.removeChild(elem);
  pstyle.appendChild(ruleElem);

  return ruleElem;
};

/* 
const schemac = {
  type: "card",
  changed: [
    {name: "sx", old: {...oldObj}, new: {...newObj}},
  ],
}

const schemab = {
  type: "board",
  changed: [
    {name: "title", old: {value: "Schnitzelbrötchen"}, new: {...newObj}},
  ],
}
*/ 

const userSizesTable = [
  {
    name: 'lg',
    size: breakpoints.values.xl,
    icon: 'ant-design:desktop-outlined',
    title: 'modules.boards.responsive.desktop',
    scale: 1,
  },
  {
    name: 'md',
    size: breakpoints.values.md,
    icon: 'ant-design:tablet-outlined',
    title: 'modules.boards.responsive.tablet',
    scale: 1,
  },
  { name: 'xs', size: 360, icon: 'ant-design:mobile-outlined', title: 'modules.boards.responsive.mobile', scale: 0.75 },
];

const initialState = {
  isLoading: false,
  userSizes: [...userSizesTable],
  error: null,
  columns: {},
  category: {},
  card: {},
  rows: [],
  sx: {},
  components: { default: { editor: '' } },
  style: document.createElement('style'),
  pstyle: document.createElement('style'),
  cstyle: document.createElement('style'),

  styleNodes: {},
  styleNodesCustom: {},
  websitePages: [],
  uploading: [],
  default: { columnOrder: [], board: {}, rows: [] },
  curSize: 0,
  snaps: {
    past: [],
    present: 0,
    future: []
  }
};
document.head.appendChild(initialState.style);
document.head.appendChild(initialState.pstyle);
document.head.appendChild(initialState.cstyle);

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startUploading(state, action) {
      state.uploading = [...current(state).uploading, ...action.payload];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.error = action.payload;
    },

    undo(state, action){

    },
    redo(state, action){

    },

    addHistory(state, action){
      const {type, values} = action.payload
      state.past.push({
        type
      })
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      const { board, columnOrder, assignee } = action.payload;
      const columns = objFromArray(
        columnOrder.map((item) => {
          return { ...item, board: board._id, sx: item.sx || {}, cards: item.cards.map((card) => card._id) };
        })
      );
      const curr = current(state);
      const cards = { ...curr.cards };

      columnOrder.map((column) => {
        return column?.cards?.map((card) => {
          cards[card?._id] = card;
          return true;
        });
      });

      state[board._id] = { board, columnOrder: board.columnOrder.map((item) => item?._id._id || item), assignee };
      state.cards = { ...curr.cards, ...cards };
      state.columns = { ...curr.columns, ...columns };

      state.isLoading = false;
    },

    updateBoard(state, action) {
      const { board, title } = action.payload;
      state[board].board = { ...current(state)[board].board, title };
    },

    addCardSuccess(state, action) {
      const { card, destination } = action.payload;
      const { index, page, column } = destination;

      const cards = [...current(state).columns[column].cards];
      cards.splice(index, 0, card);
      state.cards[card._id] = { ...card, isNew: true };

      state.columns[column].cards = cards;
    },

    moveCardSuccess(state, action) {
      const { source, destination, card, newCard } = action.payload;

      state.columns[source.column].cards = [...source.cards]
      if (!destination || destination.id === source.id) return;

      state.columns[destination.column].cards = [...destination.cards.map((item)=>{return {_id: item._id || item}})]
    },

    moveColumnsSuccess(state, action) {
      const { source, destination, column } = action.payload;
      if (destination.id === source.id) {
        state[source.id].columnOrder = source.columnOrder.map((item) => item._id || item);
        return;
      }

      state[destination.id].columnOrder = destination.columnOrder.map((item) => item._id || item);

      state[source.id].columnOrder = source.columnOrder.filter((item) => {
        return item !== column.id;
      });
    },

    addColumnSuccess(state, action) {
      const { column, destination } = action.payload;
      const { index, board } = destination;
      state.columns[column._id] = { ...column, sx: column.sx || {}, cards: [], board, isNew: true };

      const columnOrder = [...current(state)[board].columnOrder];
      columnOrder.splice(index, 0, column._id);
      state[board].columnOrder = columnOrder;
    },

    updateColumnSuccess(state, action) {
      const { title, column, board } = action.payload;
      state.columns[column].title = title;
    },
    updateBoardSuccess(state, action) {
      const { title, board } = action.payload;
      state[board].board.title = title;
    },

    updatePageSuccess(state, action) {
      const { value, id, connected } = action.payload;
      state[id].title = value;
    },

    updateCardSuccess(state, action) {
      const { card, component } = action.payload;
      const {style} = component || {};


      state.cards[card._id] = {
        ...current(state).cards[card._id], 
        rendered: component?component.editor:(current(state).cards[card._id]?.rendered || ""),
        ...card
      };
      if (card.cardType === "custom" && style){

        if (current(state).styleNodesCustom[card._id]){
          console.log(current(state).styleNodesCustom[card._id])
          current(state).cstyle.removeChild(current(state).styleNodesCustom[card._id]);
        }


        const styleNode = document.createTextNode(style);

        state.styleNodesCustom[card._id] = styleNode


        state.cards[card._id].rendered = action.payload.component?.editor
        current(state).cstyle.appendChild(styleNode);
      }
     
    },

    setCardIsNew(state, action){
      const {card, value} = action.payload
      state.cards[card].isNew = value
    },

    updateCardLocal(state, action) {
      const { card, ...other } = action.payload;
      state.cards[card] = { ...current(state).cards[card], ...other };
    },
    updateColumnLocal(state, action) {
      const { column, data } = action.payload;
      state.columns[column].sx = { ...current(state).columns[column].sx, ...data.sx };
    },
    updateRowLocal(state, action) {
      const { row, page, data } = action.payload;
      state[page].rows[row] = {
        ...current(state)[page].rows[row],
        sx: { ...current(state)[page].rows[row].sx, ...data.sx },
      };
    },

    deleteCardSuccess(state, action) {
      const { card, column } = action.payload;
      state.cards[card] = undefined;
      state.columns[column].cards = current(state).columns[column].cards.filter(
        (item) => (item?._id || item) !== card && item !== undefined
      );
    },

    deleteColumnSuccess(state, action) {
      const { column, board, row, type, index } = action.payload;
      if (type === 'board') {

        state[board].columnOrder = [...current(state)[board].columnOrder.filter((item) => item !== column)];
      } else if (type === 'page') {
        const sxCopy = current(state)[board].rows[row]?.sx || {};
        if (sxCopy.gridSize) {
          if (sxCopy.gridSize[index]) sxCopy.gridSize[index].splice(index, 1);
        }


        state[board] = {
          ...current(state)[board],
          columnOrder: [...current(state)[board].columnOrder.filter((item) => item !== column)],
          sx: sxCopy || {},
        };
        state.columns[column] = undefined;
      }
    },

    addAttachmentSuccess(state, action) {
      const { board, card, attachments } = action.payload;
      state.cards[card].attachments = attachments;
    },

    updateCardQuestions(state, action) {
      const { questions, cardId, boardId } = action.payload;
      if (!state.cards[cardId].quizId) {
        return;
      }
      state.cards[cardId].quizId.questions = [...questions];
    },

    addBoardSuccess(state, action) {
      console.log(action.payload);
    },

    // PAGES

    getPageSuccess(state, action) {
      const { page, columns, cards } = action.payload;
      if (!page) return;
      const { columnOrder, title } = page;

      const columnObj = {}
      const cardsObj = {}
      columns.map((item)=>{columnObj[item._id] = item;return true})
      cards.map((item)=>{cardsObj[item._id] = item;return true})
      // rows.map((row, rindex) =>
        /* row.columnOrder?.map((column, cindex) => {
          console.log(column)
          
          /* rows[rindex].columnOrder[cindex].cards.map((card) => {
            cards[card._id?._id] = card._id;

            if (card._id?.code?.[1]?.value) {
              const curr = current(state).styleNodes[card._id._id];

              if (curr) {
                curr.remove();
              }

              const elem = document.createTextNode(card._id.code[1].value);

              current(state).style.appendChild(elem);

              let rules = [...current(state).style.sheet.cssRules];
              let ruleText = '';
              rules = rules.map((rule) => {
                rule.selectorText = `.c-${card._id._id} ${rule.selectorText}`;

                ruleText += ` ${rule.cssText}`;
                return rule;
              });
              const ruleElem = document.createTextNode(ruleText);

              current(state).style.removeChild(elem);
              current(state).style.appendChild(ruleElem);

              state.styleNodes[card._id._id] = ruleElem;
            }
            return true;
          });
          rows[rindex].columnOrder[cindex] = column._id;
          return true;
        }) */
      // );

      state.columns = { ...current(state).columns, ...columnObj };
      state.cards = { ...current(state).cards, ...cardsObj };
      state[page._id] = { ...page, title, columnOrder, sx: page.sx || {} };

      state.isLoading = false;
    },

    getStyleSuccess(state, action){
      const { page, style } = action.payload;
      const currP = current(state)[page._id]?.styleNode;

      if (currP) {
        currP.remove();
      }

      const styleNode = convertCss(page._id, current(state).pstyle, style);
      state[page] = {...state[page], styleNode}
    },

    updateCardCode(state, action) {
      const { code, card } = action.payload;

      if (current(state).styleNodes[card]) state.styleNodes[card].nodeValue = code;
      else {
        const elem = document.createTextNode(code);
        state.styleNodes[card] = elem;
        current(state).style.appendChild(elem);
      }
      /* const rules = [...current(state).style.sheet.cssRules];
      const newRules = rules.map((item) => {
        item.selectorText = `.c-${card} ${item.selectorText}`;
        return item;
      }); */
    },

    addRow(state, action) {
      console.log(action.payload);
    },

    addColumnWebSuccess(state, action) {
      const {  page, column, index } = action.payload;
      if (!Number.isInteger(index)) state[page].columnOrder.push(column._id);
      else state[page].columnOrder.splice(index, 0, column);

      state.columns[column._id] = {
        ...column,
        page,
        sx: column.sx || {},
        cards: column.cards.map((card) => card._id._id || card._id || card),
      };
    },

    addRowWebSuccess(state, action) {
      const { row, page, column, index, isNewPage } = action.payload;
      state[page].rows[index] = row;
      state.columns[column._id] = {
        ...column,
        sx: column.sx || {},
        page,
        cards: column.cards.map((card) => card._id._id),
      };
    },

    addPageSuccess(state, action) {
      console.log('Ok');
    },

    generatePageSuccess(state, action) {
      console.log('Ok');
    },

    changeGridSizeSuccess(state, action) {
      const { page, row, value, index, curSize } = action.payload;
      const curPage = current(state)[page]
      const userSizes = current(state).userSizes;

      if (curPage.gridSize) {
        state[page].gridSize[index] = value;
      } else {
        state[page].gridSize = 
          curPage.columnOrder.map((column, cindex) => {
            return cindex === index
              ? userSizes.map((item, sizeIndex) => {
                  return value[sizeIndex];
                })
              : undefined;
          })
      }
    },

    setSettings(state, action) {
      const { type, page, row, value, what, column, index } = action.payload;
      const cstate = current(state);
      if (type === 'row') {
        if (!cstate[page].rows[row].sx) {
          state[page].rows[row].sx = { [what]: value };
          return;
        }
        state[page].rows[row].sx[what] = value;
        return;
      }
      if (type === 'column') {
        state.columns[column].sx[what] = value;
      }
      if (type === 'page') {
        state[page].sx[what] = value;
      }
    },

    reorderColumns(state, action) {
      const { page, row, destination, value, index, what } = action.payload;
      state[page].rows[row].sx[what] = value;
      state[page].rows[row].columnOrder = [...reorder(current(state)[page].rows[row].columnOrder, index, destination)];
    },
    setCurSize(state, action) {
      state.curSize = action.payload;
    },
    updateStyleSuccess(state, action) {
      const { page, column, type, row, sx } = action.payload;
      if (type === 'column') {
        state.columns[column].sx = { ...current(state).columns[column].sx, ...sx };
      } else if (type === 'row') {
        state[page].rows[row] = { ...state[page].rows[row], sx };
      }
      // state[page].rows[row].sx[what] =
    },

    uploadAttachmentSuccess(state, action) {
      const copy = [];

      current(state).uploading.map((uploaded) => {
        return action.payload
          .map((item) => {
            if (item.size === uploaded.size) {
              return true;
            }
            return false;
          })
          .includes(true);
      });

      state.uploading = copy;
    },

    deleteAttachmentSuccess(state, action) {
      const { card, id } = action.payload;
      state.cards[card].attachments = current(state).cards[card].attachments.filter((item) => item._id !== id);
    },

    getComponentSuccess(state, action) {
      const { _id, style, id, card, editor } = action.payload;

      if (style){
        if (current(state).styleNodesCustom[card]){
          current(state).cstyle.removeChild(current(state).styleNodesCustom[card]);
        }

        const styleNode = document.createTextNode(style);

        state.styleNodesCustom[card] = styleNode
        state.components[_id] = {
          ...action.payload
        };
        current(state).cstyle.appendChild(styleNode);
      }
      state.cards[card].rendered = action.payload.editor
      
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  addCardSuccess,
  updateCardQuestions,
  addRow,
  splitRow,
  setSettings,
  reorderColumns,
  setCurSize,
  updateCardCode,
  updateColumnLocal,
  updateRowLocal,
  updateCardLocal,
  uploadAttachmentSuccess,
  startUploading,
  setCardIsNew,
  undo, redo
} = slice.actions;

// ----------------------------------------------------------------------

export function moveCard({ source, destination, card }) {
  return async (state, action) => {
    try {
      const curState = store.getState();

      if (destination.droppableId?.includes('webeditorrow')) {
        dispatch(dropToWeb({ card, droppable: destination.droppableId }));
        return;
      }
      source.cards = curState.kanban.columns[source.id]?.cards;
      destination.cards = [...curState.kanban.columns[destination.id]?.cards];
      if (!source.cards && !destination.cards) return;
      if (source.id === destination.id) {
        source.cards = reorder(source.cards, source.index, destination.index);
      } else {
        source.cards = source.cards.filter((item) => item !== card.id);
        destination.cards.splice(destination.index, 0, card.id);
      }

      dispatch(slice.actions.moveCardSuccess({ source, destination, card }));
      const response = await axios.post('/column/reorder', { source, destination, card });
      dispatch(slice.actions.moveCardSuccess({ ...response.data, card }));
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function moveColumn({ source, destination, column }) {
  return async (state, action) => {
    try {
      const sourceBoard = store.getState().kanban[source.id];
      const destinationBoard = store.getState().kanban[destination.id];

      source.columnOrder = [...sourceBoard.columnOrder];
      destination.columnOrder = [...destinationBoard.columnOrder];

      if (source.id === destination.id) {
        source.columnOrder = reorder(source.columnOrder, source.index, destination.index);
      } else {
        source.columnOrder = source.columnOrder.filter((item) => item !== column.id);
        destination.columnOrder.splice(destination.index, 0, column.id);
      }

      dispatch(slice.actions.moveColumnsSuccess({ source, destination, column }));

      const response = await axios.post('/board/reorder', { source, destination, column });
      // dispatch(slice.actions.moveColumnsSuccess(response.data));
      return false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function addCard({ destination, cardType, component }) {
  return async () => {
    try {
      const response = await axios.post('/card', { destination, cardType, component });
      dispatch(slice.actions.addCardSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function addBoard({ title }) {
  return async () => {
    try {
      const response = await axios.post('/board', { title });
      dispatch(slice.actions.addBoardSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function generateWebPage({ page, website, link }) {
  return async () => {
    try {
      const response = await axios.post('/page/generate', { page, website, link });
      dispatch(slice.actions.generatePageSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function addPage({ title, recipe, notlisted, website }) {
  return async () => {
    try {
      const response = await axios.post('/page', { title, recipe, notlisted, websiteId: website });
      dispatch(slice.actions.addPageSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function createColumn(newColumn) {
  return async () => {
    try {
      const response = await axios.post('/column', newColumn);
      dispatch(slice.actions.addColumnSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function updateStyle(data) {
  return async () => {
    try {
      const response = await axios.post('/design/update', data);
      dispatch(slice.actions.updateStyleSuccess(data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function changeGridSize({ page, row, value, index, curSize }) {
  return async () => {
    try {
      const response = await axios.post('/design/gridsize', { page, value, index, curSize });
      dispatch(slice.actions.changeGridSizeSuccess({ page, value, index, curSize }));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function createWebColumn(newColumn) {
  return async () => {
    try {
      const response = await axios.post('/column/web', newColumn);
      dispatch(slice.actions.addColumnWebSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function createWebRow({ index, page }) {
  return async () => {
    try {
      const response = await axios.post('/row/web', { index, page });
      dispatch(slice.actions.addRowWebSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    try {
      const response = await axios.post('/modules/kanban/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function renameColumn(column, title, board) {
  return async () => {
    try {
      const response = await axios.post('/column/update', {
        column,
        title,
        board,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function renameBoard(boardId, title) {
  return async () => {
    try {
      const response = await axios.post('/board/update', {
        board: boardId,
        value: title,
      });
      dispatch(slice.actions.updateBoardSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function renamePage({ id, value }) {
  return async () => {
    try {
      const response = await axios.post('/page/update', {
        id,
        value,
      });
      dispatch(slice.actions.updatePageSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn({ column, board, index, row, type }) {
  return async () => {
    try {
      await axios.post('/column/delete', { column, board, index, row, type });
      dispatch(slice.actions.deleteColumnSuccess({ column, board, index, row, type }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCard({ card, column }) {
  return async () => {
    try {
      const response = await axios.delete('/card', { params: { column, card } });
      dispatch(slice.actions.deleteCardSuccess({ card, column }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCard(data) {
  return async () => {
    try {
      const response = await axios.post('/card/update', data);
      dispatch(slice.actions.updateCardSuccess(response.data));
      /* dispatch(slice.actions.addHistory({
        type:"card", values: data, _id: data.card
      })) */
      return response || {};
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function addAssignee(data) {
  return async () => {
    try {
      const response = await axios.post('/kanban/assignee', data);

      return response || { status: 400, data: 'error.internalserver' };
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

// ---------------------------------------------------------------------

export function setPermissions({ user, card, permissions, isColumn, isBoard }) {
  return async () => {
    try {
      const response = await axios.post('/card/permissions', { user, card, permissions, isColumn, isBoard });
      return response || false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return async (dispatch) => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
    try {
      await axios.post('/modules/kanban/card/delete', { cardId, columnId });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBoard(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/board', {
        params: { boardId: id },
      });
      const board = response.data.board;
      const columns = objFromArray(response.data.columns || []);
      const { columnOrder, assignee } = response.data;

      dispatch(slice.actions.getBoardSuccess(response.data));

      return {
        ...board,
        columns,
        columnOrder,
        assignee,
      };
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPage(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (id && id !== '') {
        const response = await axios.get('/page', {
          params: { id },
        });
        dispatch(slice.actions.getPageSuccess(response.data));
      }
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStyle(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (id && id !== '') {
        const response = await axios.get('/api/page/style', {
          params: { id },
        });
        dispatch(slice.actions.getStyleSuccess(response.data));
      }
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getColumn(id) {
  return async () => {
    try {
      const response = await axios.get('/column', {
        params: { columnId: id },
      });
      return response.data || false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ATTACHMENTS

export function addAttachment({ page, attachments, card, video }) {
  return async () => {
    try {
      const response = await axios.post('/kanban/attachment', { page, attachments, card, video });
      dispatch(slice.actions.addAttachmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function deleteAttachment({ cardId, file }) {
  return async () => {
    try {
      const response = await axios.post('/modules/kanban/card/attachment/delete', { cardId, file });
      dispatch(slice.actions.deleteAttachmentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function uploadAttachment({ type, files }) {
  dispatch(slice.actions.startUploading(files));
  return async () => {
    try {
      const form = new FormData();
      files.map((file) => {
        form.append(`file`, file);
        return true;
      });
      const response = await axios.post('/attachment', form);
      dispatch(slice.actions.uploadAttachmentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { data: error, status: 400 };
    }
  };
}

export function getComponent(card, id) {
  return async () => {
    try {
      const response = await axios.get('/api/component', {
        params: { id, card },
      });
      dispatch(slice.actions.getComponentSuccess({...response.data, card, id}));
      return response.data || false;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
