// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/dashboard/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    widgets: path(ROOTS_DASHBOARD, '/widgets')
  },
  modules: {
    recipes: path(ROOTS_DASHBOARD, '/recipes'),
    recipesedit: (id) => path(ROOTS_DASHBOARD, `/recipes/${id}`),

    categories: path(ROOTS_DASHBOARD, '/categories'),
    categoryedit: (id) => path(ROOTS_DASHBOARD, `/categories/${id}`),

    websites: path(ROOTS_DASHBOARD, '/sites'),
    websiteedit: (id) => path(ROOTS_DASHBOARD, `/sites/${id}`),
    websitenew: (id) => path(ROOTS_DASHBOARD, `/sites/new`),

    systems: path(ROOTS_DASHBOARD, '/systems'),
    systemedit: (id) => path(ROOTS_DASHBOARD, `/systems/${id}`),

    prints: (id) => path(ROOTS_DASHBOARD, `/prints`),
    printedit: (id) => path(ROOTS_DASHBOARD, `/prints/print/${id}`),
    orders: (id) => path(ROOTS_DASHBOARD, `/orders`),
    orderview: (id) => path(ROOTS_DASHBOARD, `/orders/order/${id}`),
    shirts: (id) => path(ROOTS_DASHBOARD, `/shirts`),
    shirtedit: (id) => path(ROOTS_DASHBOARD, `/shirts/shirt/${id}`),

    orderedit: (id) => path(ROOTS_DASHBOARD, `/prints/order/${id}`),
    teacher: (id) => path(ROOTS_DASHBOARD, `/teacher`),
    classes: (id) => path(ROOTS_DASHBOARD, `/teacher/classes`),
    quizes: path(ROOTS_DASHBOARD, `/teacher/quizes`),
    quiz: (id) => path(ROOTS_DASHBOARD, `/teacher/quiz?id=${id}`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  boards: {
    view: (id) => path(ROOTS_DASHBOARD, `/boards/${id}`),
  },
  pages: {
    view: (id) => path(ROOTS_DASHBOARD, `/page/${id}`),
  },
  randomizer: path(ROOTS_DASHBOARD, '/randomizer'),
  apps: path(ROOTS_DASHBOARD, '/apps'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
