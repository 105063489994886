import cloneDeep from 'lodash/cloneDeep';

import PropTypes from 'prop-types';
import { DragDropContext} from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import useLocales from '../hooks/useLocales';
import useDrag from '../hooks/useDrag';
import { useDispatch } from '../redux/store';

import {
  createColumn,
  addCard,
  moveCard,
  moveColumn
} from '../redux/slices/kanban';


// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

DragAndDrop.propTypes = {
  children: PropTypes.node,
};

export default function DragAndDrop({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const dragIt = useDrag();

  const onDragStart = (result, other) => {
    const sourceId = result.source.droppableId.split('-')[result.source.droppableId.split('-').length - 1];
    const onDragStart = dragIt.getOnDragStart(sourceId);
    if (onDragStart) {
      onDragStart(result);
    }
  };

  const onDragEnd = async (result, other) => {
    /**
     * DEFINITIONS OF RESPONSE
     */

    // DRAGGED OBJECT
    const draggable = {
      what: result.draggableId.split('-')[result.draggableId.split('-').length - 1],
      id: result.draggableId.split('-')[result.draggableId.split('-').length - 2],
      type: result.type,
    };
    
    console.log("DROPPABLE", result.source.droppableId)
    // SOURCE
    const source = {
      what: result.source.droppableId.split('-')[result.source.droppableId.split('-').length - 1],
      id: result.source.droppableId.split('-')[result.source.droppableId.split('-').length - 2],
      board: result.source.droppableId.split('-')[result.source.droppableId.split('-').length - 3],
      index: result.source.index,
    };

    // source.board = dragIt.getParent(source.id) || source.id;
    source.onDragEnd = dragIt.getOnDragEnd(source.id);

    // DESTINATION
    console.log(result)
    const destination = {
      droppableId: result.destination.droppableId,
      what: result.destination.droppableId.split('-')[result.destination.droppableId.split('-').length - 1],
      id: result.destination.droppableId.split('-')[result.destination.droppableId.split('-').length - 2],

      index: result.destination.index,
    };
    destination.props = dragIt.getProps(destination.id);
    destination.parent = destination.id;
    // destination.board = dragIt.getParent(destination.id) || destination.id

    const sameBoard = source.board === destination.board;
    const sameColumn = destination.id === source.id && sameBoard;
    const isColumn = draggable.type === 'card';
    const isCard = !isColumn;

    const response = {
      destination,
      source,
      draggable,
      sameBoard,
    };
    
    if (isCard) {
        dispatch(
          moveCard({
            source: {...source, column: source.id, board: source.board},
            destination: {...destination, column: destination.id, board: destination.board},
            card: { id: draggable.id, what: source.what }
          })
        );
        return;
    }
    console.log(draggable)
    if (isColumn) {
        dispatch(moveColumn({
          source,
          destination,
          column: { id: draggable.id }
        }))
    }
  };


  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} >
      {children}
    </DragDropContext>
  );
}
