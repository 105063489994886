import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
// @mui
import { Rnd } from 'react-rnd';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import html2canvas from 'html2canvas';
import {
  Box,
  Stack,
  Paper,
  Drawer,
  Divider,
  Typography,
  IconButton,
  Button,
  Slider,
  Grid,
  Tooltip,
  Card,
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
// components

import { dispatch, useSelector } from '../../../redux/store';
import { changeGridSize } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import ResponsiveManager from './ResponsiveManager';
import Scrollbar from '../../Scrollbar';

// ----------------------------------------------------------------------

WidthManager.propTypes = {
  rowObject: PropTypes.object,
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};



export default function WidthManager({ initialSize, page, row, columnOrder, children, index, width }) {

  const { translate } = useLocales();

  const { curSize, userSizes } = useSelector((state) => state.kanban);

  const [columnSize, setColumnSize] = useState([]);
  const [screenSize, setScreenSize] = useState(1000);

  useEffect(() => {
    setColumnSize(initialSize || [12, 12, 12]);
  }, [page, curSize]);

  const handleResize = (e)=>{
    // console.log(e)
  }

  const handleChange = (d, index) => {
    try {

      const diff = Number(Number((d.width / (screenSize / 12))).toFixed(2))
      const copy = [...columnSize];
      if (copy[curSize] + Number(diff) > 12 || Number.isNaN(copy[curSize])) copy[curSize] = 12;
      else copy[curSize] += Number(diff);

      setColumnSize(copy)
      dispatch(changeGridSize({ page, index, value: copy, curSize }));

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setScreenSize(document.getElementById(`b-${page}-page`)?.offsetWidth)
  }, [curSize]);

  const onWindowResize = ()=>{
    setScreenSize(document.getElementById(`b-${page}-page`)?.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return ()=>{
      window.removeEventListener('resize', onWindowResize)
    }
  }, []);
  
  return (
    <Box sx={{
      "&:hover .resize-handler":{
        bgcolor: 'text.secondary',
        zIndex:1000
      }
    }}>
      <Rnd
        enableResizing={{
          bottom: false,
          bottomLeft: false,
          bottomRight: false,
          left: false,
          right: true,
          top: false,
          topLeft: false,
          topRight: false,
        }}
        minWidth={screenSize?screenSize / 12: 32}
        default={{top:0, left:0}}
        maxWidth={screenSize}
        disableDragging
        onResizeStop={(e, m, n, c) => handleChange(c, index)}
        onResize={handleResize}
        resizeHandleComponent={{
          right: (
            <>
              <Box className="resize-handler" sx={{ transform:"translateX(0px)",zIndex:10000, width: '5px', height: '100%' }} />
            </>
          ),
        }}
        resizeGrid={[1, 0]}
        style={{
          position: 'relative',
          top:0, left: 0,
          
        }}
        bounds={`drag-parent-${index}`}
        className="width-manager"
        size={{width: columnSize[curSize]*(screenSize/12)}}
      >
        {children}
      </Rnd>
      </Box>
  );
}
