import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, Typography, Paper, TextField, Link, ListItemText, Tooltip, Button } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import CardModalGlobal from '../components/CardModalGlobal';

import { useDispatch } from '../../../redux/store';
import Editor from '../../editor';
import Iconify from '../../Iconify';
import LinkCardSettings from '../components/LinkCardSettings';
import { isExternalLink } from '../../nav-section';

// ----------------------------------------------------------------------

LinkCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export function LinkCard({ disableTitle, onOpen, paperStyle = {}, preview, onChange, card, sx, ...other }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [open, setOpen] = useState(null);

  const openWindow = () => {
    if (!preview) setOpen(true);
  };

  const onClose = async (data) => {
    const response = await onChange({ ...data, _id: card._id });
    if (response === 'to') {
      setTimeout(() => {
        setOpen(false);
      }, 200);
      return;
    }
    if (!data.noclose) setOpen(false);
  };

  return (
    <Box
      sx={{
        '&:hover .settings-btn': {
          display: 'block',
        },
      }}
    >
      <Paper
        component={isExternalLink(card?.path) ? Link : RouterLink}
        to={card?.path || '/'}
        href={card?.path || '/'}
        target={isExternalLink(card?.path) ? '_blank' : ''}
        rel="noopener"
        sx={{
          p: 2,
          width: 1,

          display: 'block',
          overflow: 'hidden',
          textDecoration: 'none',
          position: 'relative',
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            position: 'absolute',
            backgroundSize: 'cover !important',
          },

          ...sx,
          ...paperStyle,
        }}
      >
        <Box>
          {(!card.description || card.text === '') && (!card.name || card.name === '') && (
            <Typography sx={{ textDecoration: 'none' }} variant="caption">
              {translate('dragndrop.addtext')}
            </Typography>
          )}
          {!disableTitle && (card.description || card.name !== '') && (
            <Typography sx={{ textDecoration: 'none' }} variant="subtitle">
              {card.name}
            </Typography>
          )}
          {(!card.description || card.name === '' || disableTitle) && (
            <Box sx={{ textDecoration: 'none' }} dangerouslySetInnerHTML={{ __html: card.description }} />
          )}
        </Box>
      </Paper>
      <Tooltip title={translate('general.settings')}>
        <Button
          onClick={onOpen}
          className="settings-btn"
          sx={{
            display: 'none',
            position: 'absolute',
            right: 4,
            top: 4,
            zIndex: 100,
            p: 0,
            minWidth: 'unset',
            '&:hover': { transform: 'scale(1.1)' },
            transition: '0.2s transform',
          }}
        >
          <Iconify icon="ic:baseline-settings" sx={{ width: 15, height: 15 }} />
        </Button>
      </Tooltip>
    </Box>
  );
}

export function LinkModal({ card, preview, open, onUpdate, ...other }) {
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setName(card?.name || '');
    setDescription(card?.description || '');
    setPath(card?.path || '');
  }, [card]);
  console.log('Ok loaded!');
  return (
    <>
      <TextField
        sx={{ mb: 2 }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        onBlur={() => onUpdate({ name, changed: true })}
        label="Titel"
        variant="standard"
        fullWidth
      />
      <TextField
        sx={{ mb: 2 }}
        value={path}
        onChange={(e) => {
          setPath(e.target.value);
        }}
        onBlur={() => onUpdate({ path, changed: true })}
        label="Link"
        variant="standard"
        fullWidth
      />
      <Editor
        simple
        id={'editor'}
        value={description}
        onChange={(e) => {
          setDescription(e);
        }}
        onBlur={() => onUpdate({ description, changed: true })}
        sx={{ mb: 2 }}
      />
    </>
  );
}
