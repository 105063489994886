import { flexbox } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Avatar, Tooltip } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import useAuth from '../../hooks/useAuth';

import { useDispatch } from '../../redux/store';
import { updateCard, addAttachment } from '../../redux/slices/kanban';
import useLocales from '../../hooks/useLocales';

import axios from '../../utils/axios';

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  visibility: isDragging ? 'visible' : 'unset',
  position:"relative",
  ...draggableStyle,

  zIndex: isDragging?10000:0
});

export default function DropListItem({ dropDisabled, onlyDrag, dragOnly, children, index = 0, id, item, noDrag = false, board }) {
  const { translate } = useLocales();
  const [state, setState] = useState(null);

  const dispatch = useDispatch();


  const onDrop = async (e) => {
    setState(false);
    if (e.dataTransfer.files.length > 0) {
      const files = [...e.dataTransfer.files];

      const form = new FormData();
      files.map((file) => form.append(`file`, file));
      const response = await axios.post('/attachment', form);
      dispatch(
        addAttachment({ board, attachments: response.data?.map((item) => item._id || ''), card: item._id })
      );
    }
  };

  const renderContent = (
    <Draggable key={`${id}-card`} draggableId={`${id}-card`} type='card-card' index={index} fullWidth>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...(noDrag ? undefined : provided.draggableProps)}
            style={noDrag ? undefined : getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
            did={`${item}-card`}
            onDrop={(e) => {
              onDrop(e, item, 'card');
            }}
          >
            <div {...provided.dragHandleProps} style={{position:"relative", top:0, left: 0}}>{children}</div>
          </div>
          {provided.placeholder}
        </>
      )}
    </Draggable>
  );
  return onlyDrag ? (
    <Droppable droppableId={`drop-${item}-nope`} direction="vertical" type={'card-card'}>
      {(provided) => (
        <>
          <div ref={provided.innerRef} style={{ width: '100%' }}>
            {renderContent}
          </div>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  ) : (
    renderContent
  );
}
