import PropTypes from 'prop-types';
import React from 'react';

import { defaultSettings } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
};

const DragContext = React.createContext(initialState);

// ----------------------------------------------------------------------

class DragProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setDragEnabled(bool){
    this.state.isDragging = bool
  }

  getDragEnabled(bool){
    return this.state.isDragging
  }

  getItems(id) {
    return this.state[id]?.items || [];
  }

  getOnDragEnd(id) {
    return (this.state[id]?.onDragEnd ||
      this.state[this.getParent(id)]?.onDragEnd ||
      this.state[this.getParent(this.state[this.getParent(id)])]?.onDragEnd)
  }

  getOnDragStart(id) {
    return (this.state[id]?.onDragStart ||
      this.state[this.getParent(id)]?.onDragStart ||
      this.state[this.getParent(this.state[this.getParent(id)])]?.onDragStart)
  }

  getProps(id) {
    return (this.state[id]?.props ||
      this.state[this.getParent(id)]?.props ||
      this.state[this.getParent(this.state[this.getParent(id)])]?.props)
  }

  getParent(id) {
    return this.state[id]?.parent || undefined;
  }

  connectItems(parent, items, onDragEnd, props) {
    const list = this.state;
    list[parent] = { items, onDragEnd: onDragEnd || this.getOnDragEnd(parent), props: props || this.getProps(parent), onDragStart: props?props.onDragStart || this.getOnDragStart(parent):undefined  };
    items.map((item, index) => {
      const allItems = Array.isArray(item) ? item : item?.cards || [];
      list[item?._id] = { parent, items: allItems };
      allItems.map((subitem, index) => {
        list[subitem._id] = { parent: item?._id };
        return true;
      });
      return true;
    });
    this.setState(list);
  }

  mergeItems() {
    console.log(this.state.tada);
  }

  addDraggableItemGroup() {
    console.log(this.state.tada);
  }

  render() {
    return (
      <DragContext.Provider
        value={{
          getItems: this.getItems.bind(this),
          addDraggableItemGroup: this.addDraggableItemGroup.bind(this),
          connectItems: this.connectItems.bind(this),
          getProps: this.getProps.bind(this),
          mergeItems: this.mergeItems.bind(this),
          getParent: this.getParent.bind(this),
          getOnDragEnd: this.getOnDragEnd.bind(this),
          getOnDragStart: this.getOnDragStart.bind(this),
          setDragEnabled: this.setDragEnabled.bind(this),
          getDragEnabled: this.getDragEnabled.bind(this),
        }}
      >
        {this.props.children}
      </DragContext.Provider>
    );
  }
}

export { DragContext, DragProvider };
