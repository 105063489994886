import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from './Image'

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  type: PropTypes.string
};

export default function Logo({ style, disabledLink = false, sx, type = "light" }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box {...style} sx={{ width: 40, height: 40, ...sx }}>
      <Image disabledEffect src="/logo/logo.svg"/>
    </Box>
  );
  const logosmall = (
    <Box {...style} sx={{ width: 40, height: 40, ...sx }}>
      <Image disabledEffect src="/logo/logo.svg"/>
    </Box>
  );
  const logodark = (
    <Box {...style} sx={{ width: 200, height: 40, ...sx }}>
      <Image disabledEffect src="/logo/Logo_Schriftzug.svg"/>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }
  if (type === "light"){
    return <RouterLink to="/dashboard">{logodark}</RouterLink>;
  }
  if (type === "icondark"){
    return <RouterLink to="/dashboard">{logosmall}</RouterLink>;
  }
  return <RouterLink to="/dashboard">{logo}</RouterLink>;
}
