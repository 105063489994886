import { flexbox } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Avatar, Tooltip } from '@mui/material';

import useAuth from '../../hooks/useAuth';

import { useDispatch } from '../../redux/store';
import { updateCard, addAttachment } from '../../redux/slices/kanban';
import useLocales from '../../hooks/useLocales';

import { NavListRoot } from '../nav-section/vertical/NavList';

import TaskCard from './cards/TaskCard';


import BoardCard from './cards/BoardCard';
import PageCard from './cards/PageCard';
import QuizCard from './cards/QuizCard';
import RecipeCard from './cards/RecipeCard';
import HTMLCard from './cards/HTMLCard';

import axios from '../../utils/axios';
import CardModalGlobal from './components/CardModalGlobal';
import CardItemGlobal from './components/CardItemGlobal';

export default function CardItem({ item, ...other }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  const [state, setState] = useState(null);
  const [open, setOpen] = useState(false);

  const onChange = async (data) => {
    await dispatch(updateCard({ ...data, board: other.board, card: item._id }));
  };

  const onDragEnter = (e) => {
    setState(true);
  };

  const onDragLeave = (e) => {
    setState(false);
  };

  const onDrop = async (e) => {
    setState(false);
    if (e.dataTransfer.files.length > 0) {
      const files = [...e.dataTransfer.files];

      const form = new FormData();
      files.map((file) => form.append(`file`, file));
      const response = await axios.post('/attachment', form);
      dispatch(
        addAttachment({ board: other.board, attachments: response.data?.map((item) => item._id || ''), card: item._id })
      );
    }
  };

  const toggleModal = (b) => {
    
    setOpen(!open);
  };

  if (!item?._id){
    return <></>;
  }

  return (
    <Box
      sx={{ py: other.column.column?.sx?.space || 0.5, ...other?.sx || {}, ...item?.sx || {} }}
      onDragOver={onDragEnter}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      <Box
        {...other}
        sx={{
          transform: state ? 'scale(0.95)' : 'unset',
          opacity: state ? '0.7' : '1',
          transition: '0.1s',
          visibility: 'visible',
        }}
      >
        {item?.cardType === 'board' && <BoardCard {...other} onChange={onChange} card={item} />}
        {item?.cardType === 'page' && <PageCard {...other} onChange={onChange} card={item} />}
        {/* item?.cardType === 'image' && <ImageCard {...other} onChange={onChange} card={item} /> */}
        {item?.cardType === 'task' && <TaskCard {...other} onChange={onChange} card={item} />}
        { /* item?.cardType === 'code' && <HTMLCard {...other} onChange={onChange} card={item} /> */}
        {item?.cardType === 'quiz' && <QuizCard {...other} onChange={onChange} card={item} />}
        {item?.cardType === 'recipe' && <RecipeCard {...other} onChange={onChange} post={item?.recipe} />}

        {item?._id && (
          <div>
            <>
              {/* CARD */}
              <CardItemGlobal {...other} card={item} onChange={onChange} onOpen={toggleModal} /> 
              
              {/* MODAL */}

              {(open || item.isNew) && ( 
                <CardModalGlobal {...other} type={item?.cardType} open={open || item.isNew} onClose={toggleModal} card={item}  />
              )}
            </>
          </div>
        )}
      </Box>
    </Box>
  );
}
