import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

// @mui
import { Container, ListSubheader, Grid, Typography, Divider } from '@mui/material';
// redux
import { styled } from '@mui/material/styles';

import { useDispatch, useSelector } from '../../redux/store';
import { getBoard } from '../../redux/slices/kanban';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

import useLocales from '../../hooks/useLocales';
import Scrollbar from '../../components/Scrollbar';
import { DropList, CardItem, DropListItem, ColumnList, Column, ColumnHeader } from '../../components/dragndrop';

// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import axios from '../../utils/axios';
import LoadingScreen from '../../components/LoadingScreen';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

export default function Apps({dropDisabled}) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const {boardId} = useParams()

  const [boardLoaded, setBoardLoaded] = useState(null);

  const { board, columnOrder } = useSelector((state) => state.kanban[boardLoaded || 'default']);
  const { columns, cards, isLoading } = useSelector((state) => state.kanban);

  useEffect(async () => {
    const data = await dispatch(getBoard('appboard'));
    if (data) {
      setBoardLoaded(data._id);
    }
  }, []);


  const onDragEnd = (newColuns) => {};
  const cardupdateevent = (card, column, data) => {};

  return (<>
    <ColumnList dropDisabled id={board._id} onDragEnd={onDragEnd} direction="vertical">
      {columnOrder.map((column, index) => (
        <Column
          index={index}
          key={column}
          column={columns[column]}
          dropDisabled={dropDisabled}
          board={board._id}
          header={<ColumnHeader>{translate(columns[column]?.title)}</ColumnHeader>}
          sx={{pt: 0.5, my:0, bgcolor: "unset"}}
        >
          {columns[column].cards?.map((card, cardIndex) => (
            card && <DropListItem dropDisabled={dropDisabled} index={cardIndex} key={card} id={`${column}-${card}`}>
              {cards[card]?._id && <><CardItem
                column={{ _id: column, index }}
                cardIndex={cardIndex}
                cardupdateevent={cardupdateevent}
                item={cards[card]}
                
                sx={{m: 0, py: 0.25}}
                active={boardId && (boardId===cards[card]?.pageId?._id || boardId===cards[card]?.boardId?._id)}
              />
             </>}
            </DropListItem>
          ))}
        </Column>
      ))}
    </ColumnList>
    {isLoading && <>
        <LoadingScreen isDashboard />
      </>}
    </>
  );
}
