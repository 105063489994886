import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, IconButton, ListItemText, ListItem } from '@mui/material';

// utils
import { fData } from '../../utils/formatNumber';

import { DragListColumn, DragListItem } from '../draglist';
import ImageEdit from './ImageEdit';



//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.key || file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------

ImagePreview.propTypes = {
  files: PropTypes.array,
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
};

export default function ImagePreview({ file, onRemove, _id }) {
  const { key, name, size, preview } = getFileData(file);
  const [isOpen, setIsOpen] = useState(false)
  const onClose = ()=>{
    setIsOpen(false)
  }

  return (<>
    <ListItem
      key={key}
      component={m.div}
      {...varFade().inRight}
      onClick={()=>setIsOpen(true)}
      sx={{
        p: 0,
        m: 0.5,
        width: 80,
        height: 80,
        borderRadius: 1.25,
        overflow: 'hidden',
        position: 'relative',
        display: 'inline-flex',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Image alt="preview" src={isString(file) ? file : preview} ratio="1/1" />
      <IconButton
        size="small"
        onClick={() => onRemove(file)}
        sx={{
          top: 6,
          p: '2px',
          right: 6,
          position: 'absolute',
          color: 'common.white',
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
          },
        }}
      >
        <Iconify icon={'eva:close-fill'} />
      </IconButton>
      
    </ListItem>
    <ImageEdit isOpen={isOpen} onClose={onClose} _id={_id} file={file} />
    </>
  );
}
