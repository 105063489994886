import { forwardRef, useRef, useState, useEffect, useImperativeHandle } from 'react';
import { Tooltip, Grid, Checkbox, FormControlLabel, Box, Typography, OutlinedInput } from '@mui/material';
import { SliderPicker } from 'react-color';
import { useSnackbar } from 'notistack';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import useSettings from '../../../../hooks/useSettings';

// _mock_

import { useDispatch } from '../../../../redux/store';
import useLocales from '../../../../hooks/useLocales';

import { setPermissions } from '../../../../redux/slices/kanban';

// import { getConfiguration } from '../../../../redux/slices/settings';
// components
import Iconify from '../../../Iconify';

import { IconButtonAnimate } from '../../../animate';

import { CardPermissions, ColumnPermissions, BoardPermissions } from '../../../../_mock';

import { CardAssigneeAkkordeon } from '.';

// ----------------------------------------------------------------------

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const CardAssigneePermissions = forwardRef(
  ({ isColumn, isBoard, handleClose, cardIndex, user, card, cardupdateevent, columnUpdateEvent, column }, ref) => {
   
    const titleInput = useRef(null);
    const { translate } = useLocales();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState('');
    const [shorten, setShorten] = useState('');
    const [color, setColor] = useState('');

    const [checked, setChecked] = useState({});
    const [parentChecked, setParentChecked] = useState({});
    const [parentMediate, setParentMediate] = useState({});

    const { permissions, onPermissionAdd } = useSettings();

    const [standardPermissions, setStandardPermissions] = useState(permissions.map((item)=>{return isJsonString(item)?JSON.parse(item):{}}))




    const [userPermissions, setUserPermissions] = useState([]);
    const [isNewOpen, setIsNewOpen] = useState(false);

    // const allPermissions = isColumn ? ColumnPermissions : ;
    let allPermissions
    if (isBoard) allPermissions = BoardPermissions
    if (isColumn) allPermissions = ColumnPermissions
    if (!allPermissions) allPermissions = CardPermissions

    console.log(allPermissions)
    useEffect(()=>{
      const obj = {}
      const uperms = []
      allPermissions.map((parent)=>{
        const checkedChilds = parent.children.filter((child)=>{
          if (checked[child.short]){
            uperms.push(child.short)
          }
          return checked[child.short]
        })
        if (checkedChilds.length === parent.children.length){
          obj[parent.short] = "checked"
          return true
        }
        if (checkedChilds.length === 0){
          obj[parent.short] = "false"
          return true
        }
        obj[parent.short] = "mediate"
        return true
      })
      setUserPermissions([...uperms]);
      setParentChecked({...obj})
    }, [checked])

    const putPermission = (isParent, data, e) => {
      const parent = isParent?data.short:data.parent
      if (isParent){
        const obj = {}
        data.children.map((item)=>{
          obj[item.short] = parentChecked[data.short]==="false"
          return true
        })

        setChecked({...checked, ...obj})
      } else {
        setChecked({...checked, [data.short]: !checked[data.short]})
      }
    };

    const savePermissions = async (e, d) => {
      const response = await dispatch(
        setPermissions({ isBoard, isColumn, user: user._id, card: card._id, permissions: JSON.stringify(userPermissions) })
      );
      enqueueSnackbar(translate(response.data), { variant: response.status === 400 ? 'error' : 'success' });
      card.assignee = card.assignee.map((item) => (item._id === user._id ? { ...item, p: userPermissions } : item));
      if (!isColumn)
        cardupdateevent({ _id: card._id, index: cardIndex }, column, { p: JSON.stringify(userPermissions) });
      if (isColumn)
        columnUpdateEvent({ _id: card._id, index: cardIndex }, column, { p: JSON.stringify(userPermissions) });
      
      if(!d){
        console.log("TEST")
        handleClose();
      } 
    };

    

    const resetSettings = () => {
      const obj = {}
      if (Array.isArray(user.p)) {
        setUserPermissions(user.p);
        user.p.map((item)=>{
          obj[item] = true
          return true
        })
      } else {
        const arr = isJsonString(user.p) ? JSON.parse(user.p) : []
        setUserPermissions(arr);
        arr.map((item)=>{
          obj[item] = true;
          return true
        })
      }
      setChecked({...obj})
    };

    const addAsDefault = ()=>{
      setIsNewOpen(true)
      // onPermissionAdd(userPermissions)
    }

    const addAsDefaultFinished = ()=>{
      const finished = {
        color,
        name,
        shorten,
        value: userPermissions,
      }
      const data = onPermissionAdd(finished)
      setName('')
      setShorten('')
      setIsNewOpen(false)
      setStandardPermissions(data.map((item)=>{return isJsonString(item)?JSON.parse(item):{}}))
    }

    useEffect(() => {
      resetSettings();
    }, [user]);

    const setConfigurationToSaved = (item)=>{
      setUserPermissions(item.value)
    }


    useImperativeHandle(ref, () => ({
      save() {
        console.log("SAVE HERE")
        savePermissions(undefined, true);
      },
    }));

    useEffect(()=>{
      if (isNewOpen) {
        if (titleInput.current) {
          titleInput.current.focus();
        }
      }
    }, [isNewOpen])

    return (
      <>
        {!isNewOpen && <div style={{ float: 'right' }}>
          <Tooltip title={translate('userpage.save')}>
            <IconButtonAnimate color="primary" size="small" onClick={savePermissions}>
              <Iconify icon={'fluent:save-28-regular'} width={25} height={25} />
            </IconButtonAnimate>
          </Tooltip>
        </div>}

        <Typography variant="caption">
          {translate('permissions.standard')}
          <Tooltip title={translate('menu.edit')}>
            <IconButtonAnimate size="small">
              <Iconify icon={'eva:edit-fill'} style={{ transform: 'translateY(3px)' }} />
            </IconButtonAnimate>
          </Tooltip>
          <Tooltip title={translate('menu.undo')}>
            <IconButtonAnimate size="small" onClick={resetSettings}>
              <Iconify icon={'eva:undo-fill'} style={{ transform: 'translateY(3px)' }} />
            </IconButtonAnimate>
          </Tooltip>
          <Tooltip title={translate('menu.addperm')}>
            <IconButtonAnimate size="small" onClick={addAsDefault}>
              <Iconify icon={'eva:plus-fill'} style={{ transform: 'translateY(3px)' }} />
            </IconButtonAnimate>
          </Tooltip>
          {isNewOpen && <>
            <Tooltip title={translate('menu.saveconf')}>
            <IconButtonAnimate size="small" onClick={addAsDefaultFinished}>
              <Iconify icon={'fluent:save-28-regular'} style={{ transform: 'translateY(3px)' }} />
            </IconButtonAnimate>
          </Tooltip>
          </>}
        </Typography>

        {isNewOpen && 
          <Box sx={{p:1}}>
            <Box  sx={{ display: 'flex', flexGrow: 1}} >
              <OutlinedInput
                
                ref={titleInput}
                multiline
                size="small"
                placeholder={translate("menu.configurationname")}
                value={name}
                autoFocus
                onChange={(e) => {
                  setName(e.target.value);
                }}
                sx={{
                  width: "100%",
                  typography: 'caption',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                }}
              />
              <OutlinedInput
              
                multiline
                size="small"
                placeholder={translate("menu.short")}
                value={shorten}
                inputProps={{ maxLength: 3 }}
                onChange={(e) => {
                  setShorten(e.target.value);
                }}
                sx={{
                  color,
                  flexShrink: 1,
                  typography: 'caption',
                  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
                }}
              />
            </Box >
            
            <SliderPicker
              onChange={(e)=>{setColor(e.hex)}}
              color={color}
            />
          </Box>
        }


        <Grid sx={{ display: 'flex', justifyContent: 'left' }}>
          {standardPermissions.map((item, index) => (
            <Tooltip key={index} title={item.name || ""}>
              <IconButtonAnimate
                color="primary"
                onClick={() => {
                  setConfigurationToSaved(item)
                }}
              >
                <Typography sx={{color: item.color}} variant="caption">{item.shorten}</Typography>
              </IconButtonAnimate>
            </Tooltip>
          ))}
        </Grid>
        <Typography variant="caption">{translate('permissions.permissions')}</Typography>
        {allPermissions.map((accordion, index) => (
          <Grid key={index} sx={{ display: 'flex' }}>
            <Checkbox
              sx={{ width: 20, height: 20, transform: 'translateY(13px)' }}
              onClick={(e) => {
                putPermission(1, accordion, e);
              }}
              indeterminate={parentChecked[accordion.short] === "mediate"}
              checked={parentChecked[accordion.short]==="checked"}
            />
            <CardAssigneeAkkordeon accordion={accordion}>
              {accordion.children?.map((item, subindex) => (
                <Grid key={subindex} sx={{ display: 'flex' }}>
                  <FormControlLabel
                    label={translate(item.subtitle)}
                    control={
                      <Checkbox
                        sx={{ width: 20, height: 20, mr: 1 }}
                        onClick={(e) => {
                          putPermission(0, item, e);
                        }}
                        checked={checked[item.short] || false}
                      />
                    }
                  />
                </Grid>
              ))}
            </CardAssigneeAkkordeon>
          </Grid>
        ))}
      </>
    );
  }
);
