import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';

import { Stack } from '@mui/material';
import useSearch from '../../hooks/useSearch';
import Image from '../Image'

import Scrollbar from '../Scrollbar';

// ----------------------------------------------------------------------



SearchResults.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  type: PropTypes.string
};

export default function SearchResults(props) {
  const theme = useTheme();
  const [searchResults, setSearchResults] = useState([])
  const search = useSearch()

  useEffect(async ()=>{
    console.log(await search.getSearchResults())
    setSearchResults([...(await search.getSearchResults())])
    
  }, [props.search])
  const changeSearchResults = () => {
    
  }
  
   return (
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {searchResults}
        </Stack>
    );
}
