import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// form

// @mui
import { Button, Typography } from '@mui/material';
// routes
import useLocales from '../../../../hooks/useLocales';

import { dispatch, useSelector } from '../../../../redux/store';
import { setQuestions, addQuestion } from '../../../../redux/slices/question';
import { updateCardQuestions } from '../../../../redux/slices/kanban';
import Question from './Question'

import Iconify from '../../../Iconify';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function QuestionList({ board, card }) {

  const { translate } = useLocales();
  const { questions } = useSelector((state) => state.question);

  const onNewQuestion = () => {
    dispatch(addQuestion(card.quizId?._id));
  };

  useEffect(() => {
    dispatch(setQuestions(card.quizId?.questions));
  }, []);

  useEffect(()=>{
    if (!questions) return;
    dispatch(updateCardQuestions({questions, cardId: card._id, boardId: board}));
  }, [questions])

  const sync = ()=>{
    if (!questions) return;
    dispatch(updateCardQuestions({questions, cardId: card._id, boardId: board}));
  }

  return (
    <>
        {questions?.map((item, index)=>(
            <div key={index}>
                <Question question={item} index={index} card={card} sync={sync} />
            </div>
        ))}
      <Button onClick={onNewQuestion}>{translate('dragndrop.quiz.newquestion')}</Button>
    </>
  );
}
