import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
// components
import useResponsive from '../../../hooks/useResponsive';

import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import LightboxModal from '../../../components/LightboxModal';

import { DragListColumn, DragListItem } from '../../../components/draglist';

import { varFade } from '../../../components/animate';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

KanbanTaskAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  onAttachChanged: PropTypes.func,
  deleteAttach: PropTypes.func,
};

export default function KanbanTaskAttachments({ attachments, onAttachChanged, deleteAttach }) {
  const isDesktop = useResponsive('up', 'sm');
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const imagesLightbox = attachments;

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const setAttachements = (files) => {
    const array = [...attachments, ...files];
    onAttachChanged(array, files);
    // setFiles(array)
    return array;
  };

  const handleRemove = (file) => {
    const filteredItems = attachments.filter((_file) => _file.name !== file.name);
    deleteAttach(filteredItems, file);
  };

  return (
    <>
      {attachments.map((attachment, index) => {
        const { name, url, medium, fileType } = attachment;
        const what = name.split('.')[name.split('.').length - 1];
        console.log(what, fileType);
        const key = isString(attachment) ? attachment : name;
        return (
          <Box
            key={key}
            {...varFade().inRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
              '&:hover ': {
                '& button': {
                  display: 'block !important',
                },
              },
            }}
          >
            {fileType === 'image' && (
              <Image
                key={attachment.name}
                src={attachment.url.medium}
                onClick={() => handleOpenLightbox(attachment)}
                sx={{
                  m: 0.5,
                  width: 64,
                  height: 64,
                  borderRadius: 1,
                  cursor: 'pointer',
                }}
              />
            )}
            {fileType === 'video' && (
              <>
                <Image
                  key={attachment.name}
                  src={attachment.url.medium}
                  onClick={() => handleOpenLightbox(attachment)}
                  sx={{
                    m: 0.5,
                    width: 64,
                    height: 64,
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                />
                <Iconify
                  onClick={() => handleOpenLightbox(attachment)}
                  icon="akar-icons:play"
                  width={30}
                  height={30}
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    cursor: 'pointer',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </>
            )}
            <Box sx={{ top: 2, right: 2, position: 'absolute' }}>
              <IconButton
                size="small"
                onClick={() => handleRemove(attachment)}
                sx={{
                  display: isDesktop ? 'none' : '',
                  p: '2px',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
              >
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Box>
          </Box>
        );
      })}

      <UploadFile files={attachments || []} onDrop={setAttachements} />

      {openLightbox && (
        <LightboxModal
          images={imagesLightbox}
          mainSrc={imagesLightbox[selectedImage]?.url.full}
          photoIndex={selectedImage}
          setPhotoIndex={setSelectedImage}
          isOpen={openLightbox}
          onCloseRequest={() => setOpenLightbox(false)}
        />
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function UploadFile(props) {
  const handleRemove = (file) => {
    const filteredItems = props.files.filter((_file) => _file !== file);
  };

  const handleDrop = useCallback(async (acceptedFiles) => {
    props.onDrop(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
        }}
      >
        <input {...getInputProps()} />
        <Iconify icon={'eva:plus-fill'} sx={{ color: 'text.secondary' }} />
      </DropZoneStyle>
    </>
  );
}
