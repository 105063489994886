import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Paper, Typography, Box, Checkbox } from '@mui/material';
// components
import Image from '../../Image';
import Iconify from '../../Iconify';
import useLocales from '../../../hooks/useLocales';
//
import QuizModal from '../components/QuizModal';


// ----------------------------------------------------------------------

QuizCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  onFinish: PropTypes.func,
};

export default function QuizCard({ card, preview, onChange, onDeleteTask, onFinish, index, ...other }) {
  const { translate } = useLocales();
  const [ openDetails, setOpenDetails ] = useState(false);
  const { name, description, attachments, completed, isNew } = card;

  const handleOpenDetails = () => {
    if (!preview)
      setOpenDetails(true);
  };

  const handleCloseDetails = async(data) => {
    const response = await onChange({...data, _id: card._id})
    if (response === "to"){
      setTimeout(()=>{setOpenDetails(false)}, 200)
      return;
    }
    setOpenDetails(false)
  };

  const handleChangeComplete = async (event) => {
    console.log({isChange: true, completed: event.target.checked, _id: card._id})
    await onChange({isChange: true, completed: event.target.checked, _id: card._id})
  };

  const onAttachAddCard = (files) => {
    handleCloseDetails({...card})
  };

  return (
    <div style={{padding: "5px 0"}}>
      <Paper
        sx={{
          px: 1,
          py: 1,
          pb: 2,
          width: 1,
          position: 'relative',
          boxShadow: (theme) => theme.customShadows.z1,
          '&:hover': {
            boxShadow: (theme) => theme.customShadows.z16,
          },
          ...(attachments.length > 0 && {
            py: 1,
          }),
        }}
      >
        <Typography variant="subtitle2" sx={{p:1, textDecoration: "underline"}}>
          <Iconify icon={'bx:question-mark'} width={20} height={20} sx={{transform: "translateY(5px)"}} />
            Quiz
        </Typography>
        <Box onClick={handleOpenDetails} sx={{ cursor: 'pointer', alignItems:"center", textAlign:"center" }}>

          {attachments.length > 0 && (
            <Box
              sx={{
                pt: '60%',
                mb: 1,
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                  }),
                ...(completed && {
                  opacity: 0.48,
                }),
              }}
            >
              <Image src={attachments[0].url?.preview} sx={{ position: 'absolute', top: 0, width: 1, height: 1 }} />
            </Box>
          )}

          <Typography

            variant="h5"
            sx={{
              px: 1,
              mb: 1,
              transition: (theme) =>
                theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shortest,
                }),
              ...(completed && { opacity: 0.48 }),
            }}
          >
            {translate(name)}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              p:1
            }}
          >
            {translate(description)}
          </Typography>
        </Box>

      
      </Paper>

      {(openDetails || isNew) && (
        <QuizModal
          {...other}
          card={card}
          isOpen={openDetails || isNew}
          onClose={handleCloseDetails}
          onDeleteTask={() => onDeleteTask(card._id)}
          onAttachAdd={onAttachAddCard}
          onChange={onChange}
        />
      )}
    </div>
  );
}
