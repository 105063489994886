import { paramCase } from 'change-case';
import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Typography, Button, Card, CardContent, DialogTitle, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// redux
import useSettings from '../../hooks/useSettings';

// routes
import useLocales from '../../hooks/useLocales';

import { SeoIllustration } from '../../assets';
import { DialogAnimate } from '../animate';

import axios from '../../utils/axios';

import { PATH_DASHBOARD } from '../../routes/paths';
import { dispatch } from '../../redux/store';
import { addBoard } from '../../redux/slices/kanban';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function BoardAddDialog({onClose, isOpen}) {
  const { translate } = useLocales();

  const [closed, setClosed] = useState(false)
  const [newBoard, setNewBoard] = useState(isOpen)
  const [textValue, setTextValue] = useState("")
  const navigate = useNavigate();



  const addBoardToDb = async () => {
    // 
    const response = await dispatch(addBoard({title: textValue}))
    console.log(response)
    // const response = await axios.post("/modules/kanban/board", {value: textValue})
    navigate(PATH_DASHBOARD.boards.view(response.id));
    onClose()
  };

  return (<>
      <TextField value={textValue} onChange={(e)=>setTextValue(e.target.value)} label={translate('widgets.addboardtitle')} />
      <Button onClick={addBoardToDb} variant="contained" to="#" component={RouterLink} style={{ marginTop: '20px' }}>
        {translate('widgets.addbtn')}
      </Button>
    </>
  );
}
