import jwtDecode from 'jwt-decode';
//
import axios from './axios';
import socket from './socket';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const getDecoded = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // ----------------------------------------------------------------------

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded;
};

const handleTokenExpired = (exp) => {
   /* let expiredTimer;

   window.clearTimeout(expiredTimer);
   const currentTime = Date.now();
   const timeLeft = exp * 1000 - currentTime;

   expiredTimer = window.setTimeout(() => {
//     // You can do what ever you want here, like show a notification

}, timeLeft); */
};

// ----------------------------------------------------------------------

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.authorization = accessToken;

    const { exp } = jwtDecode(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.authorization;
  }
};

export { isValidToken, setSession, getDecoded };
