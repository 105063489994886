import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
// config
import { defaultSettings } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  addToSearch: ()=>{},
  setCurrentApp: ()=>{},
  getSearchResults: ()=>{}
};

const SearchContext = createContext(initialState);

// ----------------------------------------------------------------------

SearchProvider.propTypes = {
  children: PropTypes.node,
};

function SearchProvider({ children }) {
    
  const [searchResults, setSearchResults] = useState([])
  const add = (child) => {
      console.log("Done")
      setSearchResults([...searchResults, child])
  };
  const setCurrentApp = (child) => {
      console.log("Done")
      console.log(child)
  };

  const refreshSearchResults = ()=>{
    setSearchResults([])
  }

  const getSearchResults = () => {
      return searchResults
  };
  return (
    <SearchContext.Provider
      value={{
        add,
        getSearchResults
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext, SearchProvider };
