import {useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Typography, Paper, Grid } from '@mui/material';
import useLocales from '../../../hooks/useLocales';


import { PATH_DASHBOARD, } from '../../../routes/paths';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

PageCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export default function PageCard({ active, onChange, card, sx, ...other }) {

  const navigate = useNavigate();
  const { translate } = useLocales();

  const openWindow = () => {
    navigate(PATH_DASHBOARD.pages.view(card._id || card.boardId));
  };

  return !card?._id?<></>  :(
    <>
      <Paper
        onClick={openWindow}
        component="span"
        sx={{
          width: 1,
          cursor: "pointer",
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            position: 'absolute',
            backgroundSize: 'cover !important',
          },

          ...sx,

          '&:hover': {
            bgcolor: !active ? 'grey.5008' : '',
          },

          borderRadius: 0,
          my: 0,
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            gridAutoFlow: 'column',
            justifyContent: 'space-between',
            px: 2,
            py: 0.25
          }}
          style={{textDecoration: 'none' }}
        >
          <Iconify icon={'fluent:board-28-regular'} sx={{m:"auto"}} width={18} height={18} />
          <Typography
            variant="body1"
            sx={{
              ...(active
                ? { pr: 0.5, color: "primary.main" }
                : {}),
            }}
          >{card.pageId?.title || translate(card.title || 'apps.startboard')}
            {active && <Iconify sx={{transform: "translateY(3px)"}} icon="eva:arrow-ios-back-fill" height={16} width={16} />}
          </Typography>
        </Grid>
      </Paper>

    </>
  );
}
