import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
// components

import { dispatch } from '../../../redux/store';
import { addAttachment } from '../../../redux/slices/kanban';


import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import CardToolbar from './CardToolbar';
import CardAssignee from './CardAssignee/CardAssignee';
import QuestionList from './quiz/QuestionList';
import { IconButtonAnimate } from '../../animate';
//


// import KanbanTaskCommentList from './KanbanTaskCommentList';
import KanbanTaskAttachments from '../../../sections/@dashboard/kanban/KanbanTaskAttachments';
// import KanbanTaskCommentInput from './KanbanTaskCommentInput';
import { useDatePicker, DisplayTime } from '../../../sections/@dashboard/kanban/KanbanTaskAdd';
import axios from '../../../utils/axios';



// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'high'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

TaskModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function TaskModal({ board, card, isOpen, onClose, onDeleteTask, onAttachAdd, ...other }) {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();
  const fileInputRef = useRef(null);
  const titleInput = useRef(null);
  const [prioritize, setPrioritize] = useState(card.prioritize || 'low');
  const [description, setDescription] = useState(card.description || '');
  const [name, setName] = useState(card.name || '');
  const [opened, setOpened] = useState(false)
  const [completed, setCompleted] = useState(card.completed || false)
  const { due, comments } = card;

  const [isChange, setIsChange] = useState(null);


  const setChange =()=>{
    setIsChange(true)
  }

  const {
    startTime,
    endTime,
  } = useDatePicker({
    date: due,
  });

  useEffect(()=>{
    setOpened(isOpen)
  }, [isOpen])

  const onAttachChanged = async (files) => {
    const form = new FormData();
    files.map((file) => form.append(`file`, file));
    const response = await axios.post('/attachment', form);
    dispatch(addAttachment({board, attachments: response.data?.map((item) =>item._id || ''), card: card._id}))

  };

  const deleteAttach = async (list, c) => {
    console.log("Attachment deleted")
    const response = await axios.post('/modules/kanban/card/attachment/delete', { cardId: card._id, file: c });
  };

  const onCloseHandler = () => {
    setOpened(false)
    onClose({ completed, isChange, name, description, prioritize, due: [startTime, endTime] });
  };

  useEffect(()=>{
    if (opened) {
      if (titleInput.current) {
        titleInput.current.focus();
      }
    }
  }, [opened])

  const updateTaskComplete = (value)=>{
    console.log(value)
    setCompleted(value)
    setIsChange(true)
  }

  return (
    <>
      <Drawer open={opened} onClose={onCloseHandler} anchor="left" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
      
        <CardToolbar {...other} card={card} onClose={onClose} taskComplete={updateTaskComplete} />
        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
            <CardAssignee {...other} card={card} />
            
            <OutlinedInput
              fullWidth
              ref={titleInput}
              multiline
              size="small"
              placeholder={translate("dragndrop.quiz.title")}
              value={name}
              autoFocus
              onChange={(e) => {
                setName(e.target.value);
                setChange()
              }}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
            />

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>{translate("dragndrop.quiz.description")}</LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                rows={3}
                size="small"
                placeholder={translate("dragndrop.description")}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setChange()
                }}
                sx={{ typography: 'body2' }}
              />
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>{translate("dragndrop.quiz.preview")}</LabelStyle>
              <Stack direction="row" flexWrap="wrap">
                <KanbanTaskAttachments
                  attachments={card.attachments}
                  onAttachChanged={onAttachChanged}
                  deleteAttach={deleteAttach}
                />
              </Stack>
            </Stack>
            <Typography variant="subtitle2">
              {translate("dragndrop.quiz.questions")}
              
            </Typography>

            <QuestionList card={card} board={board} />
          </Stack>
          
          {/* comments.length > 0 && <KanbanTaskCommentList comments={comments} /> */}
        </Scrollbar>

        <Divider />

        {/* <KanbanTaskCommentInput /> */}
      </Drawer>
    </>
  );
}
