import { flexbox } from '@mui/system';
import { useState, useRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// @mui
// components
import DragListItem from './DragListItem';

const DragList = function DragList(props) {
  return props.children.map((item, index) => (
      {item}
  ));
};

export default function DragListColumn(props) {
  
  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const [state, setState] = useState({ items: props.children });
  const onDragEndHandler = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const items = reorder(state.items, result.source.index, result.destination.index);
    const itemsprops = reorder(props.items, result.source.index, result.destination.index);
    setState({ items });
    if (props.onDragEnd){
      props.onDragEnd(itemsprops)
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEndHandler}>
      <Droppable droppableId={props.id} direction={props.direction || "vertical"}>
        {(provided) => (
           <div ref={provided.innerRef} {...provided.droppableProps}>
            
            {props.children}
            {provided.placeholder}
           </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
