import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Stack, Grid, Box, Switch, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';
import { RHFSwitch, RHFEditor, FormProvider, RHFTextField, RHFUploadSingleFile } from '../../../components/hook-form';
// utils
import cssStyles from '../../../utils/cssStyles';

import useLocales from '../../../hooks/useLocales';
import eventBus from "../../../hooks/eventBus";
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import SearchResults from '../../../components/search/SearchResults';


// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',

  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar({sx}) {
  const [isOpen, setOpen] = useState(false);
  const [searchGlobal, setSearchGlobal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { translate } = useLocales();
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    eventBus.dispatch("searchChange", {data: searchValue});
    console.log(searchValue)
  }, [searchValue])



  return (
    <>

    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen} sx={sx}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}

        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Grid>
              <Stack
                direction="column"
                sx={{
                  gridAutoFlow: 'column',

                  m: 2
                }}
              >
                <Input
                  autoFocus
                  fullWidth
                  value={searchValue}
                  onChange={(event, newValue) => {setSearchValue(event.target.value)}}
                  disableUnderline
                  placeholder={`${translate('general.search')}...`}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                />

                {/* <Button variant="outlined" color="inherit" sx={{maxWidth:150, align: "right"}} onClick={handleClose}>
                  {translate('general.search')}
                </Button> */}
              </Stack>
            </Grid>
            
            <SearchResults search={searchValue} />
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
    </>
  );
}
