import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
// @mui
import { Box, Stack, OutlinedInput, MenuItem, IconButton, Grid, Typography, Avatar, Tooltip } from '@mui/material';
// components
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

import { useDispatch } from '../../../redux/store';

import { addCard, renameColumn } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import MenuPopover from '../../MenuPopover';
import ColumnModal from './ColumnModal';
import CardModal from './CardModal';
import { IconButtonAnimate } from '../../animate';

// ----------------------------------------------------------------------

const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

AddCardButton.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default function AddCardButton({ column, size, clicked, board, page, children, sx, ...other }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [newCardOpen, setNewCardOpen] = useState(null);
  
  const handleNewCard = () => {
    setOpen(null);
    if (clicked) clicked();
    setNewCardOpen(true);
  };


  const onSelectNewCard = async (data) => {
    console.log(column, data)
    if (!column || !column._id) {
      return false;
    }
    console.log(data)
    const newCard = await dispatch(
      addCard({
        name: '',
        destination: { board, page, column: column._id, index: 0 },
        cardType: data.custom?"custom": (data?.id || 'text'),
        component: data?._id
      })
    );
  };

  const handleCloseDetails = () => {
    setNewCardOpen(false);
  };

  const handleFocus = (event) => event.target.select();
  return (
    <>
      {children ? (
        <Box sx={sx} onClick={handleNewCard}>{children}</Box>
      ) : (
        <Tooltip title={translate('modules.boards.newcard')}>
          <IconButtonAnimate onClick={handleNewCard}>
            <Iconify icon={'mdi:card-plus'} sx={{ width: size || 15, height: size || 15 }} />
          </IconButtonAnimate>
        </Tooltip>
      )}
      {newCardOpen && (
        <CardModal
          onSelect={onSelectNewCard}
          isOpen={newCardOpen}
          onClose={handleCloseDetails}
          onDeleteTask={() => console.log('Ok')}
          onAttachAdd={() => console.log('Ok')}
          page={page}
          board={board}
        />
      )}
    </>
  );
}
