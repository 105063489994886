import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useRef, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  Grid,
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useToggle from '../../../hooks/useToggle';
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import Editor from '../../editor';

// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'hight'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

CardNotifications.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function CardNotifications({ children, card, fakeOpen, isOpen, onClose, position, onSelect, ...other }) {
  const { translate } = useLocales();

  const [open, setOpen] = useState(isOpen)
  useEffect(()=>{
    setOpen(isOpen)
  }, [isOpen])

  const onCloseHandler = () => {
    onClose();
  };
  return (
    <>
      <Drawer open={open} sx={{visibility: fakeOpen?"hidden":"visible" }} onClose={onCloseHandler} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 }} }}>
        <Divider />

        <Scrollbar sx={{ p: 2 }}>
            {children}
        </Scrollbar>
        
        <Divider />
      </Drawer>
    </>
  );
}
