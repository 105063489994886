import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
// @mui
import { Box, Stack, OutlinedInput, MenuItem, IconButton, Grid, Typography, Avatar, Tooltip } from '@mui/material';
// components
import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';

import { useDispatch } from '../../../redux/store';

import { addCard, renameColumn } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import MenuPopover from '../../MenuPopover';
import ColumnModal from './ColumnModal';
import CardModal from './CardModal';
import AddCardButton from './AddCardButton';
import { IconButtonAnimate } from '../../animate';

// ----------------------------------------------------------------------

const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join('');
  if (typeof node === 'object' && node) return getNodeText(node.props.children);
};

ColumnSettings.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default function ColumnSettings({
  column,
  columnUpdateEvent,
  header,
  columnName,
  board,
  page,
  settingsEnabled = true,
  onDelete,
  onUpdate,
  columnUpdate,
  collapsable,
  onCollapse,
  onOpen,
  ...other
}) {
  const renameRef = useRef(null);

  const { translate } = useLocales();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [value, setValue] = useState(getNodeText(header));
  const [newCardOpen, setNewCardOpen] = useState(null);

  const [open, setOpen] = useState(null);
  const [renameOpen, setRenameOpen] = useState(null);

  const [modalOpen, setModalOpen] = useState(null);

  const openWindow = () => {
    setOpen(false);
    setModalOpen(true);
  };

  useEffect(() => {
    if (renameOpen) {
      if (renameRef.current) {
        renameRef.current.focus();
      }
    }
  }, [renameOpen]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClickRename = () => {
    setRenameOpen(true);
    handleClose();
  };

  const handleChangeColumnName = (event) => {
    setValue(event.target.value);
  };

  const onModalClose = async (data) => {
    setModalOpen(false);
  };

  const handleNewCard = () => {
    setOpen(null);
    setNewCardOpen(true);
  };

  const onSelectNewCard = async (data) => {
    if (!column || !column._id) {
      return false;
    }
    const newCard = await dispatch(
      addCard({
        name: '',
        destination: { board, column: column._id, index: 0 },
        cardType: data?.id || 'text',
      })
    );
  };

  const handleUpdateColumn = (event) => {
    if (event.key === 'Enter' && renameRef.current) {
      renameRef.current.blur();
      setRenameOpen(false);
      dispatch(renameColumn(column._id, value));
    }
  };

  const handleCloseDetails = () => {
    setNewCardOpen(false);
  };

  const handleFocus = (event) => event.target.select();
  return (
    <Grid
      sx={{
        
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
      }}
      {...other}
    >
      
      <Box sx={{cursor: "pointer"}} onClick={()=>{
        if (collapsable){onCollapse()}}}>
        {/* <>
          <Box sx={{ display: 'flex', marginLeft: '8px' }}>
            {column &&
              column.owners?.map(
                (assignee, index) =>
                  assignee &&
                  user.userId !== assignee._id && (
                    <Tooltip key={`${assignee.name}-${index}`} title={`${assignee.firstName} ${assignee.lastName}`}>
                      <Avatar
                        alt={`${assignee.firstName} ${assignee.lastName}`}
                        key={`${assignee.name}-${index}`}
                        src={assignee.avatar?.url?.thumbnail}
                        sx={{ width: 16, zIndex: '10', height: 16, mb: '-16px', ml: 1 }}
                      />
                    </Tooltip>
                  )
              )}
          </Box>
                  </> */}
        {header && !renameOpen && <>{header}</>}
      </Box>
      {settingsEnabled && (
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{position:"relative", height: 1}} >
            {renameOpen && (
              <OutlinedInput
                size="small"
                placeholder="Section name"
                value={value}
                onChange={handleChangeColumnName}
                onKeyUp={handleUpdateColumn}
                inputRef={renameRef}
                onFocus={handleFocus}
                sx={{
                  typography: 'h6',
                  minHeight: 40,
                  fontWeight: 'fontWeightBold',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            )}
            <Stack className="column-settings" direction="row" sx={{height: 1, bgcolor: "grey.900",width:70, position:"absolute", top:0, right:0, display: "none"}}>
              <AddCardButton sx={{margin:"auto", top:"50%"}} column={column} board={board} page={page} />
              <IconButton sx={{margin:"auto"}} size="small" onClick={handleOpen} color={open ? 'inherit' : 'default'}>
                <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
              </IconButton>
            </Stack>
          </Stack>

          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              width: 'auto',
              '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
            }}
          >
            {' '}
            <AddCardButton clicked={() => setOpen(false)} column={column} board={board} page={page}>
              <MenuItem>
                <Iconify icon={'mdi:card-plus'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
                {translate('modules.boards.newcard')}
              </MenuItem>
            </AddCardButton>
            <MenuItem onClick={handleClickRename}>
              <Iconify icon={'eva:edit-fill'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
              {translate('dragndrop.columnrename')}
            </MenuItem>
            <MenuItem onClick={openWindow}>
              <Iconify icon={'akar-icons:settings-horizontal'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
              {translate('dragndrop.whatcanbein')}
            </MenuItem>
            <MenuItem onClick={() => onDelete(board, column._id)} sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
              {translate('dragndrop.columndelete')}
            </MenuItem>
          </MenuPopover>
        </Box>
      )}
      {column && (
        <ColumnModal columnUpdateEvent={columnUpdateEvent} column={column} isOpen={modalOpen} onClose={onModalClose} />
      )}
    </Grid>
  );
}
