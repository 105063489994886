import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

import { kanbanBoard } from '../../_mock/kanban';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  websites: [],
  website: {},
  category: {},
  pages: [],
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.error = action.payload;
    },

    getCategoriesSuccess(state, action){
      const {categories} = action.payload
      state.categories = categories
      state.isLoading = false
    },

    getCategorySuccess(state, action){
      console.log(action.payload)
      state.category = action.payload.category
      state.isLoading = false
    }, 

    saveCategorySuccess(state, action){
      state.isLoading = false
    },

    /**
     * WEBSITES
     *  */ 
    getWebsitesSuccess(state, action){
      const {websites} = action.payload
      state.websites = websites
      state.isLoading = false
    },
    getWebsiteSuccess(state, action){
      const {website, pages} = action.payload
      state.website = website
      state.pages = pages || []
      state.isLoading = false
    }, 
    saveWebsiteSuccess(state, action){
      state.isLoading = false
    },
    checkWebsiteSuccess(state, action){
      state.isLoading = false
    },
    connectPageSuccess(state, action){
      state.isLoading = false
    },
  },
});


// Reducer
export default slice.reducer;

export const {
  setLoading
} = slice.actions;


// ----------------------------------------------------------------------

export function getCategories(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/categories', {
        params: { id },
      });
      await dispatch(slice.actions.getCategoriesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getWebsites(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/websites', {
        params: { id },
      });
      await dispatch(slice.actions.getWebsitesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getCategory(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/category', {
        params: { id },
      });
      await dispatch(slice.actions.getCategorySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getWebsite(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/website', {
        params: { id },
      });
      console.log("Ok..")
      await dispatch(slice.actions.getWebsiteSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function connectPage({ page, category }) {
  return async () => {
    try {
      const response = await axios.post('/api/category/connect', { page, category });
      await dispatch(slice.actions.connectPageSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function saveCategory({ category, ...data }) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      
      const response = await axios.put('/api/category', {  category, ...data });
      await dispatch(slice.actions.saveCategorySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function saveWebsite({ website, ...data }) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      
      const response = await axios.put('/api/category', {  website, ...data });
      await dispatch(slice.actions.saveWebsiteSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function checkWebsite({...data }) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      
      const response = await axios.post('/api/website/check', { ...data });
      await dispatch(slice.actions.checkWebsiteSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function updatePageLayout({ category, ...data }) {
  dispatch(slice.actions.startLoading());
  return async () => {
    try {
      
      const response = await axios.put('/api/website/content', {  category, ...data });
      await dispatch(slice.actions.saveCategorySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function getWebsitePages(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/website/pages', {
        params: { site: id },
      });
      dispatch(slice.actions.getBoardSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
