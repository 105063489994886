import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  configuration: [],
  permission: []
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload)
      state.error = action.payload;
    },

    isConfiguration(state, action){
      console.log(action.payload, state[action.payload].current)
      return state[action.payload].current
    },

    // GET PRODUCTS
    getConfigurationSuccess(state, action) {
      state = {...state, ...action.payload}
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setStandardPermissions,
} = slice.actions;

// ----------------------------------------------------------------------

export function getMainConfiguration() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/configuration');
      dispatch(slice.actions.getConfigurationSuccess({config: response}));
      localStorage.setItem("configuration", JSON.stringify(response.data))
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

