import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, ListSubheader, Grid, Typography } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import ColumnSettings from './components/ColumnSettings';

import useDrag from '../../hooks/useDrag';

// import ServiceCommandUnit from './ServiceCommandUnit';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  visibility: isDragging ? 'visible' : 'unset',
  ...draggableStyle,
});

// ----------------------------------------------------------------------

DroplistVertical.propTypes = {
  isCollapse: PropTypes.bool,
  items: PropTypes.object,
  contactDrop: PropTypes.func,
};

export default function DroplistVertical({
  addingDisabled,
  title,
  onDragEnd,
  id,
  children,
  type = 'card',
  contactDrop,
  isDropDisabled,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
  });
  const [state, setState] = useState(null);

  const onDrop = (e, item, type) => {
    if (e.dataTransfer.files.length < 0){
      contactDrop(JSON.parse(e.dataTransfer.getData('dropid')), item, type);
    }
    setState(false);
  };

  const onDragEnter = () => {
    setState(true);
  };
  const onDragLeave = () => {
    setState(false);
  };

  return (
    <Box
      {...other}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        transform: state ? 'scale(0.95)' : 'unset',
        opacity: state ? '0.7' : '1',
        transition: '0.1s',
      }}
      {...getInputProps()}
      {...getRootProps()}
      onClick={() => {}}
    >
      {/* <ColumnSettings
        {...other}
        onDragOver={onDragEnter}
        onDrop={(e) => {
          onDrop(e, id, 'column');
        }}
        column={column}
        onDragLeave={onDragLeave}
        onUpdate={onUpdate}
        header={header}
        settingsEnabled={settingsEnabled}
        onDelete={onColumnDelete}
      /> */}

      <Droppable
        isDropDisabled={isDropDisabled}
        droppableId={`drop-${title}-${id}`}
        direction="vertical"
        type={type}
      >
        {(provided) => (
          <div style={{ width: '100%' }}>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ maxHeight: '100%', minHeight: '150px', width: '100%', position: 'relative' }}
            >
              {(Array.isArray(children)?children:[]).map((item, index) => (
                <>
                  {item}
                </>
              ))}

            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
}
