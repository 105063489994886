import { useEffect, useState } from 'react';

import { Grid, Button, Box, Typography, Card } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import { dispatch, useSelector } from '../../../redux/store';
import { getPage, createWebColumn, getStyle } from '../../../redux/slices/kanban';
import useSettings from '../../../hooks/useSettings';

import WebEditorRowSettings from './WebEditorRowSettings';
import Iconify from '../../Iconify';
import AddCardButton from '../components/AddCardButton';
import Scrollbar from '../../Scrollbar';

import { CardItem, DropListItem, ColumnList, Column, ColumnHeader } from '..';
import WidthManager from './WidthManager';

export default function WebEditor({ id, style, dropDisabled }) {
  const { translate } = useLocales();
  const { cards, columns } = useSelector((state) => state.kanban);
  const [loaded, setLoaded] = useState(null);

  const { defaultValues, setDefaultValues } = useSettings();

  const { sx, columnOrder, gridSize } = useSelector((state) => state.kanban[loaded || 'default']);
  const { curSize, userSizes } = useSelector((state) => state.kanban);

  useEffect(async () => {
    const data = await dispatch(getPage(id));
    if (data) {
      setLoaded(id);
    }
  }, [id]);
  
  const onDragEnd = () => {};
  const renderContent = ({ row, rowIndex, cindex, column, gridSize_ }) => (
    <Grid item sx={{ position: 'relative' }}>

      <WidthManager row={rowIndex} columnOrder={columnOrder} initialSize={gridSize_} rowObject={row} page={loaded} index={cindex}>
        
        <Column
          page={loaded}
          index={cindex}
          key={column._id}
          width={1}
          dropDisabled={dropDisabled}
          column={columns[column._id]}
          sx={{ mt: 0, mb: 0, zIndex: 1 }}
          header={<ColumnHeader>{translate(columns[column._id]?.title)}</ColumnHeader>}
        >
          {columns[column._id]?.cards?.map((card, cardIndex) => (
            <DropListItem index={cardIndex} key={card._id} id={`${column._id}-${card._id}`}>

              <CardItem
                disableTitle
                paperStyle={{ bgcolor: 'unset', p: 0 }}
                column={{ _id: column._id, cindex, column: columns[column._id] }}
                cardIndex={cardIndex}
                sx={{
                  ...{ ...sx, mt: 0, mb: 0 },
                  pb: 0,
                  pt: 0,
                  zIndex: 20,
                  ...columns[column._id].sx,
                  mt: 0,
                  mb: 0,
                  ...(defaultValues?.card || {}),
                  ...cards[card._id]?.sx,
                }}
                item={cards[card._id]}
              />
            </DropListItem>
              ))}

            {columns[column._id]?.cards?.length === 0 && (
            <>
              <Box sx={{ display: 'flex', minHeight: '100px', justifyContent: 'center', p: 3 }}>
                <Typography variant="subtitle1" sx={{ textAlign: 'center', maxWidth: 200 }}>
                  {translate('dragndrop.page.emptyrow')}
                  <br />
                  {translate('dragndrop.page.clicktoadd')}
                  <AddCardButton column={columns[column._id]} page={loaded} size={30} />
                </Typography>
              </Box>
            </>
          )}
          </Column> 
          {/* </Grid> */} 
      </WidthManager>
    </Grid>
  );
  return (
    <div className={`webeditor p-${loaded}`} >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} fullWidth>
        <Box
          sx={{
            ...sx,
            maxWidth: userSizes[curSize].size,
            width: '100%',
          }}
        >
          <Box id={`b-${loaded}-page`} alignItems="center" sx={{ ...style }}>
            <Grid container sx={{ position: 'relative' }}>
              {columnOrder?.map((column, cindex) => (
                <div key={cindex}>
                  {renderContent({
                    cindex,
                    column,
                    gridSize_: gridSize?.[cindex],
                  })}
                </div>
              ))}
            </Grid>

            <Button fullWidth onClick={() => dispatch(createWebColumn({ page:loaded }))}>
                Sektion hinzufügen
            </Button>           
          </Box>
        </Box>
      </Box>
    </div>
  );
}
