import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import SearchBar from '../dashboard/header/Searchbar';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  return (
    <Stack sx={{ minHeight: 1 }}>
      <div>
        <SearchBar />
      </div>
      <Outlet />
    </Stack>
  );
}
