import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  Grid
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useToggle from '../../../hooks/useToggle';
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import CardIcon from './CardIcon';
import axios from '../../../utils/axios';

import { useDispatch, useSelector } from '../../../redux/store';
import { getWebsite, saveWebsite, updatePageLayout } from '../../../redux/slices/categories';


const CardList = [
  {id: "text", name: "dragndrop.cards.text", size: 4, icon: "bx:text"},
  {id: "image", name: "dragndrop.cards.image", size: 4, icon: "bx:image-add"},
  {id: "video", name: "dragndrop.cards.video", size: 4, icon: "bx:video"},

  {id: "code", name: "dragndrop.cards.code", size: 4, icon: "bi:code-slash"},
  /*
    {id: "video", name: "dragndrop.cards.video", size: 4, icon: "bx:video"},
    {id: "code", name: "dragndrop.cards.code", size: 4, icon: "bx:code-alt"},
    {id: "html", name: "dragndrop.cards.html", size: 4, icon: "ic:baseline-html"},
  */
]
//


// ----------------------------------------------------------------------

CardModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function CardModal({ page, column, isOpen, onClose, position, onSelect}) {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();

  const { isLoading, website } = useSelector((state) => state.categories);

  const onCloseHandler = () => {
    onClose({});
  };

  const getPageComponents = ()=>{
    if (page){
      console.log(page)
    }
    
  }

  const onSelect_ = (item)=>{
    onCloseHandler()
    console.log(item)
    onSelect(item)
  }


  console.log(page)

  return (
    <>
      <Drawer open={isOpen} onClose={onCloseHandler} anchor="left" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Scrollbar sx={{p:2}}>
          <Stack spacing={3} align="center" sx={{ px: 2.5, py: 3, mb: 3 }}>
            <Typography variant="h4">
                {translate("dragndrop.createwhat")}
            </Typography>
          </Stack>
          <Grid container spacing={3}>
            {CardList.map((item, index)=>
              <Grid key={item.name} item xs={6} sm={item.size*6} md={item.size}>
                <CardIcon item={item} onSelect={onSelect_} />
              </Grid>
            )}
          </Grid>
          <Typography variant="h4" sx={{textAlign:"center"}}>
              {translate("dragndrop.page.owncomponents")}
          </Typography>
          <Grid container spacing={3}>
            {website?.components?.map((item, index)=>
              <Grid key={item.name} item xs={6} sm={4} md={4}>
                <CardIcon item={item} onSelect={onSelect_} custom/>
              </Grid>
            )}
          </Grid>

        </Scrollbar>

        <Divider />
      </Drawer>
    </>
  );
}
