import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Grid,
  Button
} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';

import { dispatch } from '../../redux/store';
import { getImage, saveImage } from '../../redux/slices/recipes';
import useLocales from '../../hooks/useLocales';
// components

import Scrollbar from '../Scrollbar';




// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'hight'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ImageEdit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function ImageEdit({_id, file, isOpen, onClose, position, onSelect}) {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();

  const [name, setName] = useState("")
  const [alt, setAlt] = useState("")

  useEffect(async ()=>{
    if (!isOpen) return;
    const item = await dispatch(getImage(file.id));
    setName(item.title.rendered || "")
    setAlt(item.alt_text || "")
  }, [isOpen])

  const onCloseHandler = () => {
    onClose({});
  };

  const onSelect_ = (item)=>{
    onCloseHandler()
    onSelect(item)
  }

  const saveImageData = async ()=>{
    console.log(_id)
    const item = await dispatch(saveImage({id: file.id, title: name, alt, wpid: _id}));
  }


  return (
    <>
      <Drawer open={isOpen} onClose={onCloseHandler} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Divider />

        <Scrollbar sx={{p:2}}>
          <Stack spacing={3} align="center" sx={{ px: 2.5, py: 3, mb: 3 }}>
            <Typography variant="h4">
              Bilddaten bearbeiten
            </Typography>
            <TextField id="outlined-basic" value={name} onChange={(e,n)=>setName(e.target.value)} label="Name" variant="outlined" />
            <TextField id="outlined-basic" value={alt} onChange={(e,n)=>setAlt(e.target.value)} label="Alt-Text" variant="outlined" />
            <Button onClick={saveImageData}>Speichern</Button>
          </Stack>
        </Scrollbar>

        <Divider />
      </Drawer>
    </>
  );
}
