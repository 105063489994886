import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// @mui
import { Tooltip, Stack, Button, Divider} from '@mui/material';
// components
import useResponsive from '../../../hooks/useResponsive';

import Iconify from '../../Iconify';
import { IconButtonAnimate } from '../../animate';
import useLocales from '../../../hooks/useLocales';

import { dispatch } from '../../../redux/store';
import { deleteCard } from '../../../redux/slices/kanban';
import CardStyleManager from './CardStyleManager';

// ----------------------------------------------------------------------

CardToolbar.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default function CardToolbar({ onCodeOpen, taskComplete, onDelete, column, card, onClose, ...other}) {
  const { translate } = useLocales();
  
  const isDesktop = useResponsive('up', 'sm');
  const fileInputRef = useRef(null);
  const [taskCompleted, setTaskCompleted] = useState(card.completed);

  const handleAttach = () => {};
  const onDeleteTask = () => {
    dispatch(deleteCard({card: card._id, column: column._id}))
    onClose()
  };

  const handleToggleCompleted = () => {
    taskComplete(!taskCompleted)
    setTaskCompleted(!taskCompleted)
  };

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">

          <>
            <Tooltip title="Back">
              <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          </>


        {card.cardType === "task" && <Button
          size="small"
          variant="outlined"
          color={taskCompleted ? 'primary' : 'inherit'}
          startIcon={!taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />}
          onClick={handleToggleCompleted}
        >
          {taskCompleted ? translate("modules.boards.complete") : translate("modules.boards.markcomplete")}
        </Button>}

        <Stack direction="row" spacing={2} justifyContent="flex-end" flexGrow={1}>
          {/* <Tooltip title={translate('menu.like')}>
            <IconButtonAnimate size="small">
              <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
            </IconButtonAnimate>
           </Tooltip> */}

          <>
            <Tooltip title={translate('menu.showcode')}>
              <IconButtonAnimate size="small" onClick={onCodeOpen}>
                <Iconify icon={'eva:code-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
          </>

          <Tooltip title={translate('menu.delete')}>
            <IconButtonAnimate onClick={onDeleteTask} size="small">
              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>

          <Tooltip title={translate('menu.save')}>
            <IconButtonAnimate onClick={onClose} size="small">
              <Iconify icon={'fluent:save-28-regular'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider />
    </>
  );
}
