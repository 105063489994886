import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useState, useRef, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Drawer,
  Divider,
  TextField,
  Typography,
  Stack
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
import CardToolbar from './CardToolbar';
import {CardAssignee} from './CardAssignee';
// components

import Scrollbar from '../../Scrollbar';

// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'hight'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

LinkModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function LinkModal({ card, isOpen, onClose, position, onSelect, ...other }) {
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();

  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const [isChange, setIsChange] = useState(false)

  const [open, setOpen] = useState(null)
  useEffect(()=>{
    setOpen(isOpen)
  }, [isOpen])

  useEffect(()=>{
    setName(translate(card.name) || "")
    setLink(card.path || "")
  }, [])

  const onCloseHandler = () => {
    setOpen(false)
    onClose({path: link, name, isChange});
  };

  const defaultValues = {
    content: '',
  };

  return (
    <>
      <Drawer open={open} onClose={onCloseHandler} anchor="left" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <CardToolbar {...other} card={card} onClose={onClose} />
        <Divider />
        

        <Scrollbar>
        <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
        <CardAssignee {...other} card={card} />
        <TextField sx={{mb:2}} value={name} onChange={(e)=>{setName(e.target.value);setIsChange(true)}} label={translate("dragndrop.title")} variant="standard" fullWidth  {...other} />
        <TextField sx={{mb:2}} value={link} onChange={(e)=>{setLink(e.target.value);setIsChange(true)}} label={translate("dragndrop.linkurl")} variant="standard" fullWidth  {...other} />
        {/* <TextField sx={{mb:2}} value={link} onChange={(e)=>{setLink(e.target.value)}} label={translate("dragndrop.icon")} variant="standard" fullWidth  {...other} /> */}
            {/* <Editor
              simple
              id={"editor"}
              value={text}
              onChange={(e)=>setText(e)}
              sx={{mb:2}}
              {...other}
            /> */}
            
            <Typography variant="caption">
             Tipp: <span style={{opacity:"0.5"}}>{translate("dragndrop.tipps.savewindow")}</span>
          </Typography> 
          </Stack>
        </Scrollbar>
       
        <Divider />
      </Drawer>
      {/* 
        <Grid>
      <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
        {renderContent}
      </ListItemStyle>
      <NavItemSettings onEdit={onEdit} />
    </Grid>
      */}
    </>
  );
}
