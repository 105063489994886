import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
// @mui


import { Drawer, Divider, Stack, Box, TextField, Typography, Grid } from '@mui/material';
// hooks
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';

import useSettings from '../../../hooks/useSettings';

import useLocales from '../../../hooks/useLocales';

import { dispatch, useSelector } from '../../../redux/store';
import { updateCardCode, updateCard, updateCardLocal, setCardIsNew } from '../../../redux/slices/kanban';
import { updatePageLayout } from '../../../redux/slices/categories';

// components
import { CardAssignee } from './CardAssignee';
import CardToolbar from './CardToolbar';
import Scrollbar from '../../Scrollbar';

import Iconify from '../../Iconify';
import CardStyleManager from './CardStyleManager';

import { ImageModal, TextModal, LinkModal, PlaceholderModal, VideoModal, HTMLModal } from '../cards';

let spamTimer = false;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const langs = [languages.html, languages.css, languages.js];

const languageBoxStyle = (enabled) => ({
  bgcolor: enabled ? 'primary.main' : 'background.neutral',
  textAlign: 'center',
  cursor: 'pointer',
  px: 2,
  borderRadius: '4px',
});

CardModalGlobal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function CardModalGlobal({ card, column, type, children, open, onClose, behindStack, ...other }) {
  
  const { translate } = useLocales();
  const [isChange, setIsChange] = useState(null);
  const [data, setData] = useState({});
  const [fakeOpen, setFakeOpen] = useState(open);
  const [codeOpen, setCodeOpen] = useState(card.cardType==="code");
  const [enabled, setEnabled] = useState(0);

  const { website } = useSelector((state) => state.categories);

  const [code, setCode] = useState([{ value: '' }, { value: '' }, { value: '' }]);

  const saveAllData = async () => {
    /* await dispatch(updateCard({ card: card._id, code }));
    await dispatch(updatePageLayout({ link: '/template-parts/animatus-header', id: website.layout?.[0] }));
    await dispatch(updatePageLayout({ link: '/template-parts/animatus-footer', id: website.layout?.[1] })); */
  };


  if (card.isNew && open){
    dispatch(setCardIsNew({card: card._id, value: false}))
  }

  useEffect(() => {
    if (open) setFakeOpen(true);
  }, [open]);

  const onCloseHandler = async () => {
    if (data.changed) {
      dispatch(updateCard({ ...data, isNew: 0, code, card: card._id }));
    }
    setFakeOpen(false);
    setTimeout(() => {
      if (onClose) onClose();
    }, 200);
  };

  const updateLocal = (updated) => {
    console.log(updated)
    dispatch(updateCardLocal({ ...data, ...updated, card: card._id }));
    setData({ changed: true, ...data, ...updated, card: card._id });
  };

  const updateData = (value) => {
    const copy = [...code];
    copy[enabled] = { ...copy[enabled], value };
    setCode(copy);

    setIsChange(true);
    if (enabled === 1) {
      if (!spamTimer) {
        spamTimer = true;
        setTimeout((spamTimer = false), 500);
      } else return;
      dispatch(updateCardCode({ card: card._id, code: value }));
    }
  };

  const onCodeChange = (value) => {
    const copy = [...code];
    copy[0] = { ...copy[0], value };
    setCode(copy);
    setIsChange(true);
  };

  const onCodeOpen = () => {
    setCodeOpen(!codeOpen);
  };

  useEffect(() => {
    setCode(card?.code?.length === 3 ? card.code : [{ value: '' }, { value: '' }, { value: '' }]);
  }, []);


  if (card._id) {
    return (
      <Drawer
        open={fakeOpen}
        BackdropProps={{ invisible: true }}
        onClose={onCloseHandler}
        anchor="left"

        PaperProps={{ sx: { zIndex: 300, width: { xs: 1, sm: codeOpen ? '100%' : 480 } } }}
      >
        
        <Grid container>
          
          <Grid item  xs={12} md={codeOpen ? 6 : 12} lg={codeOpen ? 4 : 12} sx={{maxWidth: 480}}>
          <CardToolbar {...other} onCodeOpen={onCodeOpen} column={column} onClose={onCloseHandler} card={card} />
            <Scrollbar>
              <CardStyleManager type="card" {...other} onChange={updateLocal} column={column} card={card} />
              <Divider />
              <CardAssignee {...other} card={card} />
              <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
                {children}
                {card.cardType === 'text' && <TextModal card={card} onUpdate={updateLocal} {...other} />}
                {card.cardType === 'image' && <ImageModal card={card} onUpdate={updateLocal} {...other} />}
                {card.cardType === 'link' && <LinkModal card={card} onUpdate={updateLocal} {...other} />}
                {card.cardType === 'placeholder' && <PlaceholderModal onUpdate={updateLocal} card={card} {...other} />}
                {card.cardType === 'video' && <VideoModal card={card} onUpdate={updateLocal} {...other} />}
                {card.cardType === 'code' && <HTMLModal card={card} onUpdate={updateLocal} {...other} />}
              </Stack>
            </Scrollbar>
          </Grid>
          {codeOpen && (
            <Grid item xs={12} md={6} lg={8}>
              <Grid container>
                <Grid item onClick={(i) => setEnabled(0)} sx={languageBoxStyle(enabled === 0)}>
                  HTML
                </Grid>
                <Grid item onClick={(i) => setEnabled(1)} sx={languageBoxStyle(enabled === 1)}>
                  CSS
                </Grid>
                <Grid item onClick={(i) => setEnabled(2)} sx={languageBoxStyle(enabled === 2)}>
                  JS
                </Grid>
                {enabled === 1 && (
                  <Grid item onClick={(i) => saveAllData()} sx={{ ...languageBoxStyle(), ml: 'auto' }}>
                    <Iconify sx={{ transform: 'translateY(2px)' }} icon="akar-icons:play" /> Ausführen
                  </Grid>
                )}
                {enabled === 2 && (
                  <Grid item onClick={(i) => saveAllData()} sx={{ ...languageBoxStyle(), ml: 'auto' }}>
                    <Iconify sx={{ transform: 'translateY(2px)' }} icon="akar-icons:play" /> Ausführen
                  </Grid>
                )}
                {enabled === 0 && (
                  <Grid item onClick={(i) => saveAllData()} sx={{ ...languageBoxStyle(), ml: 'auto' }}>
                    <Iconify sx={{ transform: 'translateY(2px)' }} icon="fluent:code-16-regular" /> Ordnung machen
                  </Grid>
                )}
              </Grid>
              <Box sx={{ position: 'relative', height: 1 }}>
                <Scrollbar sx={{ height: 'calc(100vh - 30px)' }}>
                  <Box
                    sx={{ width: 1, height: 1, minHeight: 'calc(100vh - 30px)', backgroundColor: 'background.default' }}
                  >
                    <Editor
                      value={code[enabled]?.value}
                      onValueChange={(value) => {
                        updateData(value);
                      }}
                      onBlur={()=>setData({ changed: true, ...data, code, card: card._id })}
                      highlight={(code) => highlight(code, langs?.[enabled])}
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        border: 'unset',
                        height: '100%',
                        minHeight: 'calc(100vh - 30px)',
                        overflow: 'auto',
                      }}
                    />
                  </Box>
                </Scrollbar>
              </Box>
            </Grid>
          )}
        </Grid>
        <Divider />
      </Drawer>
    );
  }
  return <></>;
}
