import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Drawer, Divider, Button, Typography, OutlinedInput, Box, CardMedia, Tooltip } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
// components

import { dispatch } from '../../../redux/store';
import { addAttachment, uploadAttachment, deleteAttachment } from '../../../redux/slices/kanban';

import Iconify from '../../Iconify';
import Scrollbar from '../../Scrollbar';
import CardToolbar from '../components/CardToolbar';
import CardAssignee from '../components/CardAssignee/CardAssignee';
import { IconButtonAnimate } from '../../animate';

import KanbanTaskAttachments from '../../../sections/@dashboard/kanban/KanbanTaskAttachments';

import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

VideoCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  onFinish: PropTypes.func,
};

export default function GetThumbnail({ file, board, card }) {
  const [localFile, setFile] = useState(null);
  const videoInput = useRef();
  const videoElem = useRef();
  const [imgSrc, setImgSrc] = useState();
  const [curVideo, setCurVideo] = useState();

  useEffect(() => {
    setFile(file);
  }, [file]);

  const captureThumbnail = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoElem.current.videoWidth;
    canvas.height = videoElem.current.videoHeight;

    canvas
      .getContext('2d')
      .drawImage(videoElem.current, 0, 0, videoElem.current.videoWidth, videoElem.current.videoHeight);

    setImgSrc(canvas.toDataURL(), 'image.png');
    fetch(imgSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const NewFile = new File([blob], 'video_thumbnail', {
          type: 'image/png',
        });
      });
  };

  const generateVideo = async () => {
    const url = imgSrc;
    const response = await fetch(url);
    const blob = await response.blob();
    const thumbnail = new File([blob], 'thumbnail.png', { type: 'image/png' });
    const videoFile = [thumbnail, file];
    const filesNew = await dispatch(uploadAttachment({ type: 'video', files: videoFile }));
    const data = dispatch(
      addAttachment({ board, attachments: filesNew?.map((item) => item._id || ''), card: card._id, video: 1 })
    );
  };

  return (
    <>
      {localFile ? (
        <video // eslint-disable-line
          id="video" // eslint-disable-line
          kind="captions" // eslint-disable-line
          className="w-100" // eslint-disable-line
          ref={videoElem} // eslint-disable-line
          src={localFile.preview || localFile} // eslint-disable-line
          type="video/mp4" // eslint-disable-line
          controls // eslint-disable-line
        />
      ) : (
        ''
      )}
      <Button variant="outlined" onClick={captureThumbnail}>
        Thumbnail generieren
      </Button>
      {imgSrc ? (
        <>
          <img className="w-100" src={imgSrc} alt="" />
          <Button variant="outlined" onClick={generateVideo}>
            Video hochladen
          </Button>
        </>
      ) : (
        ''
      )}
    </>
  );
}

export function VideoCard({
  paperStyle = {},
  onOpen,
  preview,
  card,
  onChange,
  onDeleteTask,
  onFinish,
  index,
  ...other
}) {
  const { translate } = useLocales();
  const [openDetails, setOpenDetails] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const { name, attachments, completed, isNew } = card;

  const handleOpenDetails = () => {
    if (!preview) setOpenDetails(true);
  };

  return (
    <>
      <Box
        sx={{
          width: 1,
          position: 'relative',
          ...paperStyle,
          '&:hover .settings-btn': {
            display: "block"
          },
        }}
      >
        <Box sx={{ cursor: 'pointer' }}>
          {attachments.length > 0 ? (
            <Box
              sx={{
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {videoStarted && (
                <CardMedia
                  component="video"
                  sx={{
                    width: 1,
                  }}
                  autoPlay
                  controls
                  src={attachments[1]?.url?.preview}
                />
              )}
              {!videoStarted && (
                <>
                  <Box
                    onClick={() => setVideoStarted(true)}
                    
                    component="img"
                    sx={{
                      width: 1,
                    }}
                    src={attachments[0].url?.full}
                  />
                  <Iconify
                    onClick={() => setVideoStarted(true)}
                    icon="akar-icons:play"
                    width={40}
                    height={40}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      cursor: 'pointer',
                      transition: "0.2s",
                      p:0.5,
                      transform: 'translate(-50%, -50%)',
                      "&:hover":{
                        p: 0
                      }
                    }}
                  />
                </>
              )}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', p: 2 }}>
              <Iconify icon={'bx:video'} width={40} height={40} />
              <br />
              <Typography
                variant="subtitle"
                sx={{
                  py: 1,
                  textAlign: 'center',
                  pl: 0,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  ...(completed && { opacity: 0.48 }),
                }}
              >
                {translate('dragndrop.dropvideohere')}
              </Typography>
            </Box>
          )}

          {name && (
            <Typography
              variant="caption"
              onClick={onOpen}
              sx={{
                py: 1,
                pl: 0,
                transition: (theme) =>
                  theme.transitions.create('opacity', {
                    duration: theme.transitions.duration.shortest,
                  }),
                ...(completed && { opacity: 0.48 }),
              }}
            >
              {translate(name)}
            </Typography>
          )}
        </Box>
        <Tooltip title={translate('general.settings')}>
          <Button
            onClick={onOpen}
            className="settings-btn"
            sx={{ position: 'absolute', display: "none", right: 4, top: 4, zIndex: 100, p: 0, minWidth: 'unset',
            "&:hover": {transform: "scale(1.1)"}, transition:"0.2s transform" }}
          
          >
            <Iconify icon="ic:baseline-settings" sx={{ width: 15, height: 15 }} />
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}

export function VideoModal({ board, card, isOpen, onClose, onUpdate, onDeleteTask, onAttachAdd, ...other }) {
  const isDesktop = useResponsive('up', 'sm');
  const { translate } = useLocales();
  const titleInput = useRef(null);
  const [alt, setAlt] = useState(card.alt || '');
  const [curFile, setCurFile] = useState(null);
  const [name, setName] = useState(card.name || '');
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);

  const onAttachChanged = async (allFiles, newFiles) => {
    setCurFile(newFiles[0]);
    // const filesNew = await dispatch(uploadAttachment({type: "video", files: newFiles}))
    // dispatch(addAttachment({ board, attachments: filesNew?.map((item) => item._id || ''), card: card._id }));
  };

  const deleteAttach = async (list, c) => {
    dispatch(deleteAttachment({ cardId: card._id, file: c }));
  };

  const onCloseHandler = () => {
    setOpened(false);
    onClose({ name, alt });
  };

  useEffect(() => {
    if (opened) {
      if (titleInput.current) {
        titleInput.current.focus();
        titleInput.current?.setSelectionRange(name.length - 1, name.length - 1);
      }
    }
  }, [opened]);

  console.log(card.attachments[0]);
  return (
    <>
      <OutlinedInput
        fullWidth
        ref={titleInput}
        multiline
        size="small"
        placeholder={translate('dragndrop.imagetitle')}
        value={name}
        autoFocus
        onChange={(e) => {
          setName(e.target.value);
        }}
        onBlur={() => onUpdate({ name, changed: true })}
        sx={{
          typography: 'h6',
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        }}
      />
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        {translate('dragndrop.cards.video')}
      </Typography>
      <Stack direction="row">
        <KanbanTaskAttachments
          attachments={card.attachments}
          onAttachChanged={onAttachChanged}
          deleteAttach={deleteAttach}
        />
      </Stack>

      {curFile && (
        <>
          <Typography variant="caption">
            Bitte springe zur gewünschten Position im Video, die du als Thumbnail verwenden willst und drück dann auf
            "Thumbnail generieren"
          </Typography>
          <GetThumbnail file={curFile || card.attachments[1].url.preview} board={board} card={card} />
        </>
      )}
    </>
  );
}
