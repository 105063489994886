import PropTypes from 'prop-types';

import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Typography, Stack, Container } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import useLocales from '../../../hooks/useLocales';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Iconify from '../../Iconify';
import Label from '../../Label';
import Image from '../../Image';
import { ColorPreview } from '../../color-utils';

// ----------------------------------------------------------------------

CardIcon.propTypes = {
  item: PropTypes.object,
  custom: PropTypes.bool
};


export default function CardIcon({ item, onSelect, custom}) {
  const { translate } = useLocales();
  const { name, title, cover, icon, colors, status, priceSale } = item;

  // const linkTo = PATH_DASHBOARD.eCommerce.view(paramCase(name));

  return (
    <Container
      align="center"
      sx={{ p: 1, cursor: 'pointer', position:"relative", transition:"0.2s", m: 1,
      '&:hover': {
        boxShadow: "0px 0px 3px black",
        borderRadius:"10px"
      }, }}

      onClick={(e)=>{onSelect({custom, ...item})}}
    >
      <Box sx={{ position: 'relative' }} align="center">
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        <Iconify icon={icon} width={50} height={50} />
      </Box>

      <Stack spacing={1} sx={{zIndex: "300"}}>
        <Typography variant="subtitle2" align="center" noWrap>
          {translate(name || title)}
        </Typography>
      </Stack>
    </Container>
  );
}
