import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, ListSubheader, Stack } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Scrollbar from '../Scrollbar';

import useDrag from '../../hooks/useDrag';

// import ServiceCommandUnit from './ServiceCommandUnit';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  visibility: isDragging ? 'visible' : 'unset',
  ...draggableStyle,
});



// ----------------------------------------------------------------------

DroplistVertical.propTypes = {
  isCollapse: PropTypes.bool,
  items: PropTypes.object,
};

const generateKey = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  Array.from(Array(length).keys()).map(() => {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
    return true;
  });
  return result;
};

export default function DroplistVertical({
  title,
  onDragEnd,
  dragItem,
  id,
  style,
  children,
  header,
  onColumnDelete,
  onUpdate,
  isDropDisabled,
  items = [],
  type = 'card',
  ...other
}) {
  return (
    <Box {...other}>

      <Droppable isDropDisabled={isDropDisabled} droppableId={`drop-${title}-${id}`} direction="horizontal" type={type} >
        {(provided) => (
            <div>
              {header && <>{header}</>}
              <Stack
                ref={provided.innerRef} {...provided.droppableProps}
                style={{minHeight:"100px", ...style}}
                direction="row"
                alignItems="flex-start"
                spacing={3}
              >
                {Array.isArray(children) && children.map((item, index) => (
                    <Draggable
                      key={`drag-${title}-${id}-${item.key}`}
                      draggableId={`drag-${title}-${id}-${item.key}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div style={{margin:"unset"}}>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{...getItemStyle(snapshot.isDragging, provided.draggableProps.style), paddingRight: "10px"}}
                          >
                            {dragItem && (
                              <>
                                <div {...provided.dragHandleProps}>{dragItem}</div>
                                {item}
                              </>
                            )}
                            {!dragItem && (
                              <>
                                <div {...provided.dragHandleProps}>{item}</div>
                              </>
                            )}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
              </Stack>
              
            </div>
        )}
      </Droppable>

      {/* <Droppable droppableId={`"navboard"-${items.board._id}`} type="navcolumn">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {items.columns.map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {(provided, snapshot) => (
                  <div>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <span
                        {...provided.dragHandleProps}
                        style={{
                          display: 'inline-block',
                          margin: '0 10px',
                          select: 'noselect',
                        }}
                      >
                        <ListSubheaderStyle
                          sx={{
                            ...(isCollapse && {
                              opacity: 0,
                            }),
                          }}
                        >
                          {item.title}
                        </ListSubheaderStyle>
                      </span>
                      {/* <ServiceCommandUnit
                        onOpen={onOpen}
                        isCollapse={isCollapse}
                        subItems={item.cards || item.items}
                        type={item._id}
                        /> 
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
    </Droppable> */}
    </Box>
  );
}
