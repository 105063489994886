import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, ListSubheader } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';

// import ServiceCommandUnit from './ServiceCommandUnit';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

CardColumn.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function CardColumn({ onDragEnd, name, dragItem, children, type = 'card-card', ...other }) {
  return (
    <Box {...other}>
      <Droppable droppableId={`drop-recipes-${name}`} direction="vertical" type={type || 'card-card'}>
        {(provided) => (
          <>
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {Array.isArray(children) && children.map((item, index) => <div key={`${index}-ca`}>{item}</div>)}
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </Box>
  );
}
