import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';

// ----------------------------------------------------------------------

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, curr) => {
    accumulator[curr[key]] = curr;
    return accumulator;
  }, {});
}

function generateRandomString(length) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  // eslint-disable-next-line
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

const initialState = {
  cards: [],
  columns: [{}],
  rows: [{columns: [{_id: generateRandomString(12)}]}]
};

const slice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPageSuccess(state, action){
      const {rows} = action.payload
      const cols = {}
      rows.map((row)=>{
        row.columnOrder.map((item)=>{
          state.columns[item._id] = item;
          return true;
        })
        return true;
      })
      state.rows = rows
    },

    
    splitRow(state, action){
      console.log(action.payload)
      const {index, column} = action.payload
      const columnName1 = generateRandomString(12)
      const columnName2 = generateRandomString(12)
      state.columns[columnName1] = {}
      state.columns[columnName2] = {}
      state.columns[column].columns = [{_id: columnName1}, {_id: columnName2}]
    },

    addRow(state, action){
      const columnName1 = generateRandomString(12)
      state.rows.push({columns: [columnName1]})
    },

    dropToWebSuccess(state, action){
      const {droppable, card} = action.payload
      const [name, row, column] = droppable.split("-")
      state.rows[row].columns[column].cards.push(card.card)
      console.log(droppable, card.card)
    }
  },
});

// Reducer
export default slice.reducer;

export const { 
  splitRow,
  addRow,
} = slice.actions;

// ----------------------------------------------------------------------

export function dropToWeb({card, droppable}) {
  return async (state, action) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/card',
        {
          params: { id: card.id },
        }
      );
      dispatch( slice.actions.dropToWebSuccess({card: response.data, droppable}) );
      return false
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {data: error, status: 400}
    }
  };
}

export function getPage(page) {
  return async (state, action) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/page',
        {
          params: { id: page },
        }
      );
      dispatch( slice.actions.getPageSuccess(response.data) );
      return false
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {data: error, status: 400}
    }
  };
}