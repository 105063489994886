// components
import Iconify from '../components/Iconify';
//
import _mock from './_mock';

// ----------------------------------------------------------------------
export const CardPermissions = [
  {
    short: 'r',
    subtitle: 'permissions.read',
    icon: 'mdi:glasses',
    children: [
      {
        parent: "r",
        short: 'r.c',
        subtitle: 'permissions.copy',
      },
    ],
  },
  {
    short: 'w',
    subtitle: 'permissions.write',
    icon: 'eva:edit-fill',
    children: [
      {
        parent: "w",
        short: 'w.e',
        subtitle: 'menu.edit',
      },
      {
        parent: "w",
        short: 'w.m',
        subtitle: 'permissions.move',
      },
    ],
  },
  {
    short: 's',
    subtitle: 'permissions.share',
    icon: 'eva:share-fill',
    children: [{
      parent: "s",
      short: 's.i',
      subtitle: 'permissions.invite',
    }],
  },
  {
    short: 'p',
    subtitle: 'permissions.admin',
    icon: 'ic:outline-admin-panel-settings',
    children: [
      {
        parent: "p",
        short: 'p.d',
        subtitle: 'permissions.delete',
      },
      {
        parent: "p",
        short: 'p.p',
        subtitle: 'permissions.permissions',
      },
    ],
  },
];


export const ColumnPermissions = [
  {
    short: 'r',
    subtitle: 'permissions.read',
    icon: 'mdi:glasses',
    children: [
      {
        parent: "r",
        short: 'r.c',
        subtitle: 'permissions.copy',
      },
    ],
  },
  {
    short: 'w',
    subtitle: 'permissions.write',
    icon: 'eva:edit-fill',
    children: [
      {
        parent: "w",
        short: 'w.e',
        subtitle: 'menu.edit',
      },
      {
        parent: "w",
        short: 'w.m',
        subtitle: 'permissions.move',
      },
      {
        parent: "w",
        short: 'w.a',
        subtitle: 'permissions.addcard',
      },
      {
        parent: "w",
        short: 'w.r',
        subtitle: 'permissions.changeorder',
      },
    ],
  },
  {
    short: 's',
    subtitle: 'permissions.share',
    icon: 'eva:share-fill',
    children: [{
      parent: "s",
      short: 's.i',
      subtitle: 'permissions.invite',
    }],
  },
  {
    short: 'p',
    subtitle: 'permissions.admin',
    icon: 'ic:outline-admin-panel-settings',
    children: [
      {
        parent: "p",
        short: 'p.d',
        subtitle: 'permissions.delete',
      },
      {
        parent: "p",
        short: 'p.p',
        subtitle: 'permissions.permissions',
      },
    ],
  },
];


export const BoardPermissions = [
  {
    short: 'r',
    subtitle: 'permissions.read',
    icon: 'mdi:glasses',
    children: [
      {
        parent: "r",
        short: 'r.c',
        subtitle: 'permissions.copy',
      },
    ],
  },
  {
    short: 'w',
    subtitle: 'permissions.write',
    icon: 'eva:edit-fill',
    children: [
      {
        parent: "w",
        short: 'w.e',
        subtitle: 'menu.edit',
      },
      {
        parent: "w",
        short: 'w.m',
        subtitle: 'permissions.move',
      },
      {
        parent: "w",
        short: 'w.a',
        subtitle: 'permissions.addcard',
      },
      {
        parent: "w",
        short: 'w.r',
        subtitle: 'permissions.changeorder',
      },
      {
        parent: "w",
        short: 'w.cm',
        subtitle: 'permissions.movecolumns',
      },
    ],
  },
  {
    short: 's',
    subtitle: 'permissions.share',
    icon: 'eva:share-fill',
    children: [{
      parent: "s",
      short: 's.i',
      subtitle: 'permissions.invite',
    }],
  },
  {
    short: 'p',
    subtitle: 'permissions.admin',
    icon: 'ic:outline-admin-panel-settings',
    children: [
      {
        parent: "p",
        short: 'p.d',
        subtitle: 'permissions.delete',
      },
      {
        parent: "p",
        short: 'p.p',
        subtitle: 'permissions.permissions',
      },
    ],
  },
];
