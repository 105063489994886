import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
// form

// @mui

import { alpha, styled } from '@mui/material/styles';
import { Box, Button, Typography, OutlinedInput, Checkbox } from '@mui/material';

import { useDropzone } from 'react-dropzone';
// routes
import useLocales from '../../../../hooks/useLocales';

import { dispatch, useSelector } from '../../../../redux/store';
import { addAnswer, setAnswer, setQuestion, setAnswerCorrect } from '../../../../redux/slices/question';

import Iconify from '../../../Iconify';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 32,
  height: 32,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,

  '&:hover': { opacity: 0.72 },
}));

export default function Question({ question, card, index, sync }) {

  const { translate } = useLocales();
  const { questions } = useSelector((state) => state.question);

  const {name} = question
  const [correct, setCorrect] = useState(question?.answers?.map((item)=>item.correct || []))
  const onNewAnswer = async () => {
    await dispatch(addAnswer(card.quizId?._id, question, index));
  };
  console.log(correct)
  const handleDrop = async (acceptedFiles) => {
    console.log("DROPPED ATTACHMENT")
  };

  const onAnswerNameChange = async (e, aindex)=>{
    await dispatch(setAnswer(card.quizId?._id, question, index, e.target.value, aindex));

  }

  const onQuestionNameChange = async (e, aindex)=>{
    await dispatch(setQuestion(card.quizId?._id, question, index, e.target.value));
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  

  return (
    <>
      <OutlinedInput onBlur={onQuestionNameChange} fullWidth defaultValue={name} placeholder={`${translate("dragndrop.quiz.questionname")} ${index+1}`} />
      
      {question?.answers?.map((item, aindex)=>(
        <Box sx={{
          display: 'grid',
          gridAutoFlow: 'column',
        }}>
          <OutlinedInput onBlur={(e)=>{onAnswerNameChange(e, aindex)}} sx={{height:"40px", m:1}} defaultValue={item?.name || ""} placeholder={`${translate("dragndrop.quiz.answername")} ${aindex+1}`} />
          <DropZoneStyle
            {...getRootProps()}
            sx={{
              m:1.5,
              ...(isDragActive && { opacity: 0.72 }),
            }}
          >
            <input {...getInputProps()} />
            <Iconify icon={'bx:image-add'} sx={{ color: 'text.secondary' }} />
          </DropZoneStyle>
          <Checkbox checked={ correct[aindex] || false } onClick={(e)=>{
            const old = [...correct]
            dispatch(setAnswerCorrect(card.quizId?._id, question, index, !correct[aindex], aindex))
            old[aindex] = !correct[aindex]
            setCorrect(old)
          } } />
          
        </Box>
      ))}
      <Button sx={{my:1}} fullWidth onClick={onNewAnswer}>{translate('dragndrop.quiz.newanswer')}</Button>
    </>
  );
}
