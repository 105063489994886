import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, IconButton, ListItemText, ListItem } from '@mui/material';

// utils
import { fData } from '../../utils/formatNumber';

import { DragListColumn, DragListItem } from '../draglist';
import ImageEdit from './ImageEdit';




//
import Image from '../Image';
import Iconify from '../Iconify';
import ImagePreview from './ImagePreview';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.key || file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
};

export default function MultiFilePreview({ _id, showPreview = false, files, onDragEnd, onUpload, onRemove, onRemoveAll }) {
  return (
    <>
      <List disablePadding >
        <AnimatePresence>
          <DragListColumn direction="horizontal" onDragEnd={onDragEnd} items={files} id="CoverMulti">
            {files.map((file, index) => {
              const { key, name, size, preview } = getFileData(file);

              if (showPreview) {
                return (
                  <DragListItem direction="horizontal" style={{display: "inline-block"}} keyy={`file${key}`} key={`file${key}`} item={file} id={key} index={index}>
                    <ImagePreview file={file} onRemove={onRemove} _id={_id}/>
                    {/* <ImageEdit /> */}
                  </DragListItem>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    my: 1,
                    px: 2,
                    py: 0.75,
                    borderRadius: 0.75,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size || 0)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />

                  <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                </ListItem>
              );
            })}
          </DragListColumn>
        </AnimatePresence>
      </List>

      {/* {hasFile && (
         <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          <Button size="small" variant="contained" onClick={onUpload}>
            Upload files
          </Button>
        </Stack>
      )} */}
    </>
  );
}
