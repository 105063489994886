import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Avatar, Tooltip } from '@mui/material';

import useAuth from '../../../hooks/useAuth';

import useLocales from '../../../hooks/useLocales';

import { TextCard, ImageCard, VideoCard, LinkCard, PlaceholderCard, HTMLCard } from '../cards';

const basicCards = { text: 1, video: 1, image: 1, link: 1, placeholder: 1, code: 1 };

export default function CardItemGlobal({ ...other }) {
  const { translate } = useLocales();
  const { card } = other;
  if (card.cardType === 'text') return <TextCard {...other} />;
  if (card.cardType === 'image') return <ImageCard {...other} />;
  if (card.cardType === 'video') return <VideoCard {...other} />;
  if (card.cardType === 'link') return <LinkCard {...other} />;
  if (card.cardType === 'placeholder') return <PlaceholderCard {...other} />;
  if (card.cardType === 'code') return <HTMLCard {...other} />;
  return <></>;
}
