import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';


// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, curr) => {
    accumulator[curr[key]] = curr;
    return accumulator;
  }, {});
}


const initialState = {
  isLoading: false,
  error: null,
  fields: [],
  name: ""
};

const slice = createSlice({
  name: 'randomizer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getRandomizerSuccess(state, action) {
      state.isLoading = false;
      const {fields, name} = action.payload
      state.fields = fields
      state.name = name
    },

    addRandomizerColumnSuccess(state, action) {
      state.isLoading = false;
      const {name} = action.payload
      state.fields.push({name})
    },

    createFieldSuccess (state, action){
      const {index, name} = action.payload
      if (!current(state).fields[index].items){
        state.fields[index].items = [{name}]
        return;
      }
      state.fields[index].items.push({name})
    },
    deleteFieldSuccess (state, action){
      const {column, index} = action.payload
      state.fields[column].items.splice(index, 1)
      console.log(index, column)
    },
    deleteColumnSuccess (state, action){
      const {column} = action.payload
      state.fields.splice(column, 1)
    },
    renameColumnSuccess (state, action){
      const {column, name} = action.payload
      state.fields[column].name = name
    }
  },
});

// Reducer
export default slice.reducer;

export const { 
  addCardSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

// ---------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getRandomizer() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/user/randomizer')
      dispatch(slice.actions.getRandomizerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createColumn({name}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/user/randomizer/column', {name})
      dispatch(slice.actions.addRandomizerColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createField(index, name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/user/randomizer/field', {index, name})
      dispatch(slice.actions.createFieldSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteField(column, index) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const response = await axios.delete('/user/randomizer/field', {params: {column, index}})
      dispatch(slice.actions.deleteFieldSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(column, index) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/user/randomizer/column', {params: {column, index}})
      dispatch(slice.actions.deleteColumnSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function renameColumn(column, name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(column)
      const response = await axios.put('/user/randomizer/column', {column, name})
      dispatch(slice.actions.renameColumnSuccess(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}