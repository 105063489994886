import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Typography, Box, Checkbox, Button } from '@mui/material';
import styled from "@emotion/styled";

import useLocales from '../../hooks/useLocales';
// components
import Image from '../Image';
import Iconify from '../Iconify';

  
  const grid = 8;
 
  
  const QuoteItem = styled.div`
  
  `;
  
  const QuoteItemHorizontal = styled.div`
    display: inline-block;
  `;
  
 export default function DragListItem(props) {
    const { translate } = useLocales();
    if (props.direction === "horizontal"){

      return <Draggable draggableId={props.item.keyy || props.keyy} key={props.item.keyy || props.keyy} keyy={props.item.keyy || props.keyy} index={props.index} id={props.item.id}>
        {provided => (
            <QuoteItemHorizontal 
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {props.children}
            </QuoteItemHorizontal>
        )}
      </Draggable>
    } 
    return (
      <Draggable draggableId={props.item.keyy || props.keyy} key={props.item.keyy || props.keyy} keyy={props.item.keyy || props.keyy} index={props.index} id={props.item.id}>
        {provided => (
            <QuoteItem 
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {props.children}
            </QuoteItem>
        )}
      </Draggable>
    );
  }