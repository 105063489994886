import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui

import { TextField, Typography, Paper, Box } from '@mui/material';

import { languages } from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism.css';

// hooks
import useLocales from '../../../hooks/useLocales';

// components

import Iconify from '../../Iconify';


// @mui

import { dispatch, useSelector } from '../../../redux/store';
import { updateCardCode, updateCard } from '../../../redux/slices/kanban';

// ----------------------------------------------------------------------

HTMLCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export function HTMLCard({ paperStyle = {}, onOpen, onChange, card, sx, ...other }) {
  const { translate } = useLocales();

  const [rendered, setRendered] = useState("")

  useEffect(()=>{
    const rText = replaceAll(`${card?.code[0]?.value}`, `/wp-content`, `http://localhost/wp-content`);
    setRendered(rText)
  }, [card?.rendered])



  const { website } = useSelector((state) => state.categories);
  const [open, setOpen] = useState(null);
  return (
    <>
      <Paper
        onClick={onOpen}
        component="span"
        sx={{
          p: 2,
          width: 1,
          borderRadius: 0,
          display: 'block',

          position: 'relative',
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            minHeight: '40px',

            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
          ...paperStyle,
        }}
      >
        <>
          <Box
            className={`c-${card._id}`}
            dangerouslySetInnerHTML={{ __html: `${rendered || ''}` }}
          />
          {(!card.code[0]?.value || card.code[0]?.value === '') && (
            <Typography
              variant="subtitle2"
              sx={{ textAlign: 'center', border: '1px dashed grey', borderRadius: '6px', p: 3 }}
            >
              <Iconify icon="ant-design:code-outlined" width={45} height={45} />
              <br />
              Dieser Codeblock ist noch leer!
              <br />
              Klicke hier um die Karte zu bearbeiten
            </Typography>
          )}
        </>
      </Paper>
      {/* {(open || card.isNew) && <HTMLCardModal {...other} card={card} isOpen={open || card.isNew} onClose={onClose} /> */}
    </>
  );
}

// ----------------------------------------------------------------------
/* eslint-disable */
const languageBoxStyle = (enabled) => ({
  bgcolor: enabled ? 'primary.main' : 'background.neutral',
  textAlign: 'center',
  cursor: 'pointer',
  px: 2,
  borderRadius: '4px',
});

const langs = [languages.html, languages.css, languages.js];

export function HTMLModal({ card, isOpen, onClose, position, onSelect, ...other }) {
  const { translate } = useLocales();

  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(0);

  
  

  const [code, setCode] = useState([{ value: '' }, { value: '' }, { value: '' }]);

  const [open, setOpen] = useState(null);
  const [isChange, setIsChange] = useState(null);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setName(card.name || '');
    setCode(card.code.length === 3 ? card.code : [{ value: '' }, { value: '' }, { value: '' }]);
  }, []);

  const onCodeChange = (value) => {
    const copy = [...code];
    copy[0] = { ...copy[0], value };
    setCode(copy);
    setIsChange(true);
  };

  return (
    <>
      <TextField
        sx={{ mb: 2 }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setIsChange(true);
        }}
        label="Titel"
        variant="standard"
        fullWidth

      />
      <Box>
        <CardPreview code={code} onCodeChange={onCodeChange} card={card} />
        {/* `${card.code[0]?.value?card.code[0]?.value:""} <script>${card.code[2]?.value}</script>` }} / */}
      </Box>
    </>
  );
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function CardPreview({ code, onCodeChange, card, ...other }) {
  const { translate } = useLocales();

  const handleChange = () => {
    const elem = document.getElementById('card-preview');
    onCodeChange(replaceAll(elem.innerHTML, 'contenteditable="true"', ''));
  };

  const childCheck = (elem) => {
    [...elem.childNodes].map((item) => {
      item.contentEditable = true;
      item.onblur = handleChange;
      childCheck(item);
      return true;
    });
  };
  useEffect(() => {
    const elem = document.getElementById('card-preview');
    childCheck(elem);
  }, [code]);
  return (
    <Box>
      <Box id="card-preview" className={`c-${card._id}`} dangerouslySetInnerHTML={{ __html: code[0]?.value || '' }} />{' '}
    </Box>
  );
}
