import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import settingsReducer from './slices/settings';
import appsReducer from './slices/apps';
import recipesReducer from './slices/recipes';
import randomizerReducer from './slices/randomizer';
import questionReducer from './slices/question';
import categorieReducer from './slices/categories';
import printReducer from './slices/prints';
import adminReducer from './slices/admin';
// import pageReducer from './slices/pages';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({

  chat: chatReducer,

  kanban: kanbanReducer,
  apps: appsReducer,
  settings: settingsReducer,
  recipes: recipesReducer,
  // pages: pageReducer,
  admin: adminReducer,
  categories: categorieReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
