import { paramCase } from 'change-case';
import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { Typography, Button, Card, CardContent, DialogTitle, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// redux
import useSettings from '../../hooks/useSettings';

// routes
import useLocales from '../../hooks/useLocales';

import { SeoIllustration } from '../../assets';
import { DialogAnimate } from '../animate';

import axios from '../../utils/axios';

import { dispatch } from '../../redux/store';
import { addPage } from '../../redux/slices/kanban';


import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function BoardAddDialog({website, onClose, isOpen}) {
  const { translate } = useLocales();

  const [textValue, setTextValue] = useState("")
  const navigate = useNavigate();


  const addPageToDb = async () => {
    const response = await dispatch(addPage({website, title: textValue}))
    navigate(PATH_DASHBOARD.pages.view(response.id));
    onClose()
  };

  return (<>
      <TextField value={textValue} onChange={(e)=>setTextValue(e.target.value)} label={translate('widgets.addpagetitle')} />
      <Button onClick={addPageToDb} variant="contained" to="#" component={RouterLink} style={{ marginTop: '20px' }}>
        {translate('widgets.addbtn')}
      </Button>
    </>
  );
}
