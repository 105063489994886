import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';

import { SketchPicker } from 'react-color';

import {
  Box,
  Stack,
  Drawer,
  Divider,
  Typography,
  Button,
  Slider,
  Grid,
  Checkbox
} from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
import useLocales from '../../../../hooks/useLocales';
// components

import { dispatch } from '../../../../redux/store';
import { setSettings, updateStyle, updateColumnLocal } from '../../../../redux/slices/kanban';


import Iconify from '../../../Iconify';
import Scrollbar from '../../../Scrollbar';
import ColumnToolbar from './ColumnToolbar';
import CardStyleManager from '../../components/CardStyleManager';



// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ColumnModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function ColumnModal({ page, row, column, rowObject={}, onClose, open, ...other }) {
  const [space, setSpaceLocal] = useState(column.sx?.space || 0);
  const [mt, setMt] = useState(column.sx?.mt || 0);
  const [mb, setMb] = useState(column.sx?.mb || 0);
  const [bgcolor, setColor] = useState(column.sx?.bgcolor || 0);
  const [color, setTextColor] = useState(column.sx?.color || 0);
 
  const [centerVertical, setCenterVertical] = useState(column.sx?.sameheight || false);
  const [centerHorizontal, setCenterHorizontal] = useState(column.sx?.textAlign || false);
  const onCloseHandler = () => {
    dispatch(updateStyle({type: "column", column: column._id, sx: {...column.sx, textAlign: centerHorizontal?"center":"left",sameheight: centerVertical, space, mt, mb, bgcolor: bgcolor?.hex || "unset"}}))
    onClose();
  };

  const toggleCenterVertical = ()=>{
    setCenterVertical(!centerVertical);
  }
  const toggleCenterHorizontal = ()=>{
    setCenterHorizontal(!centerHorizontal);
  }

  const updateLocal = (data)=>{
    dispatch(updateColumnLocal({ data, column: column._id }));
  }
  
  return (
    <>
      <Drawer open={open} onClose={onCloseHandler} anchor="left" BackdropProps={{ invisible: true}} PaperProps={{ sx: {  width: { xs: 1, sm: 360 } } }}>
        <ColumnToolbar column={column} row={row} onClose={onCloseHandler} page={page} />
        <CardStyleManager type="column" {...other} onChange={updateLocal} card={column}/>
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            
            <Stack direction="row">
              <Checkbox onClick={toggleCenterVertical} checked={centerVertical} label={"Inhalt vertikal zentrieren"}/>
              <Typography sx={{margin:"auto"}}>Inhalt vertikal zentrieren</Typography>
            </Stack>
            <Stack direction="row">
            <Checkbox onClick={toggleCenterHorizontal} checked={centerHorizontal} label={"Inhalt horizontal zentrieren"}/>
              <Typography sx={{margin:"auto"}}>Inhalt horizontal zentrieren</Typography>
            </Stack>
          </Stack>
        </Scrollbar>

        <Divider />

        {/* <KanbanTaskCommentInput /> */}
      </Drawer>
    </>
  );
}
