import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// @mui
import { alpha } from '@mui/material/styles';
import { Tooltip, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox } from '@mui/material';

import { useDispatch } from '../../../../redux/store';
import { getConversations, getContacts } from '../../../../redux/slices/chat';

// utils
import { fToNow } from '../../../../utils/formatTime';
// _mock_

import useLocales from '../../../../hooks/useLocales';
import useSocket from '../../../../hooks/useSocket';

// components
import Iconify from '../../../Iconify';
import Scrollbar from '../../../Scrollbar';
import MenuPopover from '../../../MenuPopover';
import ContactSettings from '../../../contacts/ContactSettings';
import ContactAdd from '../../../contacts/ContactAdd';
import BadgeStatus from '../../../BadgeStatus';
import { IconButtonAnimate } from '../../../animate';

import { _accordions } from '../../../../_mock';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

const iconStyle = {
  transform: 'translateY(2px)',
  marginRight: '5px',
};

export default function ContactsPopover({ accordion, user, children }) {
  const { translate } = useLocales();
  

  return (
    <>
      <Accordion key={accordion.value} sx={{ width: 1 }}>
        <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
          <Typography variant="subtitle1">
            <Iconify icon={accordion.icon} width={16} height={16} style={iconStyle} />
            {translate(accordion.subtitle)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
}
