import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';


// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}


const initialState = {
  isLoading: false,
  error: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
    _id: ""
  },
};

const slice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload)
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      
      const result = action.payload;
      const board_ = result.board;

      const columns = objFromArray(result.columns || []);
      const { columnOrder } = board_; 
      
      state.board = {
        ...board_,
        columns,
        columnOrder,
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      // HIER ANSETZEN!
      const newColumn = action.payload;
      console.log(action.payload)
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn._id]: newColumn,
      };
      state.board.columnOrder.push(newColumn._id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.isLoading = false;
      state.board.columnOrder = action.payload;
    },

    persistColumnSuccess(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      console.log(state)
      const { card, columnId } = action.payload;
    
      // state.board.columns[columnId].cards[card._id] = card;
      state.board.columns[columnId].cards.push(card);
      console.log(state.board)
    },


    deleteTask(state, action) {
      state.isLoading = false;
      const { cardId, columnId } = action.payload;
      console.log(cardId)
      state.board.columns[columnId].cards = state.board.columns[columnId].cards.filter((item) => {return item._id !== cardId;});
      // state.board.columns[columnId].cards = omit(state.board.columns[columnId].cards, cardId);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;
      
      state.isLoading = false;
      state.board.columns[column._id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId, boardId } = action.payload;
      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getApps() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/apps') // await axios.get('https://minimal-assets-api.vercel.app/api/apps/board'); // appsBoard()
      dispatch(slice.actions.getBoardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    console.log(newColumn)
    try {
      const response = await axios.post('/apps/column', newColumn);
      console.log(response)
      dispatch(slice.actions.createColumnSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, updateColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(columnId, updateColumn)
      const response = await axios.post('/apps/columns/update', {
        columnId,
        updateColumn,
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId, boardId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/apps/column/delete', { columnId, boardId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId, boardId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder, boardId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.persistColumn(newColumnOrder, boardId));
    // dispatch(slice.actions.persistColumn(newColumnOrder));
    try {
      await axios.post('/apps/columns/order', { newColumnOrder, boardId });
      // dispatch(slice.actions.persistColumnSuccess({ newColumnOrder, boardId }));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}


// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }) {
  return async (dispatch) => {
    console.log(cardId, columnId)
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
    try {
      await axios.post('/apps/card/delete', { cardId, columnId });
      // dispatch(slice.actions.persistColumnSuccess({ newColumnOrder, boardId }));
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
