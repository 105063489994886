import { useState, useRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Tooltip, Typography } from '@mui/material';

import { useDispatch } from '../../../../redux/store';

// _mock_

import useLocales from '../../../../hooks/useLocales';


// components
import Scrollbar from '../../../Scrollbar';
import MenuPopover from '../../../MenuPopover';
import {CardAssigneePermissions} from './CardAssigneePermissions';
import { IconButtonAnimate } from '../../../animate';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function CardAssigneePopover({ user, card, ...other }) {

  const [open, setOpen] = useState(null);

  const { translate } = useLocales();

  const permissionWindow = useRef(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    if (permissionWindow.current){
      permissionWindow.current.save()
    }
    setOpen(false);
  };
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Tooltip title={`${user.firstName} ${user.lastName}`}>
          <Avatar
            key={user?._id}
            alt={user?.firstName}
            src={user?.avatar && user?.avatar?.url ? user?.avatar?.url?.thumbnail : user?.avatar}
            sx={{ m: 0.5, width: 36, height: 36 }}
          />
        </Tooltip>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
        onDragEnter={(e) => {
          setOpen(false);
        }}
      >
        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {open && <CardAssigneePermissions ref={permissionWindow} {...other} handleClose={handleClose} card={card} user={user} />}
        </Scrollbar>
      </MenuPopover>

    </>
  );
}
