import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Paper, TextField } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import CardModalGlobal from '../components/CardModalGlobal';

import { useDispatch } from '../../../redux/store';
import Editor from '../../editor';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

TextCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export function TextCard({ disableTitle, onOpen, paperStyle = {}, preview, onChange, card, sx, ...other }) {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [open, setOpen] = useState(null);

  const openWindow = () => {
    if (!preview) setOpen(true);
  };

  const onClose = async (data) => {
    const response = await onChange({ ...data, _id: card._id });
    if (response === 'to') {
      setTimeout(() => {
        setOpen(false);
      }, 200);
      return;
    }
    if (!data.noclose) setOpen(false);
  };
  return (
    <>
      <Paper
        onClick={onOpen}
        component="span"
        sx={{
          p: 2,
          width: 1,

          display: 'block',
          transition:"0.2s box-shadow",
          overflow: 'hidden',
          position: 'relative',
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          "&:hover":{
            boxShadow: "0px 0px 2px black"
          },
          ...sx,
          ...paperStyle,
        }}
      >
        {(!card.description || card.description === '') && (!card.name || card.name === '') && (
            <Box sx={{ textAlign: 'center', p: 2, bgcolor:"grey.8008" }}>
              <Iconify icon={'carbon:text-scale'} width={40} height={40} />
              <br />
              <Typography
                variant="subtitle"
                sx={{
                  py: 1,
                  textAlign: 'center',
                  pl: 0,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    })
                }}
              >
                {translate('dragndrop.addtext')}
              </Typography>
            </Box>

        )}
        {!disableTitle && (card.description || card.name !== '') && (
          <Typography variant="subtitle">{card.name}</Typography>
        )}
        {(!card.description || card.name === '' || disableTitle) && (
          <Box dangerouslySetInnerHTML={{ __html: card.description }} />
        )}
      </Paper>
    </>
  );
}


export function TextModal({ card, preview, open, onUpdate, ...other }) {

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    setName(card?.name || '');
    setDescription(card?.description || '');
  }, [card]);

  return (
    <>
      <TextField
        sx={{ mb: 2 }}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
          setIsChange(true);
        }}
        onBlur={()=>onUpdate({name, changed: true})}
        label="Titel"
        variant="standard"
        fullWidth
      />
      <Editor
        simple
        id={'editor'}
        value={description}
        autoFocus
        onChange={(e) => {
          setDescription(e);
          setIsChange(true);
        }}
        onBlur={()=>onUpdate({description, changed: true})}
        sx={{ mb: 2 }}
      />
    </>
  );
}
