import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui

import { Drawer, Divider, TextField, Typography } from '@mui/material';

// hooks
import useLocales from '../../../hooks/useLocales';

// components
import { CardAssignee } from './CardAssignee';
import Scrollbar from '../../Scrollbar';
import Editor from '../../editor';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ColumnModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  column: PropTypes.object,
  onDeleteTask: PropTypes.func,
};

export default function ColumnModal({ column, isOpen, onClose, position, onSelect, ...other }) {
  const { translate } = useLocales();

  const [name, setName] = useState('');
  const [text, setText] = useState('');

  const [open, setOpen] = useState(null);
  const [isChange, setIsChange] = useState(null);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  const onCloseHandler = async () => {
    setOpen(false);
    onClose({ text, name, isChange });
  };

  return (
    <>
      <Drawer open={open || false} onClose={onCloseHandler} anchor="left" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Divider />
        <Scrollbar sx={{ p: 2 }}>
          <CardAssignee {...other} card={column} isColumn />
          <TextField
            sx={{ mb: 2 }}
            value={name}
            onChange={(e) => {setName(e.target.value); setIsChange(true)}}
            label="Titel"
            variant="standard"
            fullWidth
            {...other}
          />
          <Editor simple id={'editor'} value={text} onChange={(e) => {setText(e);setIsChange(true)}} sx={{ mb: 2 }} {...other} />

          <Typography variant="caption" sx={{ mt: 2 }}>
            Tipp: <span style={{ opacity: '0.5' }}>{translate('dragndrop.tipps.notitle')}</span>
          </Typography>
          <br />
          <Typography variant="caption">
            Tipp: <span style={{ opacity: '0.5' }}>{translate('dragndrop.tipps.savewindow')}</span>
          </Typography>
        </Scrollbar>

        <Divider />
      </Drawer>
    </>
  );
}
