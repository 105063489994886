import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';


// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, curr) => {
    accumulator[curr[key]] = curr;
    return accumulator;
  }, {});
}


const initialState = {
  isLoading: false,
  error: null,
  questions: [],
  _id: "",
  name: ""
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log(action.payload)
      state.isLoading = false;
      state.error = action.payload;
    },

    setQuestions(state, action){
      state.questions = action.payload
      state._id = action.payload?._id
    },

    addQuestionSuccess(state, action){
      state.questions.push(action.payload)
    },

    addAnswerSuccess(state, action){
      const {index, quizId, question} = action.payload
      state.questions[index].answers.push({name: ""})
    }
  },
});

// Reducer
export default slice.reducer;

export const { 
  addCardSuccess,
  setQuestions
} = slice.actions;

export function addQuestion(quizId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(quizId)
      const response = await axios.post('/card/question', { quizId });
      dispatch(slice.actions.addQuestionSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function addAnswer(quizId, question, index) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/card/answer', { quizId, question, index });
      dispatch(slice.actions.addAnswerSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function setQuestion(quizId, question, index, name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/card/question', { quizId, question, index, name });
      // dispatch(slice.actions.addAnswerSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}
export function setAnswer(quizId, question, index, name, aindex) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/card/answer', { quizId, question, index, name, aindex });
      // dispatch(slice.actions.addAnswerSuccess(response.data))
      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function setAnswerCorrect(quizId, question, index, checked, aindex) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/card/answercorrect', { quizId, question, index, checked, aindex });

      return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

// ----------------------------------------------------------------------

// ---------------------------------------------------------------------

// ----------------------------------------------------------------------
