import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha, useTheme } from '@mui/material/styles';
import { Box, Link, Card, Avatar, Typography, CardContent, Stack } from '@mui/material';


import { dispatch } from '../../../redux/store';
import { setLoading } from '../../../redux/slices/recipes';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useLocales from '../../../hooks/useLocales';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Image from '../../Image';
import Iconify from '../../Iconify';
import TextMaxLine from '../../TextMaxLine';
import TextIconLabel from '../../TextIconLabel';
import SvgIconStyle from '../../SvgIconStyle';



// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.8),
}));

const PublishedStyle = styled('div')(({ theme, published }) => ({
  position:"absolute",
  right: "10px",
  top: "10px",
  width: "10px",
  height: "10px",
  boxShadow: "0px 0px 2px black",
  borderRadius: "10px",
  backgroundColor: alpha(published==="true"?theme.palette.success.main:theme.palette.error.main, 0.8),
}));



// ----------------------------------------------------------------------

RecipeCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function RecipeCard({ sx, post, index, ...other }) {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();
  const theme = useTheme();

  if (!post) return <></>
  const { images, title, createdAt, wpid, published } = post;
  const latestPost = index === 0 || index === 1 || index === 2;


  if (isDesktop && latestPost) {
    return (
      <Card sx={{...sx}}>
        <PostContent post={post} title={title} id={wpid} view={0} comment={0} share={0} createdAt={createdAt} index={index} />
        <OverlayStyle />
        <Image alt="cover" src={images[0].url.replace("einfachmalkochen.com", "einfachmalkochen.de")} objectFit="cover" sx={{ height: 360 }} />
        <PublishedStyle published={published} />
      </Card>
    );
  }

  return (
    <Card sx={{boxShadow: theme.popoverShadow, ...sx}}>
      <Box sx={{ boxShadow: theme.popoverShadow, position: 'relative' }}>
        <SvgIconStyle
          src="https://minimal-assets-api.vercel.app/assets/icons/shape-avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: 'absolute',
            color: 'background.paper'
          }}
        />
        <Image alt="cover" objectFit='cover' src={images?.[0]?images[0].url.replace("einfachmalkochen.com", "einfachmalkochen.de"):""} ratio="4/3" />
        <PublishedStyle published={published} />
      </Box>

      <PostContent sx={sx} post={post} title={title} id={wpid} view={0} comment={0} share={0} createdAt={createdAt} {...other}/>
    </Card>
  );
}

// ----------------------------------------------------------------------

PostContent.propTypes = {
  comment: PropTypes.number,
  createdAt: PropTypes.string,
  index: PropTypes.number,
  share: PropTypes.number,
  title: PropTypes.string,
  view: PropTypes.number,
};

export function PostContent({ post, id, sx, title, view, comment, share, createdAt, index }) {
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();
  const {translate} = useLocales();

  const latestPostLarge = index === 0;
  const latestPostSmall = index === 1 || index === 2;
  const POST_INFO = [
    /* { number: comment, icon: 'eva:message-circle-fill' },
    { number: view, icon: 'eva:eye-fill' },
    { number: share, icon: 'eva:share-fill' }, */
  ];

  return (
    <CardContent
      sx={{
        pt: 2,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
        ...sx
      }}
    >

      <Box sx={{cursor: "pointer"}} onClick={()=>{navigate(PATH_DASHBOARD.modules.recipesedit(post.wpid))}} color="inherit">
        <TextMaxLine variant={isDesktop && latestPostLarge ? 'h5' : 'subtitle2'} line={1} persistent>
          {translate(title)}
        </TextMaxLine>
      </Box>
    </CardContent>
  );
}
