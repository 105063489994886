import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
// @mui
import { Tooltip, Stack, Button, Divider } from '@mui/material';
// components
// import useResponsive from '../../../../hooks/useResponsive';
import { dispatch } from '../../../../redux/store';
import { deleteColumn } from '../../../../redux/slices/kanban';
import Iconify from '../../../Iconify';
import { IconButtonAnimate } from '../../../animate';
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

ColumnToolbar.propTypes = {
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default function ColumnToolbar({ column, page, row, onClose, onDelete, ...other }) {
  const { translate } = useLocales();

  const handleAttach = () => {};
  const onDeleteColumn = () => {
    dispatch(deleteColumn({ column: column._id, index: column.index, board: page, row, type: 'page' }));
  };

  return (
    <>
      <Stack p={2.5} direction="row" alignItems="center">
        <>
          <Tooltip title="Back">
            <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
              <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
        </>

        <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
          <Tooltip title={translate('menu.delete')}>
            <IconButtonAnimate onClick={onDeleteColumn} size="small">
              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>

          <Tooltip title={translate('menu.save')}>
            <IconButtonAnimate onClick={onClose} size="small">
              <Iconify icon={'fluent:save-28-regular'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider />
    </>
  );
}
