import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch, store } from '../store';


// ----------------------------------------------------------------------

function objFromArray(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  recipe: {},
  ingredients: [],
  spices: [],
  recipes: [],
  steps: [],
  ingredientList: [],
  categories: [],
  curPage: 0,
  tipps: [],
  images: [],
  isEnd:false,
  text: "",
};

const slice = createSlice({
  name: 'recipes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.error = action.payload;
    },

    
    // GET BOARD
    getRecipesSuccess(state, action) {
      const {recipes, isEnd} = action.payload;

      state.isEnd=isEnd;
      console.log(isEnd)
      state.curPage+=1;
      state.recipes = recipes
    },

    getRecipeSuccess(state, action) {
      if (action.payload.data) {

        state.recipe = {
          ...action.payload.data,
        };
        state.ingredients = action.payload.data.ingredients || [];
        state.spices = action.payload.data.spices || [];
        state.steps = action.payload.data.steps || [];
        state.tipps = action.payload.data.tipps || [];
        state.text = action.payload.data.text || ""
        state.images = action.payload.data.images || []
      } else {
        state.recipe = {};
        state.ingredients = [];
        state.spices = [];
        state.steps = [];
        state.tipps = [];
        state.images = [];
        state.text = "";
        state.isLoading = false;
      }
    },
    setText(state, action){
      state.text = action.payload
    },

    closeRecipe(state, action) {
      state.recipe = {text: ""};
      state.ingredients = [];
      state.spices = [];
      state.steps = [];
      state.text = "";
    },

    addStep(state, action) {
      state.steps.push(action.payload);
    },

    updateStep(state, action) {
      state.steps = action.payload;
    },

    deleteStep(state, action) {
      state.steps = current(state).steps.filter((item, index) => {
        return index !== action.payload;
      });
    },

    getFromMainSuccess(state, action){
      console.log(action.payload)
    },

    addTipp(state, action) {
      state.tipps.push(action.payload);
    },

    updateTipp(state, action) {
      state.tipps = action.payload;
    },

    deleteTipp(state, action) {
      console.log(action.payload)
      state.tipps = current(state).tipps.filter((item, index) => {
        return item.keyy !== action.payload.keyy;
      });
    },

    refreshIngredientListSuccess(state, action) {
      state.ingredientList = action.payload.data;
    },

    updateIngredient(state, action) {
      state.ingredients = action.payload;
    },

    addIngredient(state, action) {
      state.ingredients.push(action.payload);
    },

    deleteIngredient(state, action) {
      state.ingredients = state.ingredients.filter((item, index) => {
        return index !== action.payload;
      });
    },
    
    updateSpice(state, action) {
      state.spices = action.payload;
    },

    addSpice(state, action) {
      state.spices.push(action.payload);
    },

    deleteSpice(state, action) {
      state.spices = state.spices.filter((item, index) => {
        return index !== action.payload;
      });
    },

    refreshCategoriesSuccess(state, action) {
      state.categories = action.payload.data;
    },

    saveImageSuccess(state, action) {
      console.log("SAVED IMAGE", action.payload)
      state.images = action.payload;
    },
    setImages(state, action){
      console.log("SET", action.payload)
      state.images = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

export const {
  closeRecipe,
  startLoading,
  setLoading,

  addIngredient,
  updateIngredient,
  deleteIngredient,

  addSpice,
  updateSpice,
  deleteSpice,

  addStep,
  updateStep,
  deleteStep,



  addTipp,
  updateTipp,
  deleteTipp,
  setImages,
  setText
} = slice.actions;

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getRecipes({sort, page, search}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/recipes', {
        params: { sort, search, page },
      });
      console.log(response.data)
      await dispatch(slice.actions.getRecipesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getRecipe(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/recipe', {
        params: { id },
      });
      await dispatch(slice.actions.getRecipeSuccess(response));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function getFromMain(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/vorlage', {
        params: { id },
      });
      await dispatch(slice.actions.getFromMainSuccess(response));
      console.log(response.data)
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error.data;
    }
  };
}

export function connectPage({ page, recipe }) {
  return async () => {
    try {
      console.log(page)
      const response = await axios.post('/recipe/connect', { page, recipe });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function getImage(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/recipe/image', {
        params: { id },
      });
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIngredientList(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/recipe/ingredients');
      await dispatch(slice.actions.refreshIngredientListSuccess(response));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategories(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/recipe/categories');
      await dispatch(slice.actions.refreshCategoriesSuccess(response));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveRecipe(values) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/user/recipe', { data: values });
      // await dispatch(slice.actions.refreshCategoriesSuccess(response));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {status: 400, data: error};
    }
  };
}

export function saveImage({id, title, alt,wpid}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/recipe/image', { id, title, alt, wpid});
      await dispatch(slice.actions.saveImageSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {status: 400, data: error};
    }
  };
}

export function addAssignee(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/recipe/assignee', { data });
      // await dispatch(slice.actions.refreshCategoriesSuccess(response));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return {status: 400, data: error};
    }
  };
}
