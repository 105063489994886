import { useState } from 'react';
// @mui
import { MenuItem, Stack, Box, Button, Tooltip } from '@mui/material';
import { ChromePicker, SketchPicker } from 'react-color';
// hooks
import useLocales from '../hooks/useLocales';
// components
import Image from './Image';
import MenuPopover from './MenuPopover';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

// ----------------------------------------------------------------------

export default function ColorPopover({ defaultColor, onColorChange, ...others }) {
  const { translate } = useLocales();

  const [open, setOpen] = useState(null);
  const [bgcolor, setColor] = useState(defaultColor || '#ffffff');

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onDeleteColor = () => {
    onColorChange("")
  };

  return (
    <Box {...others}>
      <Button
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          m: 0,
          mb: 2,
          bgcolor,
          transition: '0.2s transform',
          '&:hover': {
            transform: 'scale(1.05)',
            bgcolor
          },
          boxShadow: "0px 0px 3px grey !important",
        }}
      />

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          <ChromePicker color={bgcolor} onChange={(e) => setColor(e.hex)} onChangeComplete={(e)=>onColorChange(e)} />
        </Stack>
      </MenuPopover>
      <Tooltip title={translate('menu.delete')}>
        <IconButtonAnimate onClick={onDeleteColor} size="small">
          <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>
    </Box>
  );
}
