import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { Tooltip, Stack, Typography, Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useSnackbar } from 'notistack';

import { useDispatch } from '../../../../redux/store';
import { addAssignee } from '../../../../redux/slices/kanban';
import useToggle from '../../../../hooks/useToggle';

// components
import Iconify from '../../../Iconify';
import KanbanContactsDialog from '../../../../sections/@dashboard/kanban/KanbanContactsDialog';
import CardAssigneePopover from './CardAssigneePopover';
import { IconButtonAnimate } from '../../../animate';
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

CardAssignee.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const LabelStyle = styled(Typography)(({ theme, issamerow }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

export default function CardAssignee({ disableOwner, ownerAssignee, issamerow, isBoard, isColumn, card, ...other }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [assignee, setAssignee] = useState([]);
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    setOwners(
      card.owners || {}
    );
    setAssignee(
      card.assignee?.filter((item) => {
        if (ownerAssignee){
          return item;
        }
        if (card.owners?.filter((subitem) => subitem._id === item._id).length === 0) {
          return item;
        }
        return false;
      }) || {}
    );
  }, [card]);

  const { toggle: openContacts, onOpen: onOpenContacts, onClose: onCloseContacts } = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  const selectAssignee = async (user) => {
    const data = {
      isColumn: isColumn ? true : undefined,
      isBoard: isBoard ? true : undefined,
      card: card._id,
      contact: user._id,
    };
    const response = await dispatch(addAssignee(data));
    enqueueSnackbar(translate(response.data), { variant: response.status === 400 ? 'error' : 'success' });
    const b = assignee.map((item, index) => item._id === user._id).includes(true);
    if (!b && user) {
      setAssignee([...assignee, user]);
    }
  };

  return (
    <Box sx={{ml:3}}>
      {!disableOwner &&<Stack direction="row" sx={{marginRight: issamerow?"20px":0}}>
        <LabelStyle issamerow={issamerow} sx={{ mt: 1.5 }}>{translate('modules.boards.owner')}:</LabelStyle>
        <Stack direction="row" flexWrap="wrap" alignItems="">
          {owners?.map((user) => (
            <Avatar
              key={user?._id}
              alt={user?.firstName}
              src={user?.avatar && user?.avatar?.url ? user?.avatar?.url?.thumbnail : user?.avatar}
              sx={{ m: 0.5, width: 36, height: 36 }}
            />
          ))}
        </Stack>
      </Stack>}
      <Stack direction="row">
        <LabelStyle issamerow={issamerow} sx={{ mt: 1.5 }}>{translate('modules.boards.assignee')}:</LabelStyle>
        <Stack direction="row" flexWrap="wrap" alignItems="">
          {assignee.map((user) => (
            <CardAssigneePopover
              isBoard={isBoard}
              isColumn={isColumn}
              key={user._id}
              {...other}
              card={card}
              user={user}
            />
          ))}
          <Tooltip title="Add assignee">
            <IconButtonAnimate
              onClick={onOpenContacts}
              sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
            >
              <Iconify icon={'eva:plus-fill'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
          <KanbanContactsDialog open={openContacts} onClose={onCloseContacts} onSelect={selectAssignee} />
        </Stack>
      </Stack>
    </Box>
  );
}
