import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Typography, Paper, Grid } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

TextCard.propTypes = {
  disabledEffect: PropTypes.bool,
  sx: PropTypes.object,
};

export default function TextCard({ active, onChange, card, sx, ...other }) {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const openWindow = () => {
    navigate(PATH_DASHBOARD.boards.view(card._id || card.boardId));
  };

  return !card._id ? (
    <></>
  ) : (
    <>
      <Paper
        onClick={openWindow}
        component="span"
        sx={{
          px: 2,
          width: 1,

          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          cursor:"pointer",
          transition: "0.5s background-color",
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,

            position: 'absolute',
            backgroundSize: 'cover !important',
          },

          '&:hover': {
            bgcolor: !active ? 'grey.5008' : '',
          },

          borderRadius: 0,
          ...sx,
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            gridAutoFlow: 'column',
            justifyContent: 'space-between',
          }}
          style={{ textDecoration: 'none' }}
        >
          <Iconify icon={'iconoir:view-columns-2'} width={19} height={19} />
          <Typography
            variant="body2"
            sx={{
              ...(active
                ? { pr: 0.5, color: "primary.main" }
                : {}),
            }}
          >
            
            {card.boardId?.title || translate(card.title || 'apps.startboard')}
            {active && <Iconify sx={{transform: "translateY(3px)"}} icon="eva:arrow-ios-back-fill" height={16} width={16} />}
          </Typography>
        </Grid>
      </Paper>
    </>
  );
}
