import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Stack, Button } from '@mui/material';
// redux
import { useDispatch } from '../../redux/store';
import { ColumnSettings } from '.';
import { deleteColumn, updateColumn, addCard, deleteTask } from '../../redux/slices/kanban';
// components
import Iconify from '../Iconify';
import LightboxModal from '../LightboxModal';
import { ColumnSettings as WebColumnSettings } from './webeditor';

// ----------------------------------------------------------------------

Column.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
};

export default function Column({
  page,
  row,
  rowObject,
  width,
  board,
  settings,
  column,
  dropDisabled,
  sx = {},
  index,
  children,
  header,
  onCollapse,
  collapsable,
}) {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const onCollapseLocal = () => {
    setOpen(!open);
    onCollapse(index, !open);
  };
  const { _id } = column || {};

  const onColumnDelete = (boardId, columnId) => {
    dispatch(deleteColumn({ column: columnId, board: boardId, page, row, type: page ? 'page' : 'board' }));
  };

  const renderContent = (provided) => (
    <Stack
      sx={{
        '&:hover .column-settings': {
          display: 'block',
        },
      }}
    >
      {typeof settings === 'boolean' && (
        <ColumnSettings
          onDelete={onColumnDelete}
          column={column}
          header={header}
          board={page}
          onCollapse={onCollapseLocal}
          collapsable
        />
      )}
      {!typeof settings === 'boolean' && <>{settings}</>}
      {((collapsable && open) || !collapsable) && (
        <Droppable isDropDisabled={dropDisabled} droppableId={`${page || ''}-${_id}-column`} type="card-card">
          {(provided2) => (
            <Stack
              ref={provided2.innerRef}
              {...provided2.droppableProps}
              width={'100%' || 280}
              sx={{ minHeight: 40, position: 'relative', zIndex: 1 }}
            >
              {children}
              {provided2.placeholder}
            </Stack>
          )}
        </Droppable>
      )}
    </Stack>
  );

  return (
    <>{!!column &&
      <Paper
        sx={{
          position: 'relative',
          zIndex: 1,
          bgcolor: 'unset',
          '&:hover > .column-settings': {
            display: 'block',
            opacity: 1,
            zIndex: 100,
          },
          mt: 'unset',
          mb: 'unset',
          ...(column?.sx || {}),
        }}
      >
        {renderContent()}
        <WebColumnSettings column={column} page={page} />
      </Paper>}
    </>
  );
}
