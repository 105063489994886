import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import { Container, ListSubheader, Grid, Stack, Box } from '@mui/material';
// redux
import { styled } from '@mui/material/styles';

import { useDispatch, useSelector } from '../../redux/store';
import { getBoard, renameBoard } from '../../redux/slices/kanban';
import Scrollbar from '../../components/Scrollbar';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
import useLocales from '../../hooks/useLocales';

import { CardItem, DropListItem, ColumnList, Column, ColumnHeader } from '../../components/dragndrop';

// components
import Page from '../../components/Page';
import { CardAssignee } from '../../components/dragndrop/components/CardAssignee';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

export default function Board() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { boardId } = useParams();

  const [boardLoaded, setBoardLoaded] = useState(null);

  const { board, columnOrder, assignee } = useSelector((state) => state.kanban[boardLoaded || 'default']);
  const { columns, cards } = useSelector((state) => state.kanban);

  useEffect(async () => {
    const data = await dispatch(getBoard(boardId));
    if (data) {
      setBoardLoaded(boardId);
    } else {
      navigate(PATH_PAGE.page404);
    }
  }, [boardId]);

  const onDragEnd = (newColuns) => {};

  const cardupdateevent = (card, column, data) => {};
  const onNameChange = async (name) => {
    dispatch(renameBoard(boardId, name));
  };

  const contactDrop = (contact, item) => {};

  return (
    <Page title={board.title || translate('dragndrop.unnamed')} sx={{ height: 1, overflow: 'hidden' }}>
      <Container maxWidth={false} sx={{ height: 1, overflow: 'hidden' }}>
        {board.assignee && (
          <Stack spacing={3}>
            <CardAssignee card={{ ...board, assignee }} isBoard ownerAssignee disableOwner />
          </Stack>
        )}
        <Grid
          sx={{
            display: 'grid',
            gridAutoFlow: 'column',
            justifyContent: 'space-between',
          }}
        >
          <HeaderBreadcrumbs
            heading={board.title || translate('dragndrop.unnamed')}
            editEnabled={onNameChange}
            links={[
              {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
              },
              {
                name: 'Alle Boards',
                href: PATH_DASHBOARD.apps,
              },
              { name: board.title || translate('dragndrop.unnamed') },
            ]}
          />
        </Grid>

        <ColumnList id={board._id} onDragEnd={onDragEnd} direction="horizontal">
          
          {columnOrder.map((column, index) => (
            <Column
              index={index}
              key={column}
              column={columns[column]}
              settings
              board={board._id}
              header={<ColumnHeader>{translate(columns[column]?.title)}</ColumnHeader>}
              sx={{ mx: 1 }}
            >
              {columns[column].cards?.map((card, cardIndex) => (
                <DropListItem index={cardIndex} key={card} id={`${column}-${card}`}>
                  <CardItem
                    column={{ _id: column, index }}
                    cardIndex={cardIndex}
                    cardupdateevent={cardupdateevent}
                    item={cards[card]}
                  />
                </DropListItem>
              ))}
            </Column>
          ))}
        </ColumnList>
      </Container>
    </Page>
  );
}
