import PropTypes from 'prop-types';
import React from 'react';
// config
import socketIOClient from "socket.io-client";
import { defaultSettings, SOCKET_URL } from '../config';



// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
};

const SocketContext = React.createContext(initialState);

// ----------------------------------------------------------------------

class SocketProvider extends React.Component {
  constructor(props) {
    super(props);
    this.socket = socketIOClient(SOCKET_URL, { query: `user=${localStorage.getItem("accessToken")}` });
    
    this.state = {};
  }

  onSocket(what, data, fct){
    this.setState( {[what] :{...data, on: fct}}, ()=>{  
      this.socket.off(what); 
      this.socket.on(what, (data_)=>{
        this.state[what].on(data_, data)
      })
    })
  }

  isSocket(what){
    return this.state[what] || undefined
  }

  sendRequest(){
    this.socket.emit("hallo")
  }

  

  render() {
    return (
      <SocketContext.Provider
        value={{
          isSocket: this.isSocket.bind(this),
          sendRequest: this.sendRequest.bind(this),
          onSocket: this.onSocket.bind(this)
        }}
      >
        {this.props.children}
        
      </SocketContext.Provider>
    );
  }
}

export { SocketContext, SocketProvider };
